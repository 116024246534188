import React, { Component, useContext, useState } from "react";
import { Stack, Row, Col, Button, Form } from "react-bootstrap";
import CartContext from "./CartContext";

//https://codingstatus.com/increment-decrement-counter-in-react-hooks/
// function IncDecCounterMobile(props) {
//   const { store, storeSettings, theme } = useContext(CartContext);
//   let [num, setNum] = useState(props.intvalue);

export class IncDecCounterMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      num: this.props.intvalue,
    };
  }
  incNum = () => {
    if (this.state.num < this.context.storeSettings.productQtyLimitToCart) {
      var newnum = Number(this.state.num) + 1;
      //setNum(newnum);
      this.setState({ num: newnum });

      this.props.valueChangeCallback(newnum);
    }
  };
  decNum = () => {
    if (Number(this.state.num) > 0) {
      var newnum = this.state.num - 1;
      //setNum(newnum);
      this.setState({ num: newnum });

      this.props.valueChangeCallback(newnum);
    }
  };
  handleChange = (e) => {
    if (e.target.value === "") {
      this.setState({ num: 1 });
    } else if (Number(e.target.value) === 0) {
      this.setState({ num: Number(e.target.value) });
      this.props.valueChangeCallback(Number(e.target.value));
    } else {
      if (Number(e.target.value) > 0) {
        this.setState({ num: Number(e.target.value) });
        this.props.valueChangeCallback(Number(e.target.value));
      }
    }
  };
  render() {
    return (
      <>
        <table className="incdectable">
          <tr className="text-center align-middle ">
            <td className="mx-2 border-0" width="30%">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className="btn  mx-0 rounded-circle minus"
                  type="button"
                  onClick={this.decNum}
                  style={{
                    backgroundColor: "#4ad168",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  <b>&nbsp;-&nbsp;</b>
                </Button>
              </div>
            </td>

            <td className="text-center border-0 m-0 p-0" width="40%">
              <Form.Control
                type="text"
                className="text-center fw-bold w-100"
                value={this.state.num}
                onChange={this.handleChange}
                style={{
                  backgroundColor: "black",
                  color: this.context.theme.productCardTextColor,
                  fontSize: "12px",
                }}
              ></Form.Control>
            </td>
            <td width="30%" className="border-0 text-start">
              {/* <div style={{ display: "flex", justifyContent: "flex-start" }}> */}
              <Button
                className="btn rounded-circle plus mx-3"
                type="button"
                onClick={this.incNum}
                style={{
                  backgroundColor: "#4ad168",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                <b>&nbsp;+&nbsp;</b>
              </Button>
              {/* </div> */}
            </td>
          </tr>
        </table>
      </>
    );
  }
}

export default IncDecCounterMobile;
