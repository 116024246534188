import React, { Component, useState, useEffect } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import Slider from "react-slick";
import { MySEO } from "./MySEO";
import { Helmet } from "react-helmet";
import $ from "jquery";

import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ProductsList } from "./ProductsList";
import ProductCard from "./ProductCard";
import CartContext from "./CartContext";
import { LoadSpinner } from "./LoadSpinner";
import { LanguageSelection } from "./LanguageSelection";

export class HomeGreen extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      // prods: [],
      weightdefault: "",
      items: [
        { id: 1, title: "item #1" },
        { id: 2, title: "item #2" },
        { id: 3, title: "item #3" },
        { id: 4, title: "item #4" },
        { id: 5, title: "item #5" },
      ],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: true,
      runonce: false,
      sku: "",
      sunshineActive: true,
      empawaActive: false,
      sunsmartActive: false,
      reload: true,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  fakeRequest = (timeout) => {
    return new Promise((resolve) =>
      setTimeout(
        () => resolve(),
        //this.context.storeSettings.homePageLoadingTimeInSecond
        timeout
      )
    );
  };

  onSubCategoryClickEvent = (event) => {
    this.context.GetProductsBySubCategoryId(event.currentTarget.id, null, null);
  };

  onBrandClickEvent = (event) => {
    this.context.GetProductsByBrandId(event.currentTarget.id);
  };

  onMainCategoryClickEvent = (maincategory) => {
    this.context.GetProductsByMainCategoryId(maincategory.id);
  };

  onViewAllProductGroupClickEvent = (event) => {
    this.context.GetProductsByProductgroupId(event.currentTarget.id);
  };

  OnAdClick = (e, ad) => {
    this.context.UpdateReloadProductListView("true");
    if (ad.adType == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(ad.mainCategoryId);
    } else if (ad.adType == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(ad.subCategoryId, null, null);
    } else if (ad.adType == 3) {
      this.context.GetProductsByProductgroupId(ad.productGroupId);
    }
  };

  GetRedirectPathForProductMenu(menu) {
    if (menu.productGroupId != null) return "/ProductListViewByProductGroup";
    if (menu.mainCategoryId != null) return "/ProductListViewByMainCategory";
    if (menu.subCategoryId != null) return "/ProductListViewBySubCategory";
  }

  onSunshine = () => {
    this.setState({ empawaActive: false });
    this.setState({ sunsmartActive: false });
    this.setState({ sunshineActive: true });
  };
  onEmpawa = () => {
    this.setState({ sunshineActive: false });
    this.setState({ sunsmartActive: false });
    this.setState({ empawaActive: true });
  };
  onSunsmart = () => {
    this.setState({ sunshineActive: false });
    this.setState({ empawaActive: false });
    this.setState({ sunsmartActive: true });
  };
  onMainCategoryClickEvent = (maincategory) => {
    this.context.GetProductsByMainCategoryId(maincategory.id);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.context.prodsAll.length > 0) {
      this.setState({ loadSpinnerOpen: false }); // showing the app
      this.context.SetHomePageLoadedStatus();
      return;
    }

    this.fakeRequest(100).then(() => {
      if (
        this.context.storeSettings != "" &&
        this.context.prodsAll.length == 0
      ) {
        this.fakeRequest(
          this.context.storeSettings.homePageLoadingTimeInSecond
        ).then(() => {
          this.setState({ loadSpinnerOpen: false }); // showing the app
          this.context.SetHomePageLoadedStatus();
        });
      } else {
        this.setState({ loadSpinnerOpen: false }); // showing the app
        this.context.SetHomePageLoadedStatus();
      }
    });

    this.setState({ runonce: true });
    if (this.state.runonce == false) {
      this.state.runonce = true;

      $(".count").each(function () {
        $(this)
          .prop("count", 0)
          .animate(
            {
              Counter: $(this).text(),
            },
            {
              duration: 2000,
              easing: "swing",
              step: function (now) {
                $(this).text(Math.ceil(now));
              },
            }
          );
      });
    }
  }
  componentDidUpdate = () => {
    // if (
    //   this.context.reloadProductListView === "true" &&
    //   this.context.prodsAll &&
    //   this.context.prodsAll.length > 0
    // ) {
    //   this.setState(
    //     {
    //       prod: this.context.prodsAll.filter(
    //         (f) => f.id == this.state.prod.id
    //       )[0],
    //     },
    //     () => {
    //       this.setState({ reload: false }, () => {
    //         this.setState({ reload: true });
    //         this.context.UpdateReloadProductListView("false");
    //       });
    //     }
    //   );
    // }
  };

  render() {
    if (
      (this.state.loadSpinnerOpen == true && this.context.storeId == "") ||
      this.context.mainCategories.length == 0 ||
      this.context.subCategories.length == 0
      //||this.context.prodsAll.length == 0
    ) {
      return (
        <>
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
        </>
      );
    }
    if (
      this.context.prodsAll.length > 0 &&
      localStorage.getItem("CountryCode") == null
    ) {
      this.context.SetSelectedCountryCode("PG");
      return <></>;
    }
    return (
      <>
        <div>
          <section class="section  mh-100vh  bg-light-grey" id="productsec">
            <MySEO></MySEO>
            <Helmet>
              <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
              <link href="dist/css/grnstyle.css" rel="stylesheet" />
              <link href="dist/css/grnresponsive.css" rel="stylesheet" />
              <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
              <script src="dist/js/grncustom.js"></script>
              {/* <script src="dist/js/custom.js"></script> */}
            </Helmet>
            {this.state.reload == true ? (
              <>
                <div
                  class={
                    window.innerWidth > 1200 ? "row product-lrg-pt" : "row "
                  }
                >
                  <div class="col-md-1 position-relative">
                    <h2 class="tittle-black-outline-5">PRODUCTS</h2>
                  </div>
                  <div class="col-md-11 product-res-tab">
                    <div class="product-sec">
                      <div class="tab-list-details" className="">
                        <div className="mx-5">
                          <div
                            class="row productCarosual active"
                            id="sunshineTabView"
                          >
                            <>
                              {this.context.mainCategories
                                .filter((f) => f.name == "GREEN SunShine")
                                .map((maincategory) => (
                                  <>
                                    <div class="col-md-4 product-res-caro1 ">
                                      <div
                                        id="productSunshineCarousel"
                                        class="productImg carousel slide"
                                        data-bs-ride="carousel"
                                        data-bs-interval="7000"
                                      >
                                        <div class="carousel-indicators">
                                          <>
                                            {this.context.prodsAll
                                              .filter(
                                                (f) =>
                                                  f.mainCategoryId ==
                                                  maincategory.id
                                              )
                                              .map((product, i) => (
                                                <>
                                                  {i == 0 ? (
                                                    <>
                                                      <button
                                                        type="button"
                                                        data-bs-target={
                                                          maincategory.name ==
                                                          "GREEN SunShine"
                                                            ? "#productSunshineCarousel"
                                                            : maincategory.name ==
                                                              "GREEN Em'Pawa"
                                                            ? "#productEmpawaCarousel"
                                                            : maincategory.name ==
                                                              "GREEN SunSmart"
                                                            ? "#productSmartCarousel"
                                                            : ""
                                                        }
                                                        data-bs-slide-to="0"
                                                        class="active"
                                                        aria-current="true"
                                                        aria-label="Slide 1"
                                                      >
                                                        <img
                                                          src={
                                                            this.context.store
                                                              .storageBlobUrl +
                                                            this.context.store
                                                              .storageBlobContainerName +
                                                            "/images/" +
                                                            product.imgFileName +
                                                            this.context.store
                                                              .storageSasToken
                                                          }
                                                          className=""
                                                          alt="..."
                                                          width="60px"
                                                        />
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        type="button"
                                                        data-bs-target={
                                                          maincategory.name ==
                                                          "GREEN SunShine"
                                                            ? "#productSunshineCarousel"
                                                            : maincategory.name ==
                                                              "GREEN Em'Pawa"
                                                            ? "#productEmpawaCarousel"
                                                            : maincategory.name ==
                                                              "GREEN SunSmart"
                                                            ? "#productSmartCarousel"
                                                            : ""
                                                        }
                                                        data-bs-slide-to={i}
                                                        aria-label={
                                                          "Slide " + i + 1
                                                        }
                                                      >
                                                        {/* <img src="dist/grnimages/products/thumbnails/product-sunshine-thumb-2.png" /> */}
                                                        <img
                                                          src={
                                                            this.context.store
                                                              .storageBlobUrl +
                                                            this.context.store
                                                              .storageBlobContainerName +
                                                            "/images/" +
                                                            product.imgFileName +
                                                            this.context.store
                                                              .storageSasToken
                                                          }
                                                          className=""
                                                          alt="..."
                                                          width="60px"
                                                        />
                                                      </button>
                                                    </>
                                                  )}
                                                </>
                                              ))}
                                          </>
                                        </div>

                                        <div class="carousel-inner">
                                          {this.context.prodsAll
                                            .filter(
                                              (f) =>
                                                f.mainCategoryId ==
                                                maincategory.id
                                            )
                                            .map((product, i) => (
                                              <>
                                                <div
                                                  class={
                                                    i == 0
                                                      ? "carousel-item active"
                                                      : "carousel-item"
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      this.context.store
                                                        .storageBlobUrl +
                                                      this.context.store
                                                        .storageBlobContainerName +
                                                      "/images/" +
                                                      product.imgFileName +
                                                      this.context.store
                                                        .storageSasToken
                                                    }
                                                    class="d-block w-100 slider-img"
                                                    alt="..."
                                                  />

                                                  <div class="carousel-caption d-md-block ">
                                                    <div class="row">
                                                      <div class="col">
                                                        <h5>
                                                          {
                                                            product.subCategoryName
                                                          }
                                                        </h5>
                                                        <p>{product.name}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                        </div>

                                        <button
                                          class="carousel-control-prev"
                                          type="button"
                                          data-bs-target="#productSunshineCarousel"
                                          data-bs-slide="prev"
                                        >
                                          <img src="dist/grnimages/products/icons/right.png" />
                                          <span class="visually-hidden">
                                            Previous
                                          </span>
                                        </button>
                                        <button
                                          class="carousel-control-next"
                                          type="button"
                                          data-bs-target="#productSunshineCarousel"
                                          data-bs-slide="next"
                                        >
                                          <img src="dist/grnimages/products/icons/left.png" />
                                          <span class="visually-hidden">
                                            Next
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="col-md-2 product-sub-title product-res-caro2">
                                      <div class="content_part  my-5">
                                        <h3>
                                          Lighting Up and Lifting Up Living
                                          Standards
                                        </h3>
                                        <p>
                                          To lift up living standards
                                          sustainably, it is crucial to invest
                                          in infrastructure that supports a
                                          better quality of life.
                                        </p>

                                        <Nav.Link
                                          // as={Link}
                                          id={maincategory.id}
                                          className="btn-green d-inline-block text-white"
                                          // to={
                                          //   window.innerWidth >= 1100
                                          //     ? "/ProductsList"
                                          //     : "/ProductsListMobile"
                                          // }
                                          href={
                                            window.location.origin +
                                            "/MainCategoryArg?name=GREEN SunShine"
                                          }
                                          // onClick={(e) => {
                                          //   this.onMainCategoryClickEvent(
                                          //     maincategory
                                          //   );
                                          // }}
                                          style={{
                                            color:
                                              this.context.theme
                                                .categoryTextColor,
                                            backgroundColor:
                                              this.context.theme
                                                .productCardBackgroundColor,
                                          }}
                                        >
                                          Shop Sunshine
                                        </Nav.Link>
                                      </div>
                                    </div>

                                    <div class="col-md-6 p-0 product-res-cazro3 ">
                                      <div
                                        id="productSunshineCarouselTwo"
                                        class="productImgTwo carousel slide"
                                        data-bs-ride="carousel"
                                      >
                                        <div class="carousel-inner">
                                          {this.context.prodsAll
                                            .filter(
                                              (f) =>
                                                f.mainCategoryId ==
                                                maincategory.id
                                            )
                                            .map((product, i) => (
                                              <>
                                                <div
                                                  class={
                                                    i == 0
                                                      ? "carousel-item active"
                                                      : "carousel-item"
                                                  }
                                                >
                                                  {/* <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    this.state.prod.description,
                                                }}
                                              /> */}

                                                  <img
                                                    src={
                                                      this.context.store
                                                        .storageBlobUrl +
                                                      this.context.store
                                                        .storageBlobContainerName +
                                                      "/images/" +
                                                      product.image2 +
                                                      this.context.store
                                                        .storageSasToken
                                                    }
                                                    class="d-block w-100 slider-img"
                                                    alt="..."
                                                    style={{
                                                      objectFit: "contain",
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </>
                          </div>

                          <div class="row  productCarosual" id="empawaTabView">
                            <>
                              {this.context.mainCategories
                                .filter((f) => f.name == "GREEN Em'Pawa")
                                .map((maincategory) => (
                                  <>
                                    <div class="col-md-4 product-res-caro1">
                                      <div
                                        id="productEmpawaCarousel"
                                        class="productImg carousel slide"
                                        data-bs-ride="carousel"
                                      >
                                        <div class="carousel-indicators">
                                          <>
                                            {this.context.prodsAll
                                              .filter(
                                                (f) =>
                                                  f.mainCategoryId ==
                                                  maincategory.id
                                              )
                                              .map((product, i) => (
                                                <>
                                                  {i == 0 ? (
                                                    <>
                                                      <button
                                                        type="button"
                                                        data-bs-target={
                                                          maincategory.name ==
                                                          "GREEN SunShine"
                                                            ? "#productSunshineCarousel"
                                                            : maincategory.name ==
                                                              "GREEN Em'Pawa"
                                                            ? "#productEmpawaCarousel"
                                                            : maincategory.name ==
                                                              "GREEN SunSmart"
                                                            ? "#productSmartCarousel"
                                                            : ""
                                                        }
                                                        data-bs-slide-to="0"
                                                        class="active"
                                                        aria-current="true"
                                                        aria-label="Slide 1"
                                                      >
                                                        {/* <img src="dist/grnimages/products/thumbnails/product-sunshine-thumb-1.png" /> */}
                                                        <img
                                                          src={
                                                            this.context.store
                                                              .storageBlobUrl +
                                                            this.context.store
                                                              .storageBlobContainerName +
                                                            "/images/" +
                                                            product.imgFileName +
                                                            this.context.store
                                                              .storageSasToken
                                                          }
                                                          className=""
                                                          alt="..."
                                                          width="60px"
                                                        />
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        type="button"
                                                        data-bs-target={
                                                          maincategory.name ==
                                                          "GREEN SunShine"
                                                            ? "#productSunshineCarousel"
                                                            : maincategory.name ==
                                                              "GREEN Em'Pawa"
                                                            ? "#productEmpawaCarousel"
                                                            : maincategory.name ==
                                                              "GREEN SunSmart"
                                                            ? "#productSmartCarousel"
                                                            : ""
                                                        }
                                                        data-bs-slide-to={i}
                                                        aria-label={
                                                          "Slide " + i + 1
                                                        }
                                                      >
                                                        {/* <img src="dist/grnimages/products/thumbnails/product-sunshine-thumb-2.png" /> */}
                                                        <img
                                                          src={
                                                            this.context.store
                                                              .storageBlobUrl +
                                                            this.context.store
                                                              .storageBlobContainerName +
                                                            "/images/" +
                                                            product.imgFileName +
                                                            this.context.store
                                                              .storageSasToken
                                                          }
                                                          className=""
                                                          alt="..."
                                                          width="60px"
                                                        />
                                                      </button>
                                                    </>
                                                  )}
                                                </>
                                              ))}
                                          </>
                                        </div>

                                        <div class="carousel-inner">
                                          {this.context.prodsAll
                                            .filter(
                                              (f) =>
                                                f.mainCategoryId ==
                                                maincategory.id
                                            )
                                            .map((product, i) => (
                                              <>
                                                <div
                                                  class={
                                                    i == 0
                                                      ? "carousel-item active"
                                                      : "carousel-item"
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      this.context.store
                                                        .storageBlobUrl +
                                                      this.context.store
                                                        .storageBlobContainerName +
                                                      "/images/" +
                                                      product.imgFileName +
                                                      this.context.store
                                                        .storageSasToken
                                                    }
                                                    class="d-block w-100 slider-img"
                                                    alt="..."
                                                  />
                                                  <div class="carousel-caption d-md-block ">
                                                    <div class="row">
                                                      <div class="col">
                                                        <h5>
                                                          {
                                                            product.subCategoryName
                                                          }
                                                        </h5>
                                                        <p>{product.name}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                        </div>

                                        <button
                                          class="carousel-control-prev"
                                          type="button"
                                          data-bs-target="#productEmpawaCarousel"
                                          data-bs-slide="prev"
                                        >
                                          <img src="dist/grnimages/products/icons/right.png" />
                                          <span class="visually-hidden">
                                            Previous
                                          </span>
                                        </button>
                                        <button
                                          class="carousel-control-next"
                                          type="button"
                                          data-bs-target="#productEmpawaCarousel"
                                          data-bs-slide="next"
                                        >
                                          <img src="dist/grnimages/products/icons/left.png" />
                                          <span class="visually-hidden">
                                            Next
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="col-md-2 product-sub-title product-res-caro2">
                                      <div class="content_part  my-5">
                                        <h3>
                                          Lighting Up and Lifting Up Living
                                          Standards
                                        </h3>
                                        <p>
                                          To lift up living standards
                                          sustainably, it is crucial to invest
                                          in infrastructure that supports a
                                          better quality of life.
                                        </p>
                                        <Nav.Link
                                          // as={Link}
                                          id={maincategory.id}
                                          className="btn-green d-inline-block text-white"
                                          href={
                                            window.location.origin +
                                            "/MainCategoryArg?name=GREEN Em'Pawa"
                                          }
                                          // to={
                                          //   window.innerWidth >= 1100
                                          //     ? "/ProductsList"
                                          //     : "/ProductsListMobile"
                                          // }
                                          // onClick={(e) => {
                                          //   this.onMainCategoryClickEvent(
                                          //     maincategory
                                          //   );
                                          // }}
                                          style={{
                                            color:
                                              this.context.theme
                                                .categoryTextColor,
                                            backgroundColor:
                                              this.context.theme
                                                .productCardBackgroundColor,
                                          }}
                                        >
                                          Shop Em'pawa
                                        </Nav.Link>
                                      </div>
                                    </div>
                                    <div class="col-md-6 p-0 product-res-caro3">
                                      <div
                                        id="productEmpawaCarouselTwo"
                                        class="productImgTwo carousel slide"
                                        data-bs-ride="carousel"
                                      >
                                        <div class="carousel-inner">
                                          {this.context.prodsAll
                                            .filter(
                                              (f) =>
                                                f.mainCategoryId ==
                                                maincategory.id
                                            )
                                            .map((product, i) => (
                                              <>
                                                <div
                                                  class={
                                                    i == 0
                                                      ? "carousel-item active"
                                                      : "carousel-item"
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      this.context.store
                                                        .storageBlobUrl +
                                                      this.context.store
                                                        .storageBlobContainerName +
                                                      "/images/" +
                                                      product.image2 +
                                                      this.context.store
                                                        .storageSasToken
                                                    }
                                                    class="d-block w-100 slider-img"
                                                    alt="..."
                                                    style={{
                                                      objectFit: "contain",
                                                    }}
                                                  />

                                                  {/* <div class="carousel-caption d-none d-md-block ">
                                                <div class="row">
                                                  <div class="col">
                                                    <h5>
                                                      {product.subCategoryName}
                                                    </h5>
                                                    <p>{product.name}</p>
                                                  </div>
                                                </div>
                                              </div> */}
                                                </div>
                                              </>
                                            ))}
                                        </div>
                                        {/* <CarouselCaptions></CarouselCaptions> */}
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </>
                          </div>

                          <div class="row productCarosual" id="sunsmartTabView">
                            <>
                              {this.context.mainCategories
                                .filter((f) => f.name == "GREEN SunSmart")
                                .map((maincategory) => (
                                  <>
                                    <div class="col-md-4 product-res-caro1">
                                      <div
                                        id="productSmartCarousel"
                                        class="productImg carousel slide"
                                        data-bs-ride="carousel"
                                      >
                                        <div class="carousel-indicators">
                                          <>
                                            {this.context.prodsAll
                                              .filter(
                                                (f) =>
                                                  f.mainCategoryId ==
                                                  maincategory.id
                                              )
                                              .map((product, i) => (
                                                <>
                                                  {i == 0 ? (
                                                    <>
                                                      <button
                                                        type="button"
                                                        data-bs-target={
                                                          maincategory.name ==
                                                          "GREEN SunShine"
                                                            ? "#productSunshineCarousel"
                                                            : maincategory.name ==
                                                              "GREEN Em'Pawa"
                                                            ? "#productEmpawaCarousel"
                                                            : maincategory.name ==
                                                              "GREEN SunSmart"
                                                            ? "#productSmartCarousel"
                                                            : ""
                                                        }
                                                        data-bs-slide-to="0"
                                                        class="active"
                                                        aria-current="true"
                                                        aria-label="Slide 1"
                                                      >
                                                        {/* <img src="dist/grnimages/products/thumbnails/product-sunshine-thumb-1.png" /> */}
                                                        <img
                                                          src={
                                                            this.context.store
                                                              .storageBlobUrl +
                                                            this.context.store
                                                              .storageBlobContainerName +
                                                            "/images/" +
                                                            product.imgFileName +
                                                            this.context.store
                                                              .storageSasToken
                                                          }
                                                          className=""
                                                          alt="..."
                                                          width="60px"
                                                        />
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        type="button"
                                                        data-bs-target={
                                                          maincategory.name ==
                                                          "GREEN SunShine"
                                                            ? "#productSunshineCarousel"
                                                            : maincategory.name ==
                                                              "GREEN Em'Pawa"
                                                            ? "#productEmpawaCarousel"
                                                            : maincategory.name ==
                                                              "GREEN SunSmart"
                                                            ? "#productSmartCarousel"
                                                            : ""
                                                        }
                                                        data-bs-slide-to={i}
                                                        aria-label={
                                                          "Slide " + i + 1
                                                        }
                                                      >
                                                        {/* <img src="dist/grnimages/products/thumbnails/product-sunshine-thumb-2.png" /> */}
                                                        <img
                                                          src={
                                                            this.context.store
                                                              .storageBlobUrl +
                                                            this.context.store
                                                              .storageBlobContainerName +
                                                            "/images/" +
                                                            product.imgFileName +
                                                            this.context.store
                                                              .storageSasToken
                                                          }
                                                          className=""
                                                          alt="..."
                                                          width="60px"
                                                        />
                                                      </button>
                                                    </>
                                                  )}
                                                </>
                                              ))}
                                          </>
                                        </div>

                                        <div class="carousel-inner">
                                          {this.context.prodsAll
                                            .filter(
                                              (f) =>
                                                f.mainCategoryId ==
                                                maincategory.id
                                            )
                                            .map((product, i) => (
                                              <>
                                                <div
                                                  class={
                                                    i == 0
                                                      ? "carousel-item active"
                                                      : "carousel-item"
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      this.context.store
                                                        .storageBlobUrl +
                                                      this.context.store
                                                        .storageBlobContainerName +
                                                      "/images/" +
                                                      product.imgFileName +
                                                      this.context.store
                                                        .storageSasToken
                                                    }
                                                    class="d-block w-100 slider-img"
                                                    alt="..."
                                                  />
                                                  <div class="carousel-caption d-md-block ">
                                                    <div class="row">
                                                      <div class="col">
                                                        <h5>
                                                          {
                                                            product.subCategoryName
                                                          }
                                                        </h5>
                                                        <p>{product.name}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                        </div>

                                        <button
                                          class="carousel-control-prev"
                                          type="button"
                                          data-bs-target="#productSmartCarousel"
                                          data-bs-slide="prev"
                                        >
                                          <img src="dist/grnimages/products/icons/right.png" />
                                          <span class="visually-hidden">
                                            Previous
                                          </span>
                                        </button>
                                        <button
                                          class="carousel-control-next"
                                          type="button"
                                          data-bs-target="#productSmartCarousel"
                                          data-bs-slide="next"
                                        >
                                          <img src="dist/grnimages/products/icons/left.png" />
                                          <span class="visually-hidden">
                                            Next
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="col-md-2 product-sub-title product-res-caro2 ">
                                      <div class="content_part  my-5">
                                        <h3>
                                          Lighting Up and Lifting Up Living
                                          Standards
                                        </h3>
                                        <p>
                                          To lift up living standards
                                          sustainably, it is crucial to invest
                                          in infrastructure that supports a
                                          better quality of life.
                                        </p>
                                        <Nav.Link
                                          // as={Link}
                                          id={maincategory.id}
                                          className="btn-green d-inline-block text-white"
                                          href={
                                            window.location.origin +
                                            "/MainCategoryArg?name=GREEN SunSmart"
                                          }
                                          // to={
                                          //   window.innerWidth >= 1100
                                          //     ? "/ProductsList"
                                          //     : "/ProductsListMobile"
                                          // }
                                          // onClick={(e) => {
                                          //   this.onMainCategoryClickEvent(
                                          //     maincategory
                                          //   );
                                          // }}
                                          style={{
                                            color:
                                              this.context.theme
                                                .categoryTextColor,
                                            backgroundColor:
                                              this.context.theme
                                                .productCardBackgroundColor,
                                          }}
                                        >
                                          Shop SunSmart
                                        </Nav.Link>
                                        <br />
                                      </div>
                                    </div>
                                    <div class="col-md-6 p-0 product-res-caro3">
                                      <div
                                        id="productSmartCarouselTwo"
                                        class="productImgTwo carousel slide"
                                        data-bs-ride="carousel"
                                      >
                                        <div class="carousel-inner">
                                          {this.context.prodsAll
                                            .filter(
                                              (f) =>
                                                f.mainCategoryId ==
                                                maincategory.id
                                            )
                                            .map((product, i) => (
                                              <>
                                                <div
                                                  class={
                                                    i == 0
                                                      ? "carousel-item active"
                                                      : "carousel-item"
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      this.context.store
                                                        .storageBlobUrl +
                                                      this.context.store
                                                        .storageBlobContainerName +
                                                      "/images/" +
                                                      product.image2 +
                                                      this.context.store
                                                        .storageSasToken
                                                    }
                                                    class="d-block w-100 slider-img"
                                                    alt="..."
                                                    style={{
                                                      objectFit: "contain",
                                                    }}
                                                  />

                                                  {/* <div class="carousel-caption d-none d-md-block ">
                                                <div class="row">
                                                  <div class="col">
                                                    <h5>
                                                      {product.subCategoryName}
                                                    </h5>
                                                    <p>{product.name}</p>
                                                  </div>
                                                </div>
                                              </div> */}
                                                </div>
                                              </>
                                            ))}
                                        </div>
                                        {/* <CarouselCaptions></CarouselCaptions> */}
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </section>{" "}
        </div>
      </>
    );
  }
}

function CarouselCaptions() {
  return (
    <>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="dist/grnimages/products/product-21.png"
            class="slider-img d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-md-block">
            <div class="product-des-item">
              <div class="row">
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-1.png"
                      width="35"
                    />
                    <h5>Solar Panel</h5>
                    <p>60W Poly-crystalline</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-2.png"
                      width="35"
                    />
                    <h5>Control Box</h5>
                    <p>12.8V/18000mAh LiFePO4</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-3.png"
                      width="35"
                    />
                    <h5>LED Bulb</h5>
                    <p>
                      LED Bulb
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      <span>23 hrs / 11 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-4.png"
                      width="35"
                    />
                    <h5>TV</h5>
                    <p>DC TeleVision 32 inch</p>
                    <p>
                      <span>16 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-5.png"
                      width="35"
                    />
                    <h5>Fan</h5>
                    <p>DC Pedestal Fan 16 inch</p>
                    <p>
                      <span>13 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-6.png"
                      width="35"
                    />
                    <h5>FM Radio</h5>
                    <p>Radio with MP3 player</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-7.png"
                      width="35"
                    />
                    <h5>Lantern</h5>
                    <p>3.2V 600mAh LiFePO4</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-8.png"
                      width="35"
                    />
                    <h5>Flashlight</h5>
                    <p>3W 3.7V 1800mAh Li-ion battery</p>
                    <p>
                      <span>8 hrs / 4 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-9.png"
                      width="35"
                    />
                    <h5>USB Cable</h5>
                    <p>Mobile Charging cable</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="dist/grnimages/products/product-22.png"
            class="slider-img d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-md-block">
            <div class="product-des-item">
              <div class="row">
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-1.png"
                      width="35"
                    />
                    <h5>Solar Panel</h5>
                    <p>60W Poly-crystalline</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-2.png"
                      width="35"
                    />
                    <h5>Control Box</h5>
                    <p>12.8V/18000mAh LiFePO4</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-3.png"
                      width="35"
                    />
                    <h5>LED Bulb</h5>
                    <p>
                      LED Bulb
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      <span>23 hrs / 11 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-4.png"
                      width="35"
                    />
                    <h5>TV</h5>
                    <p>DC TeleVision 32 inch</p>
                    <p>
                      <span>16 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-5.png"
                      width="35"
                    />
                    <h5>Fan</h5>
                    <p>DC Pedestal Fan 16 inch</p>
                    <p>
                      <span>13 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-6.png"
                      width="35"
                    />
                    <h5>FM Radio</h5>
                    <p>Radio with MP3 player</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-7.png"
                      width="35"
                    />
                    <h5>Lantern</h5>
                    <p>3.2V 600mAh LiFePO4</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-8.png"
                      width="35"
                    />
                    <h5>Flashlight</h5>
                    <p>3W 3.7V 1800mAh Li-ion battery</p>
                    <p>
                      <span>8 hrs / 4 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-9.png"
                      width="35"
                    />
                    <h5>USB Cable</h5>
                    <p>Mobile Charging cable</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="dist/grnimages/products/product-21.png"
            class="slider-img d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-md-block">
            <div class="product-des-item">
              <div class="row">
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-1.png"
                      width="35"
                    />
                    <h5>Solar Panel</h5>
                    <p>60W Poly-crystalline</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-2.png"
                      width="35"
                    />
                    <h5>Control Box</h5>
                    <p>12.8V/18000mAh LiFePO4</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-3.png"
                      width="35"
                    />
                    <h5>LED Bulb</h5>
                    <p>
                      LED Bulb
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      <span>23 hrs / 11 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-4.png"
                      width="35"
                    />
                    <h5>TV</h5>
                    <p>DC TeleVision 32 inch</p>
                    <p>
                      <span>16 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-5.png"
                      width="35"
                    />
                    <h5>Fan</h5>
                    <p>DC Pedestal Fan 16 inch</p>
                    <p>
                      <span>13 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-6.png"
                      width="35"
                    />
                    <h5>FM Radio</h5>
                    <p>Radio with MP3 player</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-7.png"
                      width="35"
                    />
                    <h5>Lantern</h5>
                    <p>3.2V 600mAh LiFePO4</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-8.png"
                      width="35"
                    />
                    <h5>Flashlight</h5>
                    <p>3W 3.7V 1800mAh Li-ion battery</p>
                    <p>
                      <span>8 hrs / 4 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-9.png"
                      width="35"
                    />
                    <h5>USB Cable</h5>
                    <p>Mobile Charging cable</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="dist/grnimages/products/product-22.png"
            class="slider-img d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-md-block">
            <div class="product-des-item">
              <div class="row">
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-1.png"
                      width="35"
                    />
                    <h5>Solar Panel</h5>
                    <p>60W Poly-crystalline</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-2.png"
                      width="35"
                    />
                    <h5>Control Box</h5>
                    <p>12.8V/18000mAh LiFePO4</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-3.png"
                      width="35"
                    />
                    <h5>LED Bulb</h5>
                    <p>
                      LED Bulb
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      <span>23 hrs / 11 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-4.png"
                      width="35"
                    />
                    <h5>TV</h5>
                    <p>DC TeleVision 32 inch</p>
                    <p>
                      <span>16 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-5.png"
                      width="35"
                    />
                    <h5>Fan</h5>
                    <p>DC Pedestal Fan 16 inch</p>
                    <p>
                      <span>13 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-6.png"
                      width="35"
                    />
                    <h5>FM Radio</h5>
                    <p>Radio with MP3 player</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-7.png"
                      width="35"
                    />
                    <h5>Lantern</h5>
                    <p>3.2V 600mAh LiFePO4</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-8.png"
                      width="35"
                    />
                    <h5>Flashlight</h5>
                    <p>3W 3.7V 1800mAh Li-ion battery</p>
                    <p>
                      <span>8 hrs / 4 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-9.png"
                      width="35"
                    />
                    <h5>USB Cable</h5>
                    <p>Mobile Charging cable</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="dist/grnimages/products/product-21.png"
            class="slider-img d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-md-block">
            <div class="product-des-item">
              <div class="row">
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-1.png"
                      width="35"
                    />
                    <h5>Solar Panel</h5>
                    <p>60W Poly-crystalline</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-2.png"
                      width="35"
                    />
                    <h5>Control Box</h5>
                    <p>12.8V/18000mAh LiFePO4</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-3.png"
                      width="35"
                    />
                    <h5>LED Bulb</h5>
                    <p>
                      LED Bulb
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      <span>23 hrs / 11 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-4.png"
                      width="35"
                    />
                    <h5>TV</h5>
                    <p>DC TeleVision 32 inch</p>
                    <p>
                      <span>16 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-5.png"
                      width="35"
                    />
                    <h5>Fan</h5>
                    <p>DC Pedestal Fan 16 inch</p>
                    <p>
                      <span>13 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-6.png"
                      width="35"
                    />
                    <h5>FM Radio</h5>
                    <p>Radio with MP3 player</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-7.png"
                      width="35"
                    />
                    <h5>Lantern</h5>
                    <p>3.2V 600mAh LiFePO4</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-8.png"
                      width="35"
                    />
                    <h5>Flashlight</h5>
                    <p>3W 3.7V 1800mAh Li-ion battery</p>
                    <p>
                      <span>8 hrs / 4 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-9.png"
                      width="35"
                    />
                    <h5>USB Cable</h5>
                    <p>Mobile Charging cable</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="dist/grnimages/products/product-22.png"
            class="slider-img d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-md-block">
            <div class="product-des-item">
              <div class="row">
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-1.png"
                      width="35"
                    />
                    <h5>Solar Panel</h5>
                    <p>60W Poly-crystalline</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-2.png"
                      width="35"
                    />
                    <h5>Control Box</h5>
                    <p>12.8V/18000mAh LiFePO4</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-3.png"
                      width="35"
                    />
                    <h5>LED Bulb</h5>
                    <p>
                      LED Bulb
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      <span>23 hrs / 11 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-4.png"
                      width="35"
                    />
                    <h5>TV</h5>
                    <p>DC TeleVision 32 inch</p>
                    <p>
                      <span>16 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-5.png"
                      width="35"
                    />
                    <h5>Fan</h5>
                    <p>DC Pedestal Fan 16 inch</p>
                    <p>
                      <span>13 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-6.png"
                      width="35"
                    />
                    <h5>FM Radio</h5>
                    <p>Radio with MP3 player</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-7.png"
                      width="35"
                    />
                    <h5>Lantern</h5>
                    <p>3.2V 600mAh LiFePO4</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-8.png"
                      width="35"
                    />
                    <h5>Flashlight</h5>
                    <p>3W 3.7V 1800mAh Li-ion battery</p>
                    <p>
                      <span>8 hrs / 4 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-9.png"
                      width="35"
                    />
                    <h5>USB Cable</h5>
                    <p>Mobile Charging cable</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item">
          <img
            src="dist/grnimages/products/product-21.png"
            class="slider-img d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-md-block">
            <div class="product-des-item">
              <div class="row">
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-1.png"
                      width="35"
                    />
                    <h5>Solar Panel</h5>
                    <p>60W Poly-crystalline</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-2.png"
                      width="35"
                    />
                    <h5>Control Box</h5>
                    <p>12.8V/18000mAh LiFePO4</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-3.png"
                      width="35"
                    />
                    <h5>LED Bulb</h5>
                    <p>
                      LED Bulb
                      <br />
                      &nbsp;
                    </p>
                    <p>
                      <span>23 hrs / 11 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-4.png"
                      width="35"
                    />
                    <h5>TV</h5>
                    <p>DC TeleVision 32 inch</p>
                    <p>
                      <span>16 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-5.png"
                      width="35"
                    />
                    <h5>Fan</h5>
                    <p>DC Pedestal Fan 16 inch</p>
                    <p>
                      <span>13 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-6.png"
                      width="35"
                    />
                    <h5>FM Radio</h5>
                    <p>Radio with MP3 player</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-7.png"
                      width="35"
                    />
                    <h5>Lantern</h5>
                    <p>3.2V 600mAh LiFePO4</p>
                    <p>
                      <span>8 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-8.png"
                      width="35"
                    />
                    <h5>Flashlight</h5>
                    <p>3W 3.7V 1800mAh Li-ion battery</p>
                    <p>
                      <span>8 hrs / 4 hrs</span>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="single-item">
                    <img
                      src="dist/grnimages/products/icons/pro-shine-img-9.png"
                      width="35"
                    />
                    <h5>USB Cable</h5>
                    <p>Mobile Charging cable</p>
                    <p>
                      <span>--</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="product-c02 text-center">
        <img src="dist/grnimages/products/icons/pro-co2.png" />
        <h3>50%</h3>
        <h5>
          decrease in CO2
          <br />
          emissions in
          <br />
          last year
        </h5>
      </div>
    </>
  );
}

export class HomeGreen1 extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <HomeGreen></HomeGreen>
      </>
    );
  }
}
