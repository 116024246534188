// import React, { Component } from "react";
// import { Country, State, City } from "country-state-city";
// import CartContext from "./CartContext";
// import { Link } from "react-router-dom";
// import { Navigate } from "react-router-dom";
// import Pincode from "react-pincode";
// import axios from "axios";

// import { Modal, Row, Col, Nav, Form, Button, Alert } from "react-bootstrap";

// export default class UserSignUp extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   componentDidMount() {}

//   render() {
//     return (
//       <>
//         <div className="Container-fluid mx-0 px-1 ">
//           <div className="row mt-2 mx-1 px-0">
//             <div className="col-md-2  "></div>
//             <div className="col-md-8  ">
//               <CreateAccount></CreateAccount>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// class CreateAccount extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = {
//       firstName: "",
//       lastName: "",
//       emailId: "",
//       password: "",
//       passwordReenter: "",
//       phoneNo: "",
//       address: "",
//       landmark: "",
//       country: "",
//       state: "",
//       city: "",
//       pinCode: "",
//       ResultStatusText: "",
//       validated: false,
//       isAccountCreated: false,
//       showModel: false,
//       pinCodeData: "",
//       otpDetails: "",
//       otpVerified: false,
//     };
//     // preserve the initial state in a new object
//     //this.baseState = this.state; ///>>>>>>>>> note this one.
//   }

//   SetPincode = (pinCode) => {
//     this.setState({ pinCodeData: pinCode });
//   };

//   handleChangeFirstName = (event) => {
//     this.setState({ firstName: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangeLastName = (event) => {
//     this.setState({ lastName: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangeemailId = (event) => {
//     this.setState({ emailId: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangePassword = (event) => {
//     this.setState({ password: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangePasswordReenter = (event) => {
//     this.setState({ passwordReenter: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangePhoneNo = (event) => {
//     if (event.target.value.toString().length != 10) {
//       event.target.isValid = false;
//     }

//     this.setState({ phoneNoErrormsg: "" });
//     this.setState({ phoneNo: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangeAddress = (event) => {
//     this.setState({ address: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangeLandmark = (event) => {
//     this.setState({ landmark: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangeCountry = (event) => {
//     this.setState({ country: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangeState = (event) => {
//     this.setState({ state: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangeCity = (event) => {
//     this.setState({ city: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };
//   handleChangePincode = (event) => {
//     this.setState({ pinCode: event.target.value });
//     this.setState({ ResultStatusText: "" });
//   };

//   handleModelClose = (event) => {
//     this.setState({ showModel: false });
//     this.setState({ isAccountCreated: true });
//   };

//   AddUserAccount = () => {
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         storeId: this.context.storeId,
//         customerUserTypeId: 1, // Registed
//         firstName: this.state.firstName,
//         lastName: this.state.lastName,
//         mobileNo: this.state.phoneNo,
//         emailId: this.state.emailId,
//         password: this.state.password,
//         photoUrl: "",
//         address: this.state.address,
//         landmark: this.state.landmark,
//         city: this.state.city,
//         state: this.state.state,
//         country: this.state.country,
//         pinCode: this.state.pinCode,
//         createdDateTime: new Date().toJSON(),
//         modifiedDateTime: new Date().toJSON(),
//       }),
//     };

//     fetch(
//       process.env.REACT_APP_API + "Consumers/CreateUserAccount",
//       requestOptions
//     )
//       .then((response) => {
//         const isJson = response.headers
//           .get("content-type")
//           ?.includes("application/json");
//         const data = isJson && response.json();

//         if (response.ok) {
//           this.setState({
//             ResultStatusText: "Created Successfully!",
//           });
//           this.setState({ showModel: true });
//           return;
//         } else {
//           this.setState({
//             ResultStatusText: "Unable to Create account. try later!",
//           });
//           return;
//         }
//       })
//       .catch((error) => {
//         console.error("There was an error!", error);
//         alert("Error while AddUserAccount:" + error);
//       });
//   };

//   CreateAccount = () => {
//     //1. Check whether user account already exist
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         storeId: this.context.storeId,
//         customerUserTypeId: 0,
//         emailId: this.state.emailId,
//       }),
//     };

//     fetch(
//       process.env.REACT_APP_API + "Consumers/IsUserAccountExist",
//       requestOptions
//     )
//       .then((response) => {
//         const isJson = response.headers
//           .get("content-type")
//           ?.includes("application/json");
//         const data = isJson && response.json();

//         if (response.ok) {
//           this.setState({
//             ResultStatusText:
//               "User account with this emailId is already exist! try to login!",
//           });
//           return;
//         } else {
//           //2. if user account not found, add now
//           this.SendOtp();

//           return;
//         }
//       })
//       .catch((error) => {
//         console.error("There was an error!", error);
//         alert("err CreateAccount:" + error);
//       });
//   };
//   SendOtp = () => {
//     var res = axios
//       .post(
//         process.env.REACT_APP_API +
//           "Consumers/SendOtp/" +
//           this.context.storeId +
//           "," +
//           this.state.emailId
//       )
//       .then((response) => {
//         if (response.status === 200) {
//           this.setState({ otpDetails: response.data });
//           this.setState({ showOtpModal: true });
//         }
//       })
//       .catch((error) => {
//         console.error("There was an error! ", error);
//         alert("err SendOtp:" + error);
//       });
//   };

//   SignUpUserIfNotExisit = () => {};

//   ValidateExtra = () => {
//     var phoneValid = true;
//     var pinCodeValid = true;

//     if (this.state.phoneNo.toString().length !== 10) {
//       this.setState({
//         phoneNo: "",
//       });
//       phoneValid = false;
//     }

//     if (this.state.pinCode.toString().length !== 6) {
//       this.setState({
//         pinCode: "",
//       });
//       pinCodeValid = false;
//     }

//     if (phoneValid == true && pinCodeValid == true) {
//       return true;
//     } else {
//       return false;
//     }
//   };

//   ValidatePassword = () => {
//     if (this.state.password != this.state.passwordReenter) {
//       this.setState({ passwordReenter: "" });
//       alert("Passwords Not Matching!");
//       return false;
//     }
//   };

//   handleSubmit = (event) => {
//     const form = event.currentTarget;
//     event.preventDefault();
//     if (
//       form.checkValidity() === false ||
//       this.ValidateExtra() === false ||
//       this.ValidatePassword() === false
//     ) {
//       event.stopPropagation();
//     } else {
//       this.CreateAccount();
//     }
//     this.setState({ validated: true });
//   };
//   componentDidMount() {
//     this.setState({ country: this.context.storeSettings.defaultCountry });
//     this.setState({ state: this.context.storeSettings.defaultState });
//   }

//   handleOtpModalClose = () => {
//     this.setState({ showOtpModal: false });
//   };
//   OtpValidatedSuccess = () => {
//     // this.CreateAccount();
//     this.AddUserAccount();
//   };

//   render() {
//     if (this.state.isAccountCreated == true) {
//       return <Navigate to="/UserLogin" />;
//     }
//     return (
//       <>
//         {this.state.showOtpModal == true ? (
//           <OtpValidation
//             showOtpModal={this.state.showOtpModal}
//             otpDetails={this.state.otpDetails}
//             closeOtpModel={this.handleOtpModalClose}
//             OtpValidatedSuccess={this.OtpValidatedSuccess}
//             SendOtp={this.SendOtp}
//           ></OtpValidation>
//         ) : (
//           ""
//         )}

//         <h3 className="text-center">
//           <b>Signup Form</b>
//         </h3>
//         {/* <Pincode
//           invalidError="Please check pinCode"
//           lengthError="check length"
//           getData={(data) => this.SetPincode(data)}
//         /> */}
//         <Form
//           noValidate
//           validated={this.state.validated}
//           onSubmit={this.handleSubmit}
//         >
//           <div className="mx-3 px-3  bg-light border">
//             {/* <h4 className="text-dark">Account information</h4> */}

//             <Row className="py-1">
//               <Form.Group as={Col} md="4" controlId="validationCustom01">
//                 <Form.Label size="lg" className="">
//                   First Name (*)
//                 </Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder=""
//                   className="bg-white"
//                   // //autoComplete="new-off"
//                   size="lg"
//                   required
//                   value={this.state.firstName}
//                   onChange={this.handleChangeFirstName}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please enter a name
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Group as={Col} md="4" controlId="validationCustom02">
//                 <Form.Label size="lg" className="">
//                   Last Name
//                 </Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder=""
//                   className="bg-white"
//                   //autoComplete="new-off"
//                   size="lg"
//                   value={this.state.lastName}
//                   onChange={this.handleChangeLastName}
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="4" controlId="validationCustom03">
//                 <Form.Label>Phone No. (*)</Form.Label>
//                 <Form.Control
//                   type="number"
//                   placeholder=""
//                   className="bg-white"
//                   //autoComplete="new-off"
//                   size="lg"
//                   required
//                   value={this.state.phoneNo}
//                   onChange={this.handleChangePhoneNo}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please enter a valid phone no. it must be 10 digits number!
//                 </Form.Control.Feedback>
//                 {/* {this.state.phoneNoErrormsg != "" ? (
//                   <div>{this.state.phoneNoErrormsg}</div>
//                 ) : (
//                   ""
//                 )} */}
//               </Form.Group>
//             </Row>

//             <Row className="py-1">
//               <Form.Group as={Col} md="4" controlId="validationCustom04">
//                 <Form.Label size="lg" className="">
//                   Email address (*)
//                 </Form.Label>
//                 <Form.Control
//                   type="email"
//                   placeholder=""
//                   //autoComplete="new-off"
//                   required
//                   className="bg-white"
//                   size="lg"
//                   value={this.state.emailId}
//                   onChange={this.handleChangeemailId}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please enter a valid emailId.
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Group as={Col} md="4" controlId="validationCustom05">
//                 <Form.Label> Password (*)</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder=""
//                   //autoComplete="new-off"
//                   className="bg-white"
//                   size="lg"
//                   required
//                   value={this.state.password}
//                   onChange={this.handleChangePassword}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please enter a valid password.
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Group as={Col} md="4" controlId="validationCustom06">
//                 <Form.Label> Re-ener Password (*)</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder=""
//                   //autoComplete="new-off"
//                   className="bg-white"
//                   size="lg"
//                   required
//                   value={this.state.passwordReenter}
//                   onChange={this.handleChangePasswordReenter}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please re-enter password
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Row>
//             <Row className="py-1">
//               <Form.Group as={Col} md="8" controlId="validationCustom07">
//                 <Form.Label>Address (*)</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={2}
//                   size="lg"
//                   required
//                   placeholder=""
//                   //autoComplete="new-off"
//                   value={this.state.address}
//                   onChange={this.handleChangeAddress}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please enter address
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Group as={Col} md="4" controlId="validationCustom08">
//                 <Form.Label>Landmark (if any) </Form.Label>
//                 <Form.Control
//                   type="text"
//                   size="lg"
//                   placeholder=""
//                   //autoComplete="new-off"
//                   className="bg-white"
//                   value={this.state.landmark}
//                   onChange={this.handleChangeLandmark}
//                 />
//               </Form.Group>
//             </Row>

//             <Row className="pd-3">
//               <Form.Group as={Col} md="3" controlId="validationCustom09">
//                 <Form.Label>Country (*)</Form.Label>
//                 <Form.Select
//                   value={this.state.country}
//                   size="lg"
//                   required
//                   // disabled={this.context.storeSettings.disableCountrySelection}
//                   disabled
//                   onChange={this.handleChangeCountry}
//                 >
//                   {Country.getAllCountries().map((country) => (
//                     <>
//                       <option>{country.name}</option>
//                     </>
//                   ))}
//                 </Form.Select>
//                 <Form.Control.Feedback type="invalid">
//                   Please select country
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Group as={Col} md="3" controlId="validationCustom10">
//                 <Form.Label>State (*)</Form.Label>
//                 <Form.Select
//                   value={this.state.state}
//                   size="lg"
//                   required
//                   disabled={this.context.storeSettings.disableStateSelection}
//                   onChange={this.handleChangeState}
//                 >
//                   {State.getStatesOfCountry("IN").map((state) => (
//                     <>
//                       <option>{state.name}</option>
//                     </>
//                   ))}
//                 </Form.Select>
//                 <Form.Control.Feedback type="invalid">
//                   Please select State
//                 </Form.Control.Feedback>
//               </Form.Group>

//               <Form.Group as={Col} md="3" controlId="validationCustom11">
//                 <Form.Label>City (*)</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder=""
//                   //autoComplete="new-off"
//                   size="lg"
//                   required
//                   className="bg-white"
//                   value={this.state.city}
//                   onChange={this.handleChangeCity}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please Enter City
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Group as={Col} md="3" controlId="validationCustom12">
//                 <Form.Label>Pincode (*) {this.state.pinCodeData}</Form.Label>
//                 <Form.Control
//                   type="number"
//                   placeholder=""
//                   //autoComplete="new-off"
//                   className="bg-white"
//                   size="lg"
//                   required
//                   value={this.state.pinCode}
//                   onChange={this.handleChangePincode}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please enter 6 digit valid Pincode.
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Row>
//             <br></br>
//             <Row className="text-center">
//               <h5 className="blink_me text-black">
//                 {this.state.ResultStatusText}
//               </h5>
//             </Row>
//             <Row>
//               <Col className="text-center">
//                 <Button
//                   variant="primary"
//                   type="submit"
//                   className="w-50"
//                   // disabled={!this.state.otpVerified}
//                 >
//                   Verify & Save
//                 </Button>
//               </Col>
//             </Row>
//             <br></br>
//             <br></br>
//             <br></br>
//           </div>
//         </Form>
//         <Modal show={this.state.showModel} onHide={this.handleModelClose}>
//           <Modal.Header closeButton>
//             <Modal.Title>Signup Status</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>Signup Success. Proceeding to Login page...</Modal.Body>
//           <Modal.Footer>
//             <Button variant="primary" onClick={this.handleModelClose}>
//               Close
//             </Button>
//           </Modal.Footer>
//         </Modal>
//       </>
//     );
//   }
// }

// class OtpValidation extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = {
//       otpValue: "",
//       otpValidationStatus: "",
//       validated: false,
//     };
//   }

//   handleSubmit = (event) => {
//     const form = event.currentTarget;

//     if (Number(this.props.otpDetails.otpValue) == Number(this.state.otpValue)) {
//       this.props.OtpValidatedSuccess();
//       this.props.closeOtpModel();
//       this.setState({ validated: true });
//     } else {
//       //event.stopPropagation();
//       this.setState({
//         otpValidationStatus: "Invalid OTP. Please enter correct OTP!",
//       });
//       //this.setState({ validated: false });
//     }
//   };
//   onOtpValueChange = (e) => {
//     this.setState({ otpValidationStatus: "" });
//     this.setState({ otpValue: e.target.valueAsNumber });
//   };
//   handleClose = () => {
//     this.props.closeOtpModel();
//   };
//   render() {
//     return (
//       <>
//         <Modal
//           show={this.props.showOtpModal}
//           backdrop="static"
//           keyboard={false}
//           onHide={this.handleClose}
//           onkeydown="return event.key != 'Enter';"
//         >
//           {/* <Form noValidate onSubmit={this.handleSubmit}> */}
//           {/* <Form
//             noValidate
//             validated={this.state.validated}
//             onSubmit={this.handleSubmit}
//             onkeydown="return event.key != 'Enter';"
//           > */}
//           <Modal.Header closeButton>
//             <Modal.Title>OTP Verification</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             "One Time Password(OTP)" has been sent to your Email Account.
//             <br></br>
//             <br></br>
//             <br></br>
//             <Row>
//               <Form.Group
//                 className="mb-3"
//                 controlId="exampleForm.ControlInput1"
//               >
//                 <Row className="mx-1">
//                   <Col className="text-end my-3">Enter OTP: </Col>
//                   <Col className="W-50">
//                     <Form.Control
//                       type="number"
//                       size="lg"
//                       value={this.state.otpValue}
//                       // placeholder="enter OTP"
//                       className="text-center"
//                       onChange={(e) => this.onOtpValueChange(e)}
//                     ></Form.Control>
//                   </Col>
//                   <Col></Col>
//                 </Row>
//                 <Row className="mx-5">
//                   <Col className="text-end align-content-end">
//                     <Button
//                       variant="link"
//                       onClick={(e) => {
//                         this.props.SendOtp();
//                         this.setState({
//                           otpValidationStatus:
//                             "New OTP has been sent to your Email Account!",
//                         });
//                       }}
//                     >
//                       Resend OTP
//                     </Button>
//                   </Col>
//                 </Row>
//                 <br></br>
//                 <Row>
//                   <Col className="text-center ">
//                     <h5 className="blink_me text-black">
//                       {this.state.otpValidationStatus}
//                     </h5>
//                   </Col>
//                 </Row>
//               </Form.Group>
//             </Row>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button
//               variant="secondary"
//               //type="submit"
//               onClick={(e) => {
//                 this.handleSubmit(e);
//               }}
//             >
//               &nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;
//             </Button>
//             <Button variant="secondary" onClick={this.props.closeOtpModel}>
//               Cancel
//             </Button>
//             {/* <Button variant="primary" onClick={handleClose}>
//               Save Changes
//             </Button> */}
//           </Modal.Footer>
//           {/* </Form> */}
//         </Modal>
//       </>
//     );
//   }
// }
