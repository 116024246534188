import React, { Component, useState } from "react";
import { LoadSpinner } from "./LoadSpinner";
//npm install --save chart.js react-chartjs-2
import Chart from "chart.js/auto";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Navigate } from "react-router-dom";
import { InputGroup, Form, Row, Col, Nav, Table } from "react-bootstrap";

import { Link } from "react-router-dom";

import CartContext from "./CartContext";

//https://www.c-sharpcorner.com/article/create-different-charts-in-react-using-chart-js-library/
export class VisitorsCount extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dashboardSummary: "",
      customerSummary: "",
      totalCustomerAccessSummary: "",
      dateSelection: "Month",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      firstLoad: false,
      showElasticCart: true,
      data: {
        datasets: [
          {
            data: [10, 20, 30],
          },
        ],
        labels: ["Red", "Yellow", "Blue"],
      },
    };
    ChartJS.register(CategoryScale, ArcElement, Tooltip, Legend);
  }

  OnDateSelectionChange = (e) => {
    const { name, value } = e.target;

    this.setState({ dateSelection: value }, () => {
      this.GetTotalCustomerAccessSummary();
    });
  };

  GetTotalCustomerAccessSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetTotalCustomerAccessSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        0
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Customers";
          data.datasets[0].borderColor = "rgb(255, 0, 0)";
          data.datasets[0].backgroundColor = "rgb(0, 192, 0)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.1;
          this.setState({ totalCustomerAccessSummary: data });
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;

      this.GetTotalCustomerAccessSummary();
    }
  }

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        <div id="element_to_watch" className="Container-fluid my-5 py-5">
          <Row>
            <Col xs={12} className=" my-2 ">
              <Table className="m-0 p-0 ">
                <tr className="border-0 text-start w-100 m-0 p-0">
                  <td className="text-center " style={{ textAlign: "center" }}>
                    <div className="text-center border-dark bg-white ">
                      <InputGroup
                        className="mb-1 "
                        style={{ justifyContent: "end", textAlign: "end" }}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <small>by Date Range</small>
                        </InputGroup.Text>
                        <small>
                          <Form.Select
                            value={this.state.dateSelection}
                            onChange={(e) => {
                              this.OnDateSelectionChange(e);
                            }}
                          >
                            <option value="Today">Today</option>
                            <option value="Month">This Month</option>
                            <option value="This Year">This Year</option>
                            <option value="Last Year">Last Year</option>
                          </Form.Select>
                        </small>
                      </InputGroup>
                    </div>
                  </td>
                </tr>
              </Table>
              <Table className="border-0 m-0 p-0 bg-white text-dark">
                <tr className="border">
                  <td colSpan={2} className="border">
                    <div className="text-center">
                      <h4>Total Customer Visited</h4>
                    </div>
                    {this.state.totalCustomerAccessSummary == "" ? (
                      ""
                    ) : (
                      <>
                        <Bar
                          type="bar"
                          height={window.innerWidth > 1200 ? 80 : 150}
                          options={{
                            title: {
                              display: true,
                              text: "COVID-19 Cases of Last 6 Months",
                              fontSize: 20,
                            },
                            legend: {
                              display: true, //Is the legend shown?
                              position: "top", //Position of the legend.
                            },
                          }}
                          data={this.state.totalCustomerAccessSummary}
                        />
                      </>
                    )}
                  </td>
                </tr>
              </Table>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
