// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itempagination {
  align-items: center;
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;

  justify-content: center;
  width: 40px;
}
.pagination-page {
  font-weight: 700;
}

.disabled-page {
  color: #808e9b;
}

.activepagination {
  border: solid 1px #808e9b;
  border-radius: 40px;
  color: #808e9b;
}

.break-mepagination {
}

.paginationpagination {
  align-items: center;
  /* background-color: #0fbcf9; */
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 1000px;
}

.previouspagination {
  border-right: solid 1px #808e9b;
  font-size: 14px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 150px;
}
.nextpagination {
  border-left: solid 1px #808e9b;
  font-size: 14px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CSS/pagination.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,eAAe;EACf,YAAY;;EAEZ,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;AACA;;AAEA;EACE,mBAAmB;EACnB,+BAA+B;EAC/B,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,YAAY;EACZ,OAAO;EACP,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,8BAA8B;EAC9B,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,YAAY;AACd","sourcesContent":[".itempagination {\r\n  align-items: center;\r\n  color: black;\r\n  cursor: pointer;\r\n  display: flex;\r\n  font-size: 14px;\r\n  height: 40px;\r\n\r\n  justify-content: center;\r\n  width: 40px;\r\n}\r\n.pagination-page {\r\n  font-weight: 700;\r\n}\r\n\r\n.disabled-page {\r\n  color: #808e9b;\r\n}\r\n\r\n.activepagination {\r\n  border: solid 1px #808e9b;\r\n  border-radius: 40px;\r\n  color: #808e9b;\r\n}\r\n\r\n.break-mepagination {\r\n}\r\n\r\n.paginationpagination {\r\n  align-items: center;\r\n  /* background-color: #0fbcf9; */\r\n  display: flex;\r\n  flex-direction: row;\r\n  height: 60px;\r\n  justify-content: center;\r\n  list-style-type: none;\r\n  position: relative;\r\n  width: 1000px;\r\n}\r\n\r\n.previouspagination {\r\n  border-right: solid 1px #808e9b;\r\n  font-size: 14px;\r\n  height: 60px;\r\n  left: 0;\r\n  position: absolute;\r\n  width: 150px;\r\n}\r\n.nextpagination {\r\n  border-left: solid 1px #808e9b;\r\n  font-size: 14px;\r\n  height: 60px;\r\n  position: absolute;\r\n  right: 0;\r\n  width: 150px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
