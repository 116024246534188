// import React, { Component } from "react";
// import CartContext from "./CartContext";
// import { Link } from "react-router-dom";
// import { Navigate } from "react-router-dom";

// import { Container, Row, Col, Nav, Form, Button, Alert } from "react-bootstrap";

// export default class UserLogin extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   componentDidMount() {}

//   render() {
//     return (
//       <>
//         <div className="Container-fluid mx-0 px-1 ">
//           <div className="row mt-2 mx-1 px-0">
//             <div className="col-md-4 "></div>
//             <div className="col-md-4 ">
//               <br />
//               <br />
//               <h1 className="text-center">
//                 {" "}
//                 <b>Login Form</b>{" "}
//               </h1>
//               <Login></Login>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// class Login extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = {
//       emailId: "",
//       password: "",
//       postId: "",
//       isValidLogin: "false",
//       loginResultText: "",
//       userData: null,
//     };

//     this.handleChangeemailId = this.handleChangeemailId.bind(this);
//     this.handleChangePassword = this.handleChangePassword.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   handleChangeemailId(event) {
//     this.setState({ emailId: event.target.value });
//     this.setState({ loginResultText: "" });
//   }
//   handleChangePassword(event) {
//     this.setState({ password: event.target.value });
//     this.setState({ loginResultText: "" });
//   }

//   ValidateLogin(emailId, password) {
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         storeId: this.context.storeId,
//         emailId: this.state.emailId.trim(),
//         password: this.state.password.trim(),
//       }),
//     };

//     fetch(process.env.REACT_APP_API + "Consumers/Login", requestOptions)
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status == 404 || data.status == 400) {
//           this.setState({ isValidLogin: false });
//           this.setState({ loginResultText: "Invalid Username/Password" });
//           return;
//         }
//         this.setState({ isValidLogin: true });
//         this.setState({ loginResultText: "Login success!" });
//         this.setState({ userData: data });
//         this.context.storeUserData(data);
//       })
//       .catch((error) => {
//         console.error("There was an error!", error);
//         alert("err:" + error);
//       });
//   }

//   handleSubmit(event) {
//     event.preventDefault();
//     this.ValidateLogin(this.state.emailId, this.state.password);
//   }
//   componentDidMount() {}
//   componentDidUpdate() {}

//   render() {
//     if (this.state.isValidLogin == true) {
//       if (this.context.prevPage != "") {
//         var prevPage = this.context.prevPage;
//         this.context.storePreviousPage("");
//         return <Navigate to={prevPage} />;
//       }

//       return <Navigate to="/home" />;
//     }
//     return (
//       <>
//         <br></br>
//         <div className="mx-0 px-3 p-3 bg-secondary border">
//           <Form onSubmit={this.handleSubmit}>
//             <div className="text-end">
//               <Nav.Link
//                 as={Link}
//                 className=" mx-1 text-warning"
//                 to="/UserSignUp"
//               >
//                 <h5>
//                   New User? click here to{" "}
//                   <u className="">
//                     <b>Sign-up</b>
//                   </u>
//                 </h5>
//               </Nav.Link>
//             </div>
//             {/* Enter Email */}
//             <Form.Group className="mb-3" controlId="formBasicEmail">
//               <Form.Label className="text-white">
//                 <h5>Email address</h5>
//               </Form.Label>
//               <Form.Control
//                 size="lg"
//                 type="email"
//                 placeholder="Enter your emailId"
//                 value={this.state.emailId}
//                 onChange={this.handleChangeemailId}
//               />
//             </Form.Group>

//             {/* Enter Password */}
//             <Form.Group className="mb-3" controlId="formBasicPassword">
//               <Form.Label className="text-white">
//                 <h5>Password</h5>
//               </Form.Label>
//               <Form.Control
//                 size="lg"
//                 type="password"
//                 placeholder="Enter Password"
//                 value={this.state.password}
//                 onChange={this.handleChangePassword}
//               />
//             </Form.Group>

//             {/* Login Submit */}
//             <div className="text-center text-warning">
//               <h5 className="blink_me">{this.state.loginResultText}</h5>
//             </div>
//             <br></br>
//             <div className="text-center">
//               <Button
//                 variant="primary"
//                 type="submit"
//                 className="w-25"
//                 size="lg"
//               >
//                 <b>Login</b>
//               </Button>
//             </div>

//             {/* Forgot Password */}
//             <div className="text-center">
//               <Nav.Link
//                 as={Link}
//                 className=" mx-1 text-white"
//                 to="/UserForgotPassword"
//               >
//                 <h5>
//                   <u>Forgot Password?</u>
//                 </h5>
//               </Nav.Link>
//             </div>
//             {/* Forgot Password */}
//           </Form>
//         </div>
//       </>
//     );
//   }
// }
