// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navSubmenu.active {
  display: none;
}
.mob-nav-link .navProductMainmenu .main-logo {
  /* width: 140px; */
}
.mob-nav-link {
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
}
.navbar .navbar-nav .nav-link {
  margin-bottom: 0;
}
.mob-nav-link .dropdown {
  padding: 15px;
  margin-top: 10px;
}

.dropdown-list img {
  width: 100px;
}

.dropdown-list {
  position: absolute;
  bottom: -103px;
  background: #fff;
  right: 0;
  width: 100%;
  box-shadow: 0 4px 24px 0 #00000012;
  border-radius: 5px;
  display: none;
}
.dropdown-list.show {
  display: block;
}
.dropdown-list ul {
  list-style-type: none;
}
.fixed-top-mob {
  position: fixed;
  top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,wBAAwB;AAC1B;;AAEA;EACE;aACW;AACb;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,QAAQ;EACR,WAAW;EACX,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,MAAM;AACR","sourcesContent":["body {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\r\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  /* overflow-x: hidden; */\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n    monospace;\r\n}\r\n.navSubmenu.active {\r\n  display: none;\r\n}\r\n.mob-nav-link .navProductMainmenu .main-logo {\r\n  /* width: 140px; */\r\n}\r\n.mob-nav-link {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-left: 10px;\r\n  position: relative;\r\n}\r\n.navbar .navbar-nav .nav-link {\r\n  margin-bottom: 0;\r\n}\r\n.mob-nav-link .dropdown {\r\n  padding: 15px;\r\n  margin-top: 10px;\r\n}\r\n\r\n.dropdown-list img {\r\n  width: 100px;\r\n}\r\n\r\n.dropdown-list {\r\n  position: absolute;\r\n  bottom: -103px;\r\n  background: #fff;\r\n  right: 0;\r\n  width: 100%;\r\n  box-shadow: 0 4px 24px 0 #00000012;\r\n  border-radius: 5px;\r\n  display: none;\r\n}\r\n.dropdown-list.show {\r\n  display: block;\r\n}\r\n.dropdown-list ul {\r\n  list-style-type: none;\r\n}\r\n.fixed-top-mob {\r\n  position: fixed;\r\n  top: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
