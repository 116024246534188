import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import ProductCardMobile from "./ProductCardMobile";
import { BsFillHeartFill } from "react-icons/bs";
import RangeSlider from "react-bootstrap-range-slider";
import { BsFunnel } from "react-icons/bs";
import { Button, SplitButton, Badge, Offcanvas } from "react-bootstrap";
import { Table, Form, Row, Col } from "react-bootstrap";
import CartContext from "./CartContext";
import { useState } from "react";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GreenAd } from "./ProductsList";
import { useSearchParams } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
// import ReactPaginate from "https://cdn.skypack.dev/react-paginate@7.1.3";
import "../CSS/pagination.css";
import ReactPaginate from "react-paginate";

export class ProductsListMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      weightdefault: "",
      priceRangeCurrentValue: 200,
      priceRangeMinValue: 0,
      priceRangeMaxValue: 100,
      discountFilter: 0,
      title: "",
      selectedMainCategoryId: 0, //ALL
      selectedDiscount: 0,
      subCategoriesState: [],
      brandsState: [],
      prods: [],
      sorting: 1,
      showFilterOffCanvas: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loading: true,
      pageCount: 20,
      totalProductsInPage: 20,
      pageItemEnd: 20,
      pageItemStart: 0,
      reloadPaginate: false,
    };
    this.addToCart = this.addToCart.bind(this);
  }

  handleSortingChange = (e) => {
    this.ResetPaginate();

    this.setState({ sorting: e.target.value });
    if (e.target.value == 1) {
      var r = this.state.prods.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1));
      this.setState({ prods: r });
    } else if (e.target.value == 2) {
      this.state.prods.sort((a, b) =>
        a.mrp - (a.mrp * a.discount) / 100 > b.mrp - (b.mrp * b.discount) / 100
          ? 1
          : -1
      );
      this.setState({ prods: this.state.prods });
    } else if (e.target.value == 3) {
      this.state.prods.sort((a, b) =>
        a.mrp - (a.mrp * a.discount) / 100 < b.mrp - (b.mrp * b.discount) / 100
          ? 1
          : -1
      );
      this.setState({ prods: this.state.prods });
    } else if (e.target.value == 4) {
      this.state.prods.sort((a, b) => (a.discount > b.discount ? 1 : -1));
      this.setState({ prods: this.state.prods });
    } else if (e.target.value == 5) {
      this.state.prods.sort((a, b) => (a.discount < b.discount ? 1 : -1));
      this.setState({ prods: this.state.prods });
    }
  };

  setRangeValue = (value) => {
    this.setState({ priceRangeCurrentValue: value });
  };
  addToCart = (cartItem) => {
    this.props.addToCartProp(cartItem);
  };

  SetPriceRange = () => {
    var i =
      Math.max(
        ...this.context.prods.map((p) => p.mrp - (p.mrp * p.discount) / 100)
      ) + 20;
    this.setState({ priceRangeMaxValue: i });
    this.setState({ priceRangeCurrentValue: i });
  };
  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 100));
  };

  GetBrands = () => {
    var brands = [];
    this.context.prods.map((p) => {
      if (
        brands.filter((f) => f.id == p.brandId).length == 0 &&
        p.brandId != null
      ) {
        brands.push({
          id: p.brandId,
          brandName: this.context.brands.filter((f) => f.id == p.brandId)[0]
            .brandName,
          checked: false,
        });
      }
    });
    if (brands.length > 0) {
      this.setState({ brandsState: brands });
    }
  };
  componentDidMount() {
    if (this.context.prods.length < 100) {
      this.setState({ loading: false }); // showing the app
    } else {
      this.fakeRequest().then(() => {
        this.setState({ loading: false }); // showing the app
      });
    }
    this.InitializeComponent();
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (this.context.reloadProductListView === "true") {
      this.setState({ pageItemEnd: this.state.totalProductsInPage });
      this.setState({ pageItemStart: 0 });
      this.InitializeComponent();
    }
  }

  InitializeComponent() {
    this.ResetPaginate();

    this.setState({ prods: this.context.prods }, () => this.SetPriceRange());
    this.GetBrands();
    this.setState({ selectedDiscount: 0 });

    if (this.context.productListViewType === "ProductGroup") {
      this.context.subCategories.map((sub) => {
        if (
          this.state.subCategoriesState.filter((f) => f.name === sub.name)
            .length == 0
        ) {
          this.state.subCategoriesState.push({
            name: sub.name,
            id: sub.id,
            checked: false,
          });
        }
        this.setState({ subCategoriesState: this.state.subCategoriesState });
      });
    } else if (this.context.productListViewType === "MainCategory") {
      this.setState({ subCategoriesState: [] }, () => {
        this.context.subCategories
          .filter(
            (f) =>
              f.mainCategoryId === this.context.productListViewMainCategoryId
          )
          .map((sub) => {
            if (
              this.state.subCategoriesState.filter((f) => f.name === sub.name)
                .length == 0
            ) {
              this.state.subCategoriesState.push({
                name: sub.name,
                id: sub.id,
                checked: false,
              });
            }
          });
        this.setState({ subCategoriesState: this.state.subCategoriesState });
      });
    }

    this.context.UpdateReloadProductListView("false");
  }

  ResetPaginate = () => {
    this.setState({ pageItemEnd: this.state.totalProductsInPage });
    this.setState({ pageItemStart: 0 });
    this.setState({ reloadPaginate: true }, () => {
      this.setState({ reloadPaginate: false });
    });
  };

  ApplyFilter = () => {
    this.ResetPaginate();

    //1. Copy context prods to tempprods
    var tempprods = this.context.prods;

    //2. Main Category
    if (this.state.selectedMainCategoryId == 0) {
    } else {
      tempprods = tempprods.filter(
        (e) => e.subCategory.mainCategoryId == this.state.selectedMainCategoryId
      );
    }

    //3. Subcategory checked?

    var prodssubcategoryFiltered = [];
    var filteredSubcat = this.state.subCategoriesState.filter(
      (f) => f.checked === true
    );
    if (filteredSubcat.length != 0) {
      filteredSubcat.map((e) => {
        var filtered = tempprods.filter((tf) => tf.subCategoryId == e.id);
        if (filtered.length > 0) {
          filtered.map((fil) => prodssubcategoryFiltered.push(fil));
        }
      });
      tempprods = prodssubcategoryFiltered;
    }

    //3. brand Filtered
    var prodbrandFiltered = [];
    var filteredBrand = this.state.brandsState.filter(
      (f) => f.checked === true
    );
    if (filteredBrand.length != 0) {
      filteredBrand.map((e) => {
        var filtered = tempprods.filter((tf) => tf.brandId == e.id);
        if (filtered.length > 0) {
          filtered.map((fil) => prodbrandFiltered.push(fil));
        }
      });
      tempprods = prodbrandFiltered;
    }

    //4. PriceRange
    tempprods = tempprods.filter(
      (e) =>
        e.mrp - (e.mrp * e.discount) / 100 <=
        Number(this.state.priceRangeCurrentValue)
    );

    //5. PriceDiscount
    tempprods = tempprods.filter(
      (e) => e.discount >= Number(this.state.selectedDiscount)
    );

    //final. copy tempprods to this.state.prods
    this.setState({ prods: tempprods });
  };

  onPriceRangeChange = (e) => {
    this.setState({ priceRangeCurrentValue: e.target.value }, () =>
      this.ApplyFilter()
    );
  };
  onMainCategorySelectChangeEvent = (e) => {
    this.setState({ selectedMainCategoryId: e.target.value }, () => {
      var result = [];

      //ALL
      if (this.state.selectedMainCategoryId == 0) {
        this.setState({ subCategoriesState: [] }, () => {
          this.context.subCategories.map((sub) => {
            this.state.subCategoriesState.push({
              name: sub.name,
              id: sub.id,
              checked: false,
            });
            this.setState({
              subCategoriesState: this.state.subCategoriesState,
            });
          });
        });
      } else {
        this.setState({ subCategoriesState: [] }, () => {
          result = this.context.subCategories.filter(
            (f) => f.mainCategoryId == e.target.value
          );

          result.map((sub) => {
            this.state.subCategoriesState.push({
              name: sub.name,
              id: sub.id,
              checked: false,
            });
          });

          this.setState({ subCategoriesState: this.state.subCategoriesState });
        });
      }
      this.ApplyFilter();
    });
  };

  onSubCategorySelectChangeEvent = (e, row) => {
    //update
    this.state.subCategoriesState.map((subc) =>
      subc.name === e.target.name ? (subc.checked = e.target.checked) : ""
    );
    this.setState({ subCategoriesState: this.state.subCategoriesState }, () =>
      this.ApplyFilter()
    );
  };

  onBrandChange = (e, row) => {
    this.state.brandsState.map((subc) =>
      subc.brandName === e.target.name ? (subc.checked = e.target.checked) : ""
    );
    this.setState({ brandsState: this.state.brandsState }, () =>
      this.ApplyFilter()
    );
  };

  onChangeDiscount = (e) => {
    this.setState({ selectedDiscount: Number(e.target.value) }, () =>
      this.ApplyFilter()
    );
  };

  //this validation is required when same maincategory menu item is clicked sebsequently
  //TODO to be deleted: unused
  ValidateAndReloadSubcategory() {
    if (this.context.productListViewType === "MainCategory") {
      //Valiate
      var isCorrect = false;
      if (this.state.subCategoriesState.length > 0) {
        var filteredSubCat = this.context.subCategories.filter(
          (f) => f.mainCategoryId === this.context.productListViewMainCategoryId
        );

        filteredSubCat.map((e1) => {
          this.state.subCategoriesState.map((e) => {
            if (e1.name === e.name) {
              isCorrect = true;
            }
          });
        });
      }

      if (isCorrect != true) {
        this.setState({ subCategoriesState: [] }, () => {
          this.context.subCategories
            .filter(
              (f) =>
                f.mainCategoryId === this.context.productListViewMainCategoryId
            )
            .map((sub) => {
              if (
                this.state.subCategoriesState.filter((f) => f.name === sub.name)
                  .length == 0
              ) {
                this.state.subCategoriesState.push({
                  name: sub.name,
                  id: sub.id,
                  checked: false,
                });
              }
            });
          this.setState({ subCategoriesState: this.state.subCategoriesState });
        });
      }
    }
  }
  ResetFilterClick = () => {
    this.InitializeComponent();
    // if (this.context.productListViewType === "ProductGroup") {
    //   this.setState({ prods: this.context.prods }, () => {
    //     this.setState({ selectedMainCategoryId: 0 }, () => {
    //       this.setState({ subCategoriesState: [] }, () => {
    //         this.context.subCategories.map((sub) => {
    //           this.state.subCategoriesState.push({
    //             name: sub.name,
    //             id: sub.id,
    //             checked: false,
    //           });
    //           this.setState({
    //             subCategoriesState: this.state.subCategoriesState,
    //           });
    //         });
    //       });
    //     });
    //   });
    // } else if (this.context.productListViewType === "MainCategory") {
    //   this.setState({ prods: this.context.prods }, () => {
    //     this.setState({ selectedMainCategoryId: 0 }, () => {
    //       this.setState({ subCategoriesState: [] }, () => {
    //         this.context.subCategories
    //           .filter(
    //             (f) =>
    //               f.mainCategoryId ===
    //               this.context.productListViewMainCategoryId
    //           )
    //           .map((sub) => {
    //             this.state.subCategoriesState.push({
    //               name: sub.name,
    //               id: sub.id,
    //               checked: false,
    //             });
    //             this.setState({
    //               subCategoriesState: this.state.subCategoriesState,
    //             });
    //           });
    //       });
    //     });
    //   });
    // }
    // this.SetPriceRange();
    // this.setState({ selectedDiscount: 0 });
  };

  ShowFilterOffCanvas = (e) => {
    this.setState({ showFilterOffCanvas: true });
  };

  handleMenuOffCanvasClose = (e) => {
    this.setState({ showFilterOffCanvas: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  handlePageClick = (event) => {
    var startIndex =
      (event.selected + 1) * this.state.totalProductsInPage -
      this.state.totalProductsInPage;

    //change end value first to avoid hanging
    this.setState({
      pageItemEnd: startIndex + this.state.totalProductsInPage,
    });

    this.setState({
      pageItemStart: startIndex,
    });
    window.scrollTo(0, 0);
  };

  render() {
    const { loading } = this.state;
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (loading) {
      return (
        <>
          <LoadSpinner open="true"></LoadSpinner>
        </>
      );
    }
    return (
      <>
        <Helmet>
          {/* <link rel="icon" type="image/x-icon" href="dist/images/fav.ico" /> */}
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
          {/* <script src="dist/js/grncustom.js"></script> */}
        </Helmet>
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {window.location.hostname == "shop.green.com.pg" ? (
          <>
            <div style={{ paddingTop: "65px" }}>
              <GreenAd></GreenAd>
            </div>
          </>
        ) : (
          <></>
        )}
        <div
          className="mx-0 px-0"
          style={{
            backgroundColor: this.context.theme.homepageBackgroundColor,
            color: this.context.theme.homepageTitlesTextColor,
            paddingTop: "130x",
          }}
        >
          {this.context.theme.hideFilters == true ||
          (this.context.productListViewType === "SubCategory" &&
            this.context.theme.hidePriceRangeOnFilters == true) ? (
            <></>
          ) : (
            <>
              <Offcanvas
                show={this.state.showFilterOffCanvas}
                onHide={this.handleMenuOffCanvasClose}
                className="h-auto"
                placement="end"
                style={{ width: "60%" }}
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="bg-dark filterDsn">
                    <div className="border bg-white border my-0 p-0">
                      <Table className="bg-white my-0 p-2 border-0">
                        <Row>
                          <Col className="text-secondary">
                            <div className="text-center cl-black bold_font d-inline-block w-100">
                              <h2>
                                <b>Filters</b>
                              </h2>
                            </div>
                            <div className="text-end">
                              <Button
                                className="btn-green-2 d-inline-block text-end"
                                variant="secondary"
                                onClick={this.ResetFilterClick}
                              >
                                Reset Filter
                              </Button>
                            </div>
                            {this.context.productListViewType ===
                            "ProductGroup" ? (
                              <>
                                <br></br>
                              </>
                            ) : (
                              ""
                            )}

                            {this.context.productListViewType ===
                            "MainCategory" ? (
                              <>
                                <h5 className="py-2">
                                  <b>Category</b>
                                </h5>
                                <div className="border categorydiv">
                                  {this.state.subCategoriesState.map(
                                    (subcategory) => (
                                      <Form.Check
                                        type="checkbox"
                                        size="lg"
                                        name={subcategory.name}
                                        label={subcategory.name}
                                        id={subcategory.id}
                                        onChange={
                                          this.onSubCategorySelectChangeEvent
                                        }
                                      ></Form.Check>
                                    )
                                  )}
                                </div>
                                <br></br>
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.brandsState.length > 1 ? (
                              <>
                                <br></br>
                                <h6 className="py-2 text-dark">
                                  <b>
                                    {this.context.store.businessTypeId == 20
                                      ? "Artist"
                                      : "Brand"}
                                    {/* <b>Brand</b> */}
                                  </b>
                                </h6>
                                <div className="border categorydiv">
                                  {this.state.brandsState.map((brand) => (
                                    <h6>
                                      <Form.Check
                                        type="checkbox"
                                        size=""
                                        name={brand.brandName}
                                        label={brand.brandName}
                                        checked={brand.checked}
                                        id={brand.id}
                                        onChange={this.onBrandChange}
                                      ></Form.Check>
                                    </h6>
                                  ))}
                                </div>
                                <br></br>
                              </>
                            ) : (
                              ""
                            )}
                            {this.context.theme.hidePriceRangeOnFilters ==
                            true ? (
                              <></>
                            ) : (
                              <>
                                <h5 className="py-2">
                                  <b>Price Range </b>
                                </h5>
                                <div className="border">
                                  <RangeSlider
                                    value={this.state.priceRangeCurrentValue}
                                    size="lg"
                                    onChange={(e) => this.onPriceRangeChange(e)}
                                    tooltipPlacement="bottom"
                                    tooltip="on"
                                    min={this.state.priceRangeMinValue}
                                    max={this.state.priceRangeMaxValue}
                                    step={10}
                                  />
                                </div>
                                <br></br>
                              </>
                            )}

                            {this.context.theme.hideDiscountOnFilters ==
                            true ? (
                              <></>
                            ) : (
                              <>
                                <h5 className="py-2">
                                  <b>Discount</b>
                                </h5>
                                <div className="border">
                                  <Form.Check
                                    type="radio"
                                    label="All"
                                    group="discount"
                                    value="0"
                                    checked={this.state.selectedDiscount === 0}
                                    onChange={this.onChangeDiscount}
                                  ></Form.Check>
                                  <Form.Check
                                    type="radio"
                                    label="Above 5%"
                                    group="discount"
                                    value="5"
                                    checked={this.state.selectedDiscount === 5}
                                    onChange={this.onChangeDiscount}
                                  ></Form.Check>
                                  <Form.Check
                                    type="radio"
                                    label="Above 10%"
                                    group="discount"
                                    value="10"
                                    checked={this.state.selectedDiscount === 10}
                                    onChange={this.onChangeDiscount}
                                  ></Form.Check>
                                  <Form.Check
                                    type="radio"
                                    label="Above 20%"
                                    group="discount"
                                    value="20"
                                    checked={this.state.selectedDiscount === 20}
                                    onChange={this.onChangeDiscount}
                                  ></Form.Check>
                                  <Form.Check
                                    type="radio"
                                    label="Above 30%"
                                    group="discount"
                                    value="30"
                                    checked={this.state.selectedDiscount === 30}
                                    onChange={this.onChangeDiscount}
                                  ></Form.Check>
                                  <Form.Check
                                    type="radio"
                                    label="Above 50%"
                                    group="discount"
                                    value="50"
                                    checked={this.state.selectedDiscount === 50}
                                    onChange={this.onChangeDiscount}
                                  ></Form.Check>
                                  <Form.Check
                                    type="radio"
                                    label="Above 75%"
                                    group="discount"
                                    value="75"
                                    checked={this.state.selectedDiscount === 75}
                                    onChange={this.onChangeDiscount}
                                  ></Form.Check>
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Table>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
              <br />
            </>
          )}

          <Row className="m-0 p-0">
            <Col xs={12} className="py-0 m-0">
              <Row className="m-0 p-0 align-middle">
                <Col
                  xs={12}
                  className="text-center align-middle align-content-center "
                >
                  <div class="title-dsn text-center">
                    <span
                      style={{
                        color: this.context.theme.homepageTitlesTextColor,
                      }}
                    >
                      {window.location.hostname == "shop.green.com.pg" ? (
                        <>
                          <h2 class="bold_font">
                            {this.context.prodListTitle}
                          </h2>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col xs={12} className="text-center pt-5">
                              <h4
                                class="bold_font"
                                style={{
                                  color:
                                    this.context.theme.homepageTitlesTextColor,
                                }}
                              >
                                {this.context.prodListTitle}
                              </h4>
                            </Col>
                          </Row>
                        </>
                      )}

                      {this.context.prodListDescription ? (
                        <>
                          <h5 style={{ textAlign: "justify" }}>
                            {this.context.prodListDescription}
                          </h5>
                        </>
                      ) : (
                        <></>
                      )}

                      <p>
                        {this.context.theme.hideProductCount == false ? (
                          <>{this.state.prods.length} Products available</>
                        ) : (
                          ""
                        )}
                      </p>
                    </span>
                  </div>
                  <Helmet>
                    <title>{this.context.prodListTitle}</title>
                    <meta
                      content={this.context.prodListDescription}
                      name="description"
                    />
                  </Helmet>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col
                  xs={12}
                  className="filterSortDsn align-middle text-center my-2 "
                >
                  <Row className="border-0 p-0 m-0 w-100 border">
                    <Col
                      className="text-start align-middle align  border-0 mb-2"
                      xs={12}
                    >
                      <b>Sort By:</b>
                    </Col>
                    <Col className="text-start align-middle border-0 " xs={6}>
                      <Form.Select
                        value={this.state.sorting}
                        className="fw-bold"
                        onChange={this.handleSortingChange}
                      >
                        <option value={1}>Popularity</option>
                        <option value={2}>Price-Low To High</option>
                        <option value={3}>Price-High to Low</option>
                        <option value={4}>Disc-Low to High</option>
                        <option value={5}>Disc-High to Low</option>
                      </Form.Select>
                    </Col>
                    <Col className="align-middle border-0 m-0 p-0 " xs={6}>
                      <Button
                        className="btn-green-3 d-inline-block"
                        onClick={(e) => {
                          this.ShowFilterOffCanvas(e);
                        }}
                      >
                        Filters &nbsp;
                        <BsFunnel size={"13px"}></BsFunnel>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="row mx-1 p-0 ">
                <div className="col-md-12 m-0 p-0">
                  <div className="row justify-content-around m-0 p-0 ">
                    {this.state.prods
                      .slice(this.state.pageItemStart, this.state.pageItemEnd)
                      .map((prod) => (
                        <>
                          {window.innerWidth <= 360 ? (
                            <>
                              <div
                                className="card mb-3 m-0 p-0 cardwithshadow CornersRounded"
                                style={{
                                  width:
                                    this.context.theme.productCardWidthMobile +
                                    "cm",
                                  backgroundColor:
                                    this.context.theme.homepageBackgroundColor,
                                  borderColor:
                                    this.context.theme.productCardBorderColor,
                                }}
                              >
                                <ProductCardMobile
                                  prod={prod}
                                ></ProductCardMobile>
                              </div>
                            </>
                          ) : window.innerWidth <= 390 ? (
                            <>
                              <div
                                className="card mb-3 m-0 p-0 CornersRounded"
                                style={{
                                  width:
                                    this.context.theme.productCardWidthMobile +
                                    "cm",
                                  backgroundColor:
                                    this.context.theme.homepageBackgroundColor,
                                  borderColor:
                                    this.context.theme.productCardBorderColor,
                                }}
                              >
                                <ProductCardMobile
                                  prod={prod}
                                ></ProductCardMobile>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="card p-0 m-0 mb-3  "
                                style={{
                                  width:
                                    this.context.theme.productCardWidthMobile +
                                    "cm",
                                  backgroundColor:
                                    this.context.theme.homepageBackgroundColor,
                                  borderColor:
                                    this.context.theme.productCardBorderColor,
                                }}
                              >
                                <ProductCardMobile
                                  prod={prod}
                                ></ProductCardMobile>
                              </div>
                            </>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              </div>
              {this.state.prods.length / this.state.totalProductsInPage > 1 ? (
                <>
                  <div
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: 10,
                      boxSizing: "border-box",
                      width: "100%",
                    }}
                  >
                    {this.state.reloadPaginate == false ? (
                      <>
                        <div
                          className=" w-100 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <ReactPaginate
                            nextLabel=">"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={
                              this.state.prods.length /
                              this.state.totalProductsInPage
                            }
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
        <br></br>
        <br></br>
      </>
    );
  }
}
export default function HoverControlledSplitButton(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <SplitButton
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onToggle={() => setIsClicked(!isClicked)}
      show={isClicked || isHovered}
    />
  );
}

export class ProductListViewByProductGroupMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsListMobile></ProductsListMobile>
      </>
    );
  }
}
export class ProductListViewByMainCategoryMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsListMobile></ProductsListMobile>
      </>
    );
  }
}
export class ProductListViewBySubCategoryMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsListMobile></ProductsListMobile>
      </>
    );
  }
}
