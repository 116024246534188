import React, { useState, useContext, Component, createRef } from "react";
import pincode from "pincode-distance/lib/pincode";
import { Country, State, City } from "country-state-city";
import CartContext from "./CartContext";
import "../CSS/3d.css";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { sha256 } from "js-sha256";
import { OfflineStore } from "./OfflineStore";
import IncDecCounter from "./IncDecCounterControl";

import {
  Modal,
  Nav,
  Form,
  FloatingLabel,
  Table,
  Container,
  Row,
  Col,
  Button,
  Stack,
} from "react-bootstrap";
import { render } from "@testing-library/react";
import { getCurrentDateTime } from "../../helper";
import { useEffect } from "react";
import { CheckoutUpiManual } from "./CheckoutUpiManual";

export default class CheckoutAndPayForm extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showStoreOffline: false,
      registeredUserAccount: "",
      guestUserAccount: "",
      firstName: "",
      lastName: "",
      emailId: "",
      mobileNo: "",
      address: "",
      landmark: "",
      country: "",
      state: "",
      city: "",
      pinCode: "",
      paymentmode: "",
      formValidationErrorMsg: "",
      validatedAndSubmitted: "false",
      errormsg: "",
      loadSpinnerOpen: false,
      hideDifferentAddress: "true",
      deliveryAddressState: "current", //current or different
      showUnavailableItemsModal: false,
      unavailableCartItems: [],
      phonepeResponseUrl: "",
      phonePeWindow: "",
      showCheckoutUpiManual: false,
      acceptTermsAndConditions: false,
      storePickup: false,
      homeDelivery: false,
    };
  }
  static PaymentMode_CashOnDelivery = 1;
  static PaymentMode_RazorPay = 2;
  static PaymentMode_BankReceipt = 9;
  static OrderStatusRecived = 1;

  handleDeliveryMethodChange = (event) => {
    if (event.target.id === "StorePickup") {
      if (event.target.checked == true) {
        this.setState({ storePickup: true });
        this.setState({ homeDelivery: false });
        this.setState({ deliveryAddressState: "current" });
        this.context.SetDeliveryMethod("StorePickup");
      }
    } else if (event.target.id === "HomeDelivery") {
      if (event.target.checked == true) {
        this.setState({ homeDelivery: true });
        this.setState({ storePickup: false });
        this.context.SetDeliveryMethod("HomeDelivery");
      }
    }
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  HideStoreOfflineModal = () => {
    this.setState({ showStoreOffline: false });
  };

  HideCheckoutUpiManual = () => {
    this.setState({ showCheckoutUpiManual: false });
  };

  handleChangeFirstName = (event) => {
    this.setState({ firstName: event.target.value });
  };
  handleChangeLastName = (event) => {
    this.setState({ lastName: event.target.value });
  };
  handleChangeemailId = (event) => {
    this.setState({ emailId: event.target.value });
  };
  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };
  handleChangePasswordReenter = (event) => {
    this.setState({ passwordReenter: event.target.value });
  };
  handleChangePhoneNo = (event) => {
    if (
      event.target.value.toString().length <=
      this.context.storeSettings.phoneNumberLength
    )
      this.setState({ mobileNo: event.target.value });
  };
  handleChangeAddress = (event) => {
    this.setState({ address: event.target.value });
  };
  handleChangeLandmark = (event) => {
    this.setState({ landmark: event.target.value });
  };
  handleChangeCountry = (event) => {
    this.setState({ country: event.target.value });
  };
  handleChangeState = (event) => {
    this.setState({ state: event.target.value });
  };
  handleChangeCity = (event) => {
    this.setState({ city: event.target.value });
  };
  handleChangePincode = (event) => {
    this.setState({ pinCode: event.target.value });
    this.context.SetDeliveryPinCode(event.target.value);
  };
  handleChangePaymentMode = (event) => {
    this.setState({ paymentmode: event.target.id });
    this.setState({ errormsg: "" });
  };
  handleChangeDeliveryAddress = (event) => {
    if (event.target.id === "deliverytodifferentaddress") {
      this.setState({ hideDifferentAddress: "" });
      this.setState({ deliveryAddressState: "different" });

      this.setState({ firstName: "" });
      this.setState({ lastName: "" });
      this.setState({ emailId: this.state.registeredUserAccount.emailId });
      this.setState({ mobileNo: "" });
      this.setState({ address: "" });
      this.setState({ landmark: "" });
      this.setState({ country: this.context.storeSettings.defaultCountry });
      this.setState({ state: this.context.storeSettings.defaultState });
      this.setState({ city: "" });
      this.setState({ pinCode: "" });
    } else {
      this.setState({ hideDifferentAddress: "true" });
      this.setState({ deliveryAddressState: "current" });

      this.setState({ firstName: this.state.registeredUserAccount.firstName });
      this.setState({ lastName: this.state.registeredUserAccount.lastName });
      this.setState({ emailId: this.state.registeredUserAccount.emailId });
      this.setState({ mobileNo: this.state.registeredUserAccount.mobileNo });
      this.setState({ address: this.state.registeredUserAccount.address });
      this.setState({ landmark: this.state.registeredUserAccount.landmark });
      this.setState({ country: this.state.registeredUserAccount.country });
      this.setState({ state: this.state.registeredUserAccount.state });
      this.setState({ city: this.state.registeredUserAccount.city });
      this.setState({ pinCode: this.state.registeredUserAccount.pinCode });
      this.context.SetDeliveryPinCode(this.state.registeredUserAccount.pinCode);
    }
  };
  GetUserData = () => {
    console.log(`Bearer ${this.context.userData.bearerToken}`);

    var requestData = "";
    if (this.context.storeSettings.loginMethod == "SMS") {
      requestData =
        "Consumers/GetUserAccountByMobileNo/" +
        this.context.storeId +
        "," +
        this.context.userData.mobileNo;
    } else if (this.context.storeSettings.loginMethod == "EMAIL") {
      requestData =
        "Consumers/GetUserAccount/" +
        this.context.storeId +
        "," +
        this.context.userData.emailId;
    } else return;

    fetch(process.env.REACT_APP_API + requestData, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.context.userData.bearerToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ registeredUserAccount: data });
        this.UpdateUserInformation(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  UpdateUserInformation = (data) => {
    this.setState({ firstName: data.firstName });
    this.setState({ lastName: data.lastName });
    this.setState({ emailId: data.emailId });
    this.setState({ mobileNo: data.mobileNo });
    this.setState({ address: data.address });
    this.setState({ landmark: data.landmark });
    this.setState({ country: data.country });
    this.setState({ state: data.state });
    this.setState({ city: data.city });
    this.setState({ pinCode: data.pinCode });
    this.context.SetDeliveryPinCode(data.pinCode);
  };

  uuidv4 = () => {
    //return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  CallPhonePe = (paymentTransactionId) => {
    var redirecturl = "";
    if (this.context.portalMode == "Kiosk") {
      redirecturl =
        "https://" +
        window.location.hostname +
        //":3000" +
        "/CompleteOrderKioskPhonePe?id=" +
        paymentTransactionId;
    } else {
      redirecturl =
        "https://" +
        window.location.hostname +
        // ":3000" +
        "/CompleteOrderPhonePe?id=" +
        paymentTransactionId;
    }

    var data = {
      merchantId: this.context.storeSettings.merchentId,
      merchantTransactionId: paymentTransactionId,
      merchantUserId: this.context.storeSettings.merchantUserId,
      amount: Math.round(this.context.getTotalAmountWithTax(), 0) * 100,
      redirectUrl: redirecturl,
      redirectMode: "REDIRECT",
      callbackUrl: process.env.REACT_APP_API + "StoreManager/SuccessPhonePe",
      //callbackUrl:
      //"https://localhost:7222/api/" + "StoreManager/SuccessPhonePe",
      mobileNumber: this.state.mobileNo,
      paymentInstrument: { type: "PAY_PAGE" },
    };
    var base64 = window.btoa(
      unescape(encodeURIComponent(JSON.stringify(data)))
    );
    var sign = base64 + "/pg/v1/pay" + this.context.storeSettings.saltKey;
    var hash = sha256.create();
    hash.update(sign);
    // hash.hex();

    var X_VERIFY = hash.hex() + "###" + this.context.storeSettings.saltKeyIndex;
    var payload = { X_VERIFY: X_VERIFY.toString(), base64: base64.toString() };

    var res = axios
      .post(
        process.env.REACT_APP_API +
          "StoreManager/GeneratePhonePeLink/" +
          this.context.storeId,
        payload
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          console.log(data);
          const url =
            response.data?.data?.instrumentResponse?.redirectInfo?.url;
          if (url) {
            this.setState({ phonepeResponseUrl: url });
            //window.open(url, "");
          }
        }
      })
      .catch((error) => {});
  };

  CallRazorPay = () => {
    var ordid = "Order_" + new Date().getTime();
    var custName = "";
    var emailId = "";
    var mobileNo = "";
    if (
      this.context.userData.loginStatus === "inactive" ||
      this.state.deliveryAddressState == "different"
    ) {
      custName = this.state.firstName + " " + this.state.lastName;
      emailId = this.state.emailId;
      mobileNo = this.state.mobileNo;
    } else if (this.context.userData.loginStatus === "active") {
      custName =
        this.state.registeredUserAccount.firstName +
        " " +
        this.state.registeredUserAccount.lastName;
      emailId = this.state.registeredUserAccount.emailId;
      mobileNo = this.state.registeredUserAccount.mobileNo;
    }

    var options = {
      key: this.context.storeSettings.razorpayKey,
      key_secret: this.context.storeSettings.razorpayKeySecret,
      // amount: 1 * 100,
      amount: Math.round(this.context.getTotalAmountWithTax(), 0) * 100,
      currency: this.context.storeSettings.razorpayCurrency,
      name: this.context.storeSettings.razorpayCompanyName,
      description: this.context.storeSettings.razorpayCompanyDescription,
      orderid: ordid,
      handler: (response) => {
        this.SuccessRazorPayResponse(response);
      },
      prefill: {
        name: custName, //customer name
        email: emailId, //customer emailId
        contact: mobileNo, //customer phoneno
      },
      notes: {
        address: this.context.storeSettings.razorpayCompanyAddress,
      },
      theme: {
        color: this.context.storeSettings.razorpayThemeColor,
      },
      modal: {
        escape: false,
        ondismiss: () => {
          //this.setState({ OrderStatus: "Dismissed" });
          this.context.UpdateOrderStatus("Dismissed");
        },
      },
    };
    var pay = window.Razorpay(options);
    //var rzp1 = new Razorpay(options);
    //pay.on("payment.failed", function (response) {
    pay.on("payment.failed", (response) => {
      this.FailedInRazorPayResponse(response);
    });
    pay.open();
  };
  FailedInRazorPayResponse = (response) => {
    //TODO: store the response details in Database

    //this.setState({ OrderStatus: "Failed" });
    this.context.UpdateOrderStatus("Failed");
    // alert(response.error.code);
    // alert(response.error.description);
    // alert(response.error.source);
    // alert(response.error.step);
    // alert(response.error.reason);
    // alert(response.error.metadata.order_id);
    // alert(response.error.metadata.payment_id);
  };
  SuccessRazorPayResponse = (response) => {
    //1. Store Order Details in DB
    this.StoreOrderInDB(response.razorpay_payment_id);
    //2. Clear Local Cart
    console.log(response.razorpay_payment_id);
  };
  PerformCashOnDelivery = () => {
    //TODO: store guest user details in database.
    this.StoreOrderInDB("");
  };

  CallPhonePePay = () => {
    const uuid = this.uuidv4();
    this.StoreOrderInDB(uuid);
  };

  getMainCategoryById = (id) => {
    var pg = this.context.mainCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };

  StoreOrderInDB = (paymentTransactionId) => {
    this.ShowLoadSpinner();

    var userid = "";
    var paymentmodeid = 0; //cashondelivery
    var paymentstatusid = 0;
    var orderstatusid = 0;
    var description = "";
    var orderWorkflowStatus =
      "<p>" +
      this.context.GetInDateFormat(new Date()) +
      "=> " +
      "Received" +
      "</p>";
    var totalAmtPaid = 0;
    var customerOrderedItems = [];
    var customerOrderDeliveryAddresses = {};

    this.context.myCart.map((cartItem) => {
      var OrderedItem = {
        productId: "1",
        productName: "",
        imgFileName: "",
        price: 0,
        qty: "",
        description: "",
      };
      OrderedItem.productId = cartItem.prod.id;
      OrderedItem.productName =
        window.location.hostname == "shop.green.com.pg"
          ? cartItem.prod.subCategoryName + " " + cartItem.prod.name
          : cartItem.prod.name;
      OrderedItem.categoryName = this.getMainCategoryById(
        cartItem.prod.mainCategoryId
      );
      OrderedItem.imgFileName = cartItem.prod.imgFileName;
      OrderedItem.mrp = cartItem.prod.mrp;
      OrderedItem.discount = cartItem.prod.discount;
      OrderedItem.qty = cartItem.qty;
      OrderedItem.description = cartItem.prod.description;
      customerOrderedItems.slice();
      customerOrderedItems.push(OrderedItem);
    });

    if (this.context.userData.loginStatus === "active") {
      userid = this.context.userData.id;

      if (
        this.state.deliveryAddressState == "current" &&
        this.state.registeredUserAccount.customerUserTypeId ==
          this.context.userType.Registered
      ) {
        customerOrderDeliveryAddresses = [
          {
            firstName: this.state.registeredUserAccount.firstName,
            lastName: this.state.registeredUserAccount.lastName,
            mobileNo: this.state.registeredUserAccount.mobileNo,
            emailId: this.state.registeredUserAccount.emailId,
            Address: this.state.registeredUserAccount.address,
            Landmark: this.state.registeredUserAccount.landmark,
            city: this.state.registeredUserAccount.city,
            state: this.state.registeredUserAccount.state,
            country: this.state.registeredUserAccount.country,
            pinCode: this.state.registeredUserAccount.pinCode,
          },
        ];
      } else if (
        this.state.deliveryAddressState == "current" &&
        (this.state.registeredUserAccount.customerUserTypeId ==
          this.context.userType.Guest ||
          this.state.registeredUserAccount.customerUserTypeId == null)
      ) {
        customerOrderDeliveryAddresses = [
          {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mobileNo: this.state.mobileNo,
            emailId: this.state.emailId,
            Address: this.state.address,
            Landmark: this.state.landmark,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            pinCode: this.state.pinCode,
          },
        ];
      } else if (this.state.deliveryAddressState == "different") {
        customerOrderDeliveryAddresses = [
          {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mobileNo: this.state.mobileNo,
            emailId: this.state.emailId,
            Address: this.state.address,
            Landmark: this.state.landmark,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            pinCode: this.state.pinCode,
          },
        ];
      }
    } else {
      alert("SubmitOrder Failed. Please try later! ");
      return;
    }

    orderstatusid = 1; //Recevied
    if (this.state.paymentmode === "RazorPay") {
      paymentmodeid = 2; //RazorPay
      paymentstatusid = 1; //paid
      totalAmtPaid = Math.round(this.context.getTotalAmountWithTax(), 0);
    } else if (this.state.paymentmode === "CashOnDelivery") {
      paymentmodeid = 1; //cashondelivery
      paymentstatusid = 2; //unpaid
    } else if (this.state.paymentmode === "BankReceipt") {
      paymentmodeid = 9; //cashondelivery
      paymentstatusid = 2; //unpaid
    } else if (this.state.paymentmode === "PhonePe") {
      paymentmodeid = 10; //cashondelivery
      paymentstatusid = 2; //unpaid
    }

    var deliveryMethodId = 3; // Home delivery
    if (this.state.storePickup == true) {
      deliveryMethodId = 2; //StorePickup
    }
    var str = {
      storeId: this.context.storeId,
      customerId: userid,
      paymentModeId: paymentmodeid,
      paymentStatusId: paymentstatusid,
      deliveryMethodId: deliveryMethodId,
      paymentTransactionId: paymentTransactionId,
      orderStatus: orderstatusid,
      description: "",
      orderWorkflowStatus: orderWorkflowStatus,
      subTotalAmt: this.context.getSubTotalPriceInCart(this.context.myCart),
      deliveryAmt: this.context.getDeliveryAmount(),
      totalAmt: this.context.getTotalAmountWithTax(),
      totalAmtPaid: totalAmtPaid,

      couponCode: this.context.coupon.couponCode,
      couponValue: this.context.coupon.couponValue,
      totalSavings: this.context.getTotalSavingsFromCart(this.context.myCart),

      taxPercentage:
        this.context.taxDetails.inclusiveOfTax == true
          ? 0
          : this.context.taxDetails.taxPercentage,
      taxAmount:
        this.context.taxDetails.inclusiveOfTax == true
          ? 0
          : this.context.getTaxAmount(),

      customerOrderedItems: customerOrderedItems,
      customerOrderDeliveryAddresses: customerOrderDeliveryAddresses,
    };

    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitOrder", str)
      .then((response) => {
        if (response.status === 200) {
          if (this.state.paymentmode === "PhonePe") {
            this.CallPhonePe(paymentTransactionId);
            if (this.context.portalMode == "Kiosk") {
              this.context.ClearCartAndOrderDetails();
              this.context.clearUserData();
            } else {
              this.context.ClearCartAndOrderDetails();
            }
          } else {
            this.context.storeOrderInformation(response.data);
            this.SendInvoiceEmail(response.data);
            this.context.UpdateOrderStatus("Success");
          }
          this.HideLoadSpinner();
        } else {
          alert("Order Submit Failed. Please try again!");
          this.context.UpdateOrderStatus("Failed");
          this.HideLoadSpinner();
        }
      })
      .catch((error) => {
        console.error("StoreOrderInDB:There was an error!", error);
        this.context.UpdateOrderStatus("Failed");
        this.HideLoadSpinner();
      });
  };
  SendInvoiceEmail = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(this.context.orderData),
      body: JSON.stringify(data),
    };

    var v1 = process.env.REACT_APP_API + "Consumers/SendInvoiceEmail";
    fetch(
      process.env.REACT_APP_API +
        "Consumers/SendInvoiceEmail/" +
        "OnlinePortal",
      requestOptions
    )
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        if (response.status === 200) {
          console.log("email sent");
          return;
        } else {
          throw new Error(response);
        }
      })
      .catch((error) => {
        console.error("SendInvoiceEmail:There was an error!", error);
      });
  };
  CheckProductsAvailability = () => {
    axios
      .post(
        process.env.REACT_APP_API + "Consumers/CheckProductsAvailability",
        this.context.myCart
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ unavailableCartItems: response.data });
          if (response.data.length == 0) {
            this.context.GetDistance();
            this.setState(
              { validatedAndSubmitted: "true" },
              this.ProceedToPay()
            );
          } else {
            this.setState({ showUnavailableItemsModal: true });
          }
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  ProceedCompleteOrder = () => {
    this.CheckProductsAvailability();
  };
  ProceedToPay = () => {
    if (this.state.paymentmode === "RazorPay") {
      this.CallRazorPay();
    } else if (this.state.paymentmode === "PhonePe") {
      this.CallPhonePePay();
    } else if (this.state.paymentmode === "CashOnDelivery") {
      this.PerformCashOnDelivery();
    } else if (this.state.paymentmode === "BankReceipt") {
      if (window.location.hostname == "shop.green.com.pg") {
        this.PerformCashOnDelivery();
      } else {
        this.setState({ showCheckoutUpiManual: true });
      }
    }
  };

  OnUpiManualPaymentSuccess = () => {
    this.setState({ showCheckoutUpiManual: false }, () => {
      this.PerformCashOnDelivery();
    });
  };

  StoreOrderInformationInContext = () => {
    this.context.storeOrderInformation({
      name: this.state.firstName,
      mobileNo: this.state.mobileNo,
      emailId: this.state.emailId,
      address: this.state.address,
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
      pinCode: this.state.pinCode,
      paymentMode: this.state.paymentmode,
    });
  };

  ValidateForm = () => {
    //this.setState(this.state.errmsg = "";
    var errmsg = "";

    //guest user
    if (this.context.userData.loginStatus === "inactive") {
      if (this.state.firstName == "") errmsg += "Name,";
      if (this.state.mobileNo == "") errmsg += "Phone No,";
      if (this.state.emailId == "") errmsg += "Email Address,";
      if (this.state.address == "") errmsg += "Address,";
      if (this.state.city == "") errmsg += "City,";
      if (this.state.pinCode == "") errmsg += "PinCode,";
    }
    //Registered user
    else {
      if (this.state.deliveryAddressState == "current") {
        //no validation required
      } else if (this.state.deliveryAddressState == "different") {
        var errmsg = "";
        if (this.state.firstName == "") errmsg += "Name,";
        if (this.state.mobileNo == "") errmsg += "Phone No,";
        if (this.state.emailId == "") errmsg += "Email Address,";
        if (this.state.address == "") errmsg += "Address,";
        if (this.state.city == "") errmsg += "City,";
        if (this.state.pinCode == "") errmsg += "PinCode,";
      }
    }
    if (this.state.paymentmode === "")
      this.setState({ errormsg: "Select Payment mode" });

    if (errmsg != "")
      this.setState({ formValidationErrorMsg: "Enter valid " + errmsg });

    if (errmsg != "" || this.state.paymentmode === "") {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "Please enter required fileds and try again!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }

    return true;
  };

  CheckDeliveryPincodes = () => {
    if (this.context.shippingDetails.deliveryPincodes) {
      var pincodes = String(
        this.context.shippingDetails.deliveryPincodes.replace(/ /g, "")
      ).split(",");
      if (pincodes.length > 0) {
        var result = false;

        for (var i = 0; i < pincodes.length; i++) {
          var pincodeRange = pincodes[i].split("-");
          if (pincodeRange.length == 2) {
            if (
              pincodeRange[0] <= this.state.pinCode &&
              pincodeRange[1] >= this.state.pinCode
            ) {
              return true;
            }
          } else if (pincodes[i] == this.state.pinCode) {
            return true;
          }
        }
        return false;
      }
    }
    return true;
  };

  ValidateExtra = () => {
    if (this.state.storePickup == false && this.state.homeDelivery == false) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "Please select Delivery Method!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }

    if (this.CheckDeliveryPincodes() == false) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "We are Sorry! we would not be able to deliver to this Pincode. Pleaes contact our support if any queries. ",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }

    if (
      this.state.homeDelivery == true &&
      Math.round(this.context.getTotalAmountWithTax(), 0) <
        Number(this.context.shippingDetails.minimumOrderLimitForDelivery)
    ) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text:
          "Total Order amount should be above " +
          Number(
            this.context.shippingDetails.minimumOrderLimitForDelivery
          ).toLocaleString(this.context.storeSettings.defaultLocale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "currency",
            currency: this.context.storeSettings.defaultCurrency,
          }) +
          " for Delivery!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }

    if (
      this.context.theme.checkTermsAndConditionsOnCheckOut == true &&
      this.state.acceptTermsAndConditions != true
    ) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "Please accept Terms and Conditions and proceed!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }

    if (
      this.context.userData.customerUserTypeId == 1 &&
      this.state.deliveryAddressState == "current"
    )
      return true;
    var phoneValid = true;
    var pinCodeValid = true;

    if (
      this.state.mobileNo.toString().length !==
      this.context.storeSettings.phoneNumberLength
    ) {
      this.setState({
        mobileNo: "",
      });
      phoneValid = false;
    }

    if (
      this.state.pinCode.toString().length !==
      this.context.storeSettings.pincodeLength
    ) {
      this.setState({
        pinCode: "",
      });
      pinCodeValid = false;
    }

    if (phoneValid == true && pinCodeValid == true) {
      return true;
    } else {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "Please enter required fileds and try again!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (this.context.portalMode == "Kiosk") {
      this.setState({ paymentmode: "RazorPay" }, () => {
        this.StoreOrderInformationInContext(); //TODO to be removed
        this.ProceedCompleteOrder();
        this.setState({ validated: true });
        return;
      });
    } else {
      if (form.checkValidity() === false || this.ValidateExtra() === false) {
        event.stopPropagation();
      } else {
        if (this.context.storeSettings.offline == true) {
          this.setState({ showStoreOffline: true });
          return;
        }
        if (this.ValidateForm() != true) return;

        this.StoreOrderInformationInContext(); //TODO to be removed
        this.ProceedCompleteOrder();
      }
      this.setState({ validated: true });
    }
  };

  componentDidMount() {
    // this.setState({ country: this.context.storeSettings.defaultCountry });
    // this.setState({ state: this.context.storeSettings.defaultState });
    window.scrollTo(0, 0);
    if (
      this.context.userData.loginStatus === "active" &&
      this.context.userData.customerUserTypeId == 1
    ) {
      // this.GetUserData();
      this.setState({ registeredUserAccount: this.context.userData });
      this.UpdateUserInformation(this.context.userData);
    } else {
      if (this.context.storeSettings.loginMethod == "EMAIL") {
        this.setState({ emailId: this.context.userData.emailId });
      } else if (this.context.storeSettings.loginMethod == "SMS") {
        this.setState({ mobileNo: this.context.userData.mobileNo });
      }
      this.setState({ country: this.context.storeSettings.defaultCountry });
      this.setState({ state: this.context.storeSettings.defaultState });
    }
  }

  OnHideUnavailableItemsModal = () => {
    this.setState({ showUnavailableItemsModal: false });
  };

  handleTermsAndConditionsChange = (e) => {
    this.setState({ acceptTermsAndConditions: e.target.checked });
  };

  componentDidUpdate() {
    console.log("did update");
  }
  myFunc = () => {
    alert("I'm a function in the parent window");
  };

  render() {
    // if (this.context.userData.loginStatus === "active") {
    //   this.GetUserData();
    // }
    if (this.state.phonepeResponseUrl != "") {
      window.location.replace(this.state.phonepeResponseUrl);
      window.location.href = this.state.phonepeResponseUrl;
      //const y = window.top.outerHeight / 2 + window.top.screenY - 1000 / 2;
      //const x = window.top.outerWidth / 2 + window.top.screenX - 1000 / 2;
      // const x = 0;
      // const y = 0;
      // const width = window.innerWidth;
      // const height = window.innerHeight;
      // const phonePeWindow = window.open(
      //   this.state.phonepeResponseUrl,
      //   "_blank",
      //   `width=${width},height=${height},top=${y}, left=${x}`
      // );

      // if (phonePeWindow) {
      //   phonePeWindow.focus();
      // }
      if (this.context.portalMode == "Kiosk") {
        return <Navigate to="/Kiosk" />;
      } else return <Navigate to="/Home" />;
    }

    if (this.context.OrderStatus !== "") {
      return <Navigate to="/CompleteOrder" />;
    }
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        {this.state.showUnavailableItemsModal == true ? (
          <>
            <UnavailableCartItems
              unavailableCartItems={this.state.unavailableCartItems}
              show={this.state.showUnavailableItemsModal}
              onHide={this.OnHideUnavailableItemsModal}
            ></UnavailableCartItems>
          </>
        ) : (
          <></>
        )}
        {this.state.showCheckoutUpiManual == true ? (
          <>
            <CheckoutUpiManual
              onHide={this.HideCheckoutUpiManual}
              onPaidSuccess={this.OnUpiManualPaymentSuccess}
            ></CheckoutUpiManual>
          </>
        ) : (
          <></>
        )}
        {this.context.portalMode == "Kiosk" ? (
          <>
            <br />
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
            >
              <Row className="rounded">
                <Col className="text-center  py-1">
                  <Button
                    className="CornersRounded"
                    type="submit"
                    disabled={this.context.myCart.length > 0 ? "" : true}
                    style={{
                      backgroundColor:
                        this.context.theme.mainNavBarBackgroundColor,
                      color: this.context.theme.mainNavBarTextColor,
                    }}
                  >
                    <h3>
                      <b>&nbsp;&nbsp;Place Order&nbsp;&nbsp;</b>
                    </h3>
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            <div className="mx-2 p-3 bg-white">
              <h5 className="text-white bg-danger blink_me text-danger">
                {this.state.formValidationErrorMsg}
              </h5>

              {/* Delivery Method */}
              <Row className="mx-0 my-3">
                <h4 className="text-start">
                  <b>Delivery Method</b>
                </h4>
              </Row>
              <Row className="border m-3 p-1 py-3 bg-light-grey delivery-method">
                <Col
                  sm={12}
                  className="text-start"
                  style={{
                    verticalAlign: "middle",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <b>
                    <Stack direction="horizontal">
                      <Form.Check
                        type="radio"
                        name="deliverymethod"
                        id="StorePickup"
                        checked={this.state.storePickup}
                        // label="Store Pickup"
                        label={
                          <>
                            {window.location.hostname == "shop.green.com.pg" ? (
                              <>
                                <h5
                                  className="bold_font"
                                  style={{ color: "#23B14D" }}
                                >
                                  Store Pickup
                                </h5>
                              </>
                            ) : (
                              <>
                                <h5 className="bold_font">Store Pickup</h5>
                              </>
                            )}
                          </>
                        }
                        onChange={(e) => this.handleDeliveryMethodChange(e)}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Form.Check
                        type="radio"
                        name="deliverymethod"
                        id="HomeDelivery"
                        checked={this.state.homeDelivery}
                        // label="Delivery"
                        label={
                          <>
                            {window.location.hostname == "shop.green.com.pg" ? (
                              <>
                                <h5
                                  className="bold_font"
                                  style={{ color: "#23B14D" }}
                                >
                                  Delivery
                                </h5>
                              </>
                            ) : (
                              <>
                                <h5 className="bold_font">Delivery</h5>
                              </>
                            )}
                          </>
                        }
                        onChange={(e) => this.handleDeliveryMethodChange(e)}
                      />
                    </Stack>

                    {this.state.homeDelivery == true &&
                    Math.round(this.context.getTotalAmountWithTax(), 0) <
                      Number(
                        this.context.shippingDetails
                          .minimumOrderLimitForDelivery
                      ) ? (
                      <>
                        <br />
                        <h5 className=" text-left text-dark px-0 blink_me">
                          <u>Note:</u> Total Order amount should be above &nbsp;
                          {Number(
                            this.context.shippingDetails
                              .minimumOrderLimitForDelivery
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                          &nbsp;for Delivery!
                        </h5>
                      </>
                    ) : (
                      <></>
                    )}
                  </b>
                </Col>
              </Row>
              <Form
                noValidate
                className="px-3 billing-address"
                validated={this.state.validated}
                onSubmit={this.handleSubmit}
              >
                {this.state.storePickup == true ||
                this.state.homeDelivery == true ? (
                  <>
                    {this.context.userData.loginStatus === "active" &&
                    this.state.registeredUserAccount.customerUserTypeId == 1 ? (
                      <>
                        <div className="mx-0 px-0 bg-white border">
                          <Row className="py-1 bg-light mx-0">
                            <Col className="px-0">
                              <h6>
                                <b>
                                  <Form.Check
                                    className=""
                                    type="radio"
                                    checked={
                                      this.state.deliveryAddressState ===
                                      "current"
                                        ? "true"
                                        : ""
                                    }
                                    name="deliverygroup"
                                    label={
                                      this.state.homeDelivery == true
                                        ? "Delivery to this Address [ Billing Address ]"
                                        : "Billing Address"
                                    }
                                    id="deliverytocurrentaddress"
                                    onChange={this.handleChangeDeliveryAddress}
                                    style={{ color: "#23B14D" }}
                                  ></Form.Check>
                                </b>
                              </h6>
                            </Col>
                          </Row>
                          <Row className="mx-2 p-3">
                            <div className="col-md-12 p-0 billing-detail">
                              {this.state.registeredUserAccount.firstName}{" "}
                              &nbsp;
                              {this.state.registeredUserAccount.lastName}
                              <br></br>
                              {this.state.registeredUserAccount.address}
                              <br></br>
                              {this.state.registeredUserAccount.city}
                              {" - "}
                              {this.state.registeredUserAccount.pinCode}
                              <br></br>
                              {this.state.registeredUserAccount.state},{" "}
                              {this.state.registeredUserAccount.country}
                              <br></br>
                              Contact No :{" "}
                              {this.state.registeredUserAccount.mobileNo}
                            </div>
                          </Row>
                          <br></br>
                          {this.state.homeDelivery == true ? (
                            <>
                              <Row className="py-1 bg-light mx-0">
                                <Col className="">
                                  <h6 className="" style={{ color: "#23B14D" }}>
                                    <b>
                                      <Form.Check
                                        className=""
                                        type="radio"
                                        name="deliverygroup"
                                        label="Delivery to different Address"
                                        id="deliverytodifferentaddress"
                                        onChange={
                                          this.handleChangeDeliveryAddress
                                        }
                                        style={{ color: "#23B14D" }}
                                      ></Form.Check>
                                    </b>
                                  </h6>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}

                          <Row className="px-0 mx-0 bg-white">
                            {this.state.hideDifferentAddress == "true" ? (
                              <></>
                            ) : (
                              <>
                                {/* Registered by deliver to different address */}
                                {this.state.homeDelivery == true ? (
                                  <>
                                    <div className="col-md-12 border-0">
                                      <Row className="p-3">
                                        <Row>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="First Name *"
                                              className="mb-3  "
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder=""
                                                className=" "
                                                required
                                                value={this.state.firstName}
                                                onChange={
                                                  this.handleChangeFirstName
                                                }
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Please enter a name
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Last Name *"
                                              className="mb-3  "
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder=""
                                                className=""
                                                required
                                                value={this.state.lastName}
                                                onChange={
                                                  this.handleChangeLastName
                                                }
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Please enter a name
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Mobile Number *"
                                              className="mb-3  "
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                className="bg-white"
                                                size=""
                                                required
                                                maxLength={
                                                  this.context.storeSettings
                                                    .phoneNumberLength
                                                }
                                                value={this.state.mobileNo}
                                                onChange={
                                                  this.handleChangePhoneNo
                                                }
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Please enter a valid{" "}
                                                {
                                                  this.context.storeSettings
                                                    .phoneNumberLength
                                                }
                                                &nbsp;digits Phone Number!
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Email ID *"
                                              className="mb-3  "
                                            >
                                              <Form.Control
                                                type="email"
                                                required
                                                placeholder=""
                                                className="bg-white"
                                                size=""
                                                value={this.state.emailId}
                                                onChange={
                                                  this.handleChangeemailId
                                                }
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Please enter a valid Email ID.
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Address and Landmark *"
                                              className="mb-3  "
                                            >
                                              <Form.Control
                                                as="textarea"
                                                required
                                                rows={2}
                                                size=""
                                                placeholder=""
                                                value={this.state.address}
                                                onChange={
                                                  this.handleChangeAddress
                                                }
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Please enter address
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Country *"
                                              className="mb-3  "
                                            >
                                              <Form.Select
                                                value={this.state.country}
                                                size=""
                                                required
                                                // disabled
                                                onChange={
                                                  this.handleChangeCountry
                                                }
                                              >
                                                <option value=""></option>
                                                {Country.getAllCountries().map(
                                                  (country) => (
                                                    <>
                                                      <option
                                                        value={country.isoCode}
                                                      >
                                                        {country.name}
                                                      </option>
                                                    </>
                                                  )
                                                )}
                                              </Form.Select>
                                              <Form.Control.Feedback type="invalid">
                                                Please select country
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label={
                                                window.location.hostname ==
                                                "shop.green.com.pg" ? (
                                                  <>Province *</>
                                                ) : (
                                                  <>State *</>
                                                )
                                              }
                                              className="mb-3  "
                                            >
                                              <Form.Select
                                                value={this.state.state}
                                                size=""
                                                required
                                                disabled={
                                                  this.context.storeSettings
                                                    .disableStateSelection
                                                }
                                                onChange={
                                                  this.handleChangeState
                                                }
                                              >
                                                <option></option>
                                                {State.getStatesOfCountry(
                                                  this.state.country
                                                ).map((state) => (
                                                  <>
                                                    <option>
                                                      {state.name}
                                                    </option>
                                                  </>
                                                ))}
                                              </Form.Select>
                                              <Form.Control.Feedback type="invalid">
                                                Please select State
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="City *"
                                              className="mb-3  "
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder=""
                                                size=""
                                                required
                                                className="bg-white"
                                                value={this.state.city}
                                                onChange={this.handleChangeCity}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Please Enter City
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                          <Col xs={6}>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Postal Code *"
                                              className="mb-3  "
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                className="bg-white"
                                                size=""
                                                required
                                                minLength={
                                                  this.context.storeSettings
                                                    .pincodeLength
                                                }
                                                maxLength={
                                                  this.context.storeSettings
                                                    .pincodeLength
                                                }
                                                value={this.state.pinCode}
                                                onChange={
                                                  this.handleChangePincode
                                                }
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Please enter valid{" "}
                                                {
                                                  this.context.storeSettings
                                                    .pincodeLength
                                                }{" "}
                                                digit Pincode!
                                              </Form.Control.Feedback>
                                            </FloatingLabel>
                                          </Col>
                                        </Row>
                                      </Row>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </Row>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* IF Guest User  */}
                        <Row>
                          <Col className="" xs={6}>
                            <h5 className="mx-3">
                              {this.state.storePickup == true ? (
                                <>
                                  <b>Billing Address</b>
                                </>
                              ) : (
                                <>
                                  <b>Delivery Address</b>
                                </>
                              )}
                            </h5>
                          </Col>
                          <Col className="" xs={6}>
                            <table className="w-100">
                              <tr>
                                <td
                                  xs={12}
                                  className="align-end text-end border-0 "
                                >
                                  <Nav.Link
                                    as={Link}
                                    className="text-end"
                                    to="/UserProfile"
                                    // onClick={(e) => {
                                    //   this.context.storePreviousPage("/Checkout");
                                    // }}
                                    style={{ color: "#23B14D" }}
                                  >
                                    <span className="blink_me">
                                      Click here to{" "}
                                      <u>Complete your profile! </u>
                                    </span>
                                  </Nav.Link>
                                </td>
                              </tr>
                            </table>
                          </Col>
                        </Row>
                        <div className="mx-3 px-0 bg-white">
                          <Row className="p-0">
                            <Row>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="First Name *"
                                  className="mb-3  "
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    className=" "
                                    required
                                    value={this.state.firstName}
                                    onChange={this.handleChangeFirstName}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter a first name
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Last Name *"
                                  className="mb-3  "
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    className=""
                                    required
                                    value={this.state.lastName}
                                    onChange={this.handleChangeLastName}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter a last name
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Mobile Number *"
                                  className="mb-3  "
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    className="bg-white"
                                    size=""
                                    required
                                    value={this.state.mobileNo}
                                    maxLength={
                                      this.context.storeSettings
                                        .phoneNumberLength
                                    }
                                    onChange={this.handleChangePhoneNo}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter a valid{" "}
                                    {
                                      this.context.storeSettings
                                        .phoneNumberLength
                                    }{" "}
                                    digit mobile number
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Email ID *"
                                  className="mb-3  "
                                >
                                  <Form.Control
                                    type="email"
                                    required
                                    placeholder=""
                                    className="bg-white"
                                    size=""
                                    value={this.state.emailId}
                                    onChange={this.handleChangeemailId}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter a valid email id.
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Address and Landmark *"
                                  className="mb-3 textAreaDsn"
                                >
                                  <Form.Control
                                    as="textarea"
                                    required
                                    rows={2}
                                    size=""
                                    placeholder=""
                                    value={this.state.address}
                                    onChange={this.handleChangeAddress}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter address
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Country *"
                                  className="mb-3  "
                                >
                                  <Form.Select
                                    value={this.state.country}
                                    size=""
                                    required
                                    // disabled
                                    onChange={this.handleChangeCountry}
                                  >
                                    <option value=""></option>
                                    {Country.getAllCountries().map(
                                      (country) => (
                                        <>
                                          <option value={country.isoCode}>
                                            {country.name}
                                          </option>
                                        </>
                                      )
                                    )}
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Please select country
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label={
                                    window.location.hostname ==
                                    "shop.green.com.pg" ? (
                                      <>Province *</>
                                    ) : (
                                      <>State *</>
                                    )
                                  }
                                  className="mb-3  "
                                >
                                  <Form.Select
                                    value={this.state.state}
                                    size=""
                                    required
                                    disabled={
                                      this.context.storeSettings
                                        .disableStateSelection
                                    }
                                    onChange={this.handleChangeState}
                                  >
                                    {/*<option></option> */}
                                    {State.getStatesOfCountry(
                                      this.state.country
                                    ).map((state) => (
                                      <>
                                        <option>{state.name}</option>
                                      </>
                                    ))}
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Please select state
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="City *"
                                  className="mb-3  "
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    size=""
                                    required
                                    className="bg-white"
                                    value={this.state.city}
                                    onChange={this.handleChangeCity}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter city
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col xs={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Postal Code *"
                                  className="mb-3  "
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    className="bg-white"
                                    size=""
                                    required
                                    minLength={
                                      this.context.storeSettings.pincodeLength
                                    }
                                    maxLength={
                                      this.context.storeSettings.pincodeLength
                                    }
                                    value={this.state.pinCode}
                                    onChange={this.handleChangePincode}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter valid{" "}
                                    {this.context.storeSettings.pincodeLength}{" "}
                                    digit pincode
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </Row>
                        </div>
                      </>
                    )}

                    <Row className="text-center">
                      <h5 className="blink_me text-black">
                        {this.state.ResultStatusText}
                      </h5>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {/* PAYMENT Start */}
                {/* <div className="mx-3 px-0   paymentcontainercustom rounded-pill"> */}
                <div className="">
                  <br></br>
                  <Row className="my-0">
                    <h4 className="text-start">
                      <b>Payment</b>
                    </h4>
                  </Row>
                  <div className="mx-3 py-3 bg-white bank-statement-section">
                    {/* Select Payment Mode : */}
                    <Row className="my-0">
                      <Col xs={12} className="text-start border p-0 ">
                        <div className="">
                          <label
                            className="blink_me  "
                            hidden={this.state.errormsg === ""}
                            style={{
                              color: "#23B14D",
                            }}
                          >
                            <h5>{this.state.errormsg}</h5>
                          </label>
                        </div>
                        {/* bank receipt */}
                        {this.context.storeSettings.disableBankReceipt ==
                        true ? (
                          <></>
                        ) : (
                          <>
                            <div className="w-100 bg-light p-3 form-check-control">
                              <div className="payment-mode mb-3">
                                Select Payment mode
                              </div>
                              <Form.Check
                                type="radio"
                                id="BankReceipt"
                                name="group1"
                                onChange={this.handleChangePaymentMode}
                                label={
                                  <>
                                    {window.location.hostname ==
                                    "shop.green.com.pg" ? (
                                      <>
                                        <h5 className="">Bank Receipt</h5>
                                      </>
                                    ) : (
                                      <>
                                        <h4 className="">
                                          UPI{" "}
                                          <span
                                            className="text-secondary"
                                            style={{ fontSize: "14px" }}
                                          >
                                            [pay with Paytm/PhonePe/GPay]
                                          </span>{" "}
                                        </h4>
                                      </>
                                    )}
                                  </>
                                }
                              />
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.context.storeSettings
                                    .bankReceiptDescriptionHtml,
                              }}
                              className={
                                this.context.storeSettings
                                  .bankReceiptDescriptionHtml
                                  ? "bankreceiptheight"
                                  : ""
                              }
                            />
                          </>
                        )}

                        {this.context.storeSettings.disablePhonePePaymentMode ==
                        true ? (
                          <></>
                        ) : (
                          <>
                            <div className="w-100 bg-light form-check-control">
                              <Form.Check
                                type="radio"
                                id="PhonePe"
                                name="group1"
                                className="py-2 "
                                onChange={this.handleChangePaymentMode}
                                label={
                                  <>
                                    <h5 className="">Pay Now</h5>
                                    <span className="text-success">
                                      [via PhonePe Secure Gateway]
                                    </span>
                                  </>
                                }
                              ></Form.Check>
                            </div>
                          </>
                        )}

                        {this.context.storeSettings
                          .disableRazorPayPaymentMode == true ? (
                          ""
                        ) : (
                          <>
                            <div className="w-100 bg-light form-check-control">
                              <Form.Check
                                type="radio"
                                id="RazorPay"
                                name="group1"
                                className="py-2 "
                                onChange={this.handleChangePaymentMode}
                                label={
                                  <>
                                    <h5 className="">Pay Now</h5>
                                    <span className="text-success">
                                      [via Razorpay Secure Gateway]
                                    </span>
                                  </>
                                }
                              ></Form.Check>
                            </div>
                          </>
                        )}
                        {this.context.storeSettings.disableCod == true ? (
                          ""
                        ) : (
                          <>
                            <div className="w-100 bg-light form-check-control">
                              <Form.Check
                                type="radio"
                                id="CashOnDelivery"
                                name="group1"
                                onChange={this.handleChangePaymentMode}
                                label={
                                  <>
                                    <h5 className="">Cash On Delivery(COD)</h5>
                                    {/* <span className="text-danger">- [Cash/UPI]</span> */}
                                  </>
                                }
                              />
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* TermsAndConditons */}
                {this.context.theme.checkTermsAndConditionsOnCheckOut ==
                true ? (
                  <>
                    <Row className="border-0 my-3 mx-3">
                      {/* <Col sm={2} className=" text-start bg-light border ">
                            Terms And Conditions:
                          </Col> */}
                      <Col
                        sm={12}
                        className="text-start"
                        style={{
                          verticalAlign: "middle",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <b>
                          <Stack direction="horizontal">
                            <Form.Check
                              checked={this.state.acceptTermsAndConditions}
                              label="by selecting, I agree to the "
                              onChange={(e) =>
                                this.handleTermsAndConditionsChange(e)
                              }
                            />
                            <Nav.Link
                              as={Link}
                              to="/TermsAndConditions"
                              className=""
                              target="_blank"
                            >
                              <i class="bi bi-arrow-right  me-2"></i>
                              <u>Terms & Conditions</u>
                            </Nav.Link>
                          </Stack>
                        </b>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {/* PLACE ORDER */}
                <Row className="rounded">
                  <Col className="text-center  py-1">
                    {window.location.hostname == "shop.green.com.pg" ? (
                      <>
                        <Row className="m-0 p-0">
                          <Col xs={12} md={8} className="m-0 p-0 text-start">
                            <div className="mx-3">
                              <span className="delivery-note">
                                Delivery Note: &nbsp;
                              </span>
                              <span
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.context.shippingDetails
                                      .deliveryDuration,
                                }}
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="border-0 m-0 p-0 text-end mt-3 mt-md-0"
                            verticalAlign="top"
                            style={{ verticalAlign: "top" }}
                          >
                            <Button
                              className="btn-green-2 d-text-white text-center text-md-end mx-3"
                              type="submit"
                              style={{ verticalAlign: "top" }}
                              disabled={
                                this.context.myCart.length > 0 ? "" : true
                              }
                            >
                              <b>&nbsp;&nbsp;Place Order&nbsp;&nbsp;</b>
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Button
                          className=" CornersRounded"
                          type="submit"
                          disabled={this.context.myCart.length > 0 ? "" : true}
                          style={{
                            fontFamily: "sans-serif",
                            backgroundColor:
                              this.context.theme.mainNavBarBackgroundColor,
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        >
                          <h2>
                            <b>&nbsp;&nbsp;Place Order&nbsp;&nbsp;</b>
                          </h2>
                        </Button>
                        <div>
                          <span className="blink_me delivery-note">
                            <b>
                              <u>Delivery Note:</u> &nbsp;
                            </b>
                          </span>
                          <span
                            className=""
                            dangerouslySetInnerHTML={{
                              __html:
                                this.context.shippingDetails.deliveryDuration,
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        )}
      </>
    );
  }
}

class UnavailableCartItems extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (this.props.unavailableCartItems.length > 0) {
      this.context.deleteItemsOnMyCart(this.props.unavailableCartItems);

      Swal.fire({
        title: "",
        text: "These Items are removed from your Cart! Please review your Cart & Order Summary and continue to 'Place Order'",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.onHide();
          window.scrollTo(0, 0);
        }
      });
    }
  };
  render() {
    return (
      <>
        <div className="container ">
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // dialogClassName="xxlmodal"
          >
            <Form onSubmit={this.handleSubmit} className="">
              <Modal.Header
                // closeButton
                style={{
                  backgroundColor: this.context.theme.mainNavBarBackgroundColor,
                  color: this.context.theme.mainNavBarTextColor,
                  borderColor: "white",
                }}
              >
                <Modal.Title id="contained-modal-title-vcenter">
                  Item(s) Out-Of-Stock at present!
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="show-grid productmodal-body"
                style={{
                  backgroundColor:
                    this.context.theme.productCardBackgroundColor,
                  color: this.context.theme.productCardTextColor,
                }}
              >
                Below items are{" "}
                <i>
                  <u>unavailable (or) out-of-stock</u>
                </i>{" "}
                at present. We have noted and we will notify/contact once they
                are available. We delete below Products from your Cart!
                <br />
                <br />
                <Table className="border" responsive="md">
                  <tr className="bg-light text-center text-secondary">
                    <th></th>
                    <th>Product in Cart</th>
                    <th>Qty</th>
                    <th>Status</th>
                  </tr>
                  {this.props.unavailableCartItems.map((prod, i) => (
                    <>
                      <tr className="text-center border">
                        <td>{i + 1}</td>
                        <td>
                          <b>{prod.prod.name}</b>
                        </td>
                        <td>{prod.qty}</td>
                        <td>
                          <span className="blink_me">Unavailable</span>
                        </td>
                      </tr>
                    </>
                  ))}
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  // onClick={(e) =>
                  //   this.context.deleteItemOnMyCart(this.props.prod.id)
                  // }
                >
                  Ok
                </Button>
                {/* <Button variant="danger" onClick={this.props.onHide}>
                  Close
                </Button> */}
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}
