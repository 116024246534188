import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { BlobServiceClient } from "@azure/storage-blob";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

import { Table, Form, Button } from "react-bootstrap";

export default class UserOrdersMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      userOrders: [],
      loadSpinnerOpen: false,
      currentOrderRow: "",
    };
    this.inputFile = React.createRef();
  }

  GetUserOrders = () => {
    console.log(`Bearer ${this.context.userData.bearerToken}`);
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetUserOrders/" +
        this.context.storeId +
        "," +
        this.context.userData.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.context.userData.bearerToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) return;
        this.setState({ userOrders: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  componentDidMount() {
    this.GetUserOrders();
  }

  GetOrderStatus = (orderStatusId) => {
    var list = this.context.orderStatusTypes.filter(
      (e) => e.id == orderStatusId
    );
    if (list.length > 0) return list[0].statusName;
    return "";
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  handleFileUpload = async (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    for (let i = 0; i < files.length; i++) {
      await this.uploadFileToBlob(files[i]);
    }
    console.log("All uploaded ");
    this.HideLoadSpinner();
  };

  uploadFileToBlob = async (file) => {
    if (!file) return [];
    const blobService = new BlobServiceClient(
      this.context.store.storageBlobUrl + this.context.store.storageSasToken
    );
    const containerClient = blobService.getContainerClient(
      this.context.store.storageBlobContainerName
    );

    console.log("uploading:" + file.name);
    //upload file.
    await this.createBlobInContainer(containerClient, file);

    // get list of blobs in container
    //return getBlobsInContainer(containerClient);
  };

  createBlobInContainer = async (containerClient, file) => {
    // create blobClient for container
    var targetFileName =
      this.state.currentOrderRow.orderId + "." + file.name.split(".").pop();
    const blobClient = containerClient.getBlockBlobClient(
      "bankreceipt/" + targetFileName
    );

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    await blobClient.uploadData(file, options);

    this.setState({ bankReceiptFileName: targetFileName });
    this.state.currentOrderRow.bankReceiptFileName = targetFileName;
    this.setState({ currentOrderRow: this.state.currentOrderRow }, () => {});

    console.log("uploaded:" + file.name);
  };

  onUploadButtonClick = (currentOrderRow) => {
    this.setState({ currentOrderRow: currentOrderRow }, () => {
      this.inputFile.current.click();
    });
  };

  DeleteBankReceipt = (currentOrderRow) => {
    currentOrderRow.bankReceiptFileName = "";
    this.setState({ currentOrderRow: currentOrderRow });
  };

  UpdateOrder = (order) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    };
    fetch(process.env.REACT_APP_API + "Consumers/UpdateOrder", requestOptions)
      .then((res) => {
        if (res.status != 200) {
          alert("Not updated");
          return;
        }

        Swal.fire({
          title: " Status",
          text: "Updated successfully!",
          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("Failed" + error);
      });
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "bankReceiptRefNumber":
        this.state.userOrders[i].bankReceiptRefNumber = value;
        break;
      case "bankReceiptDetails":
        this.state.userOrders[i].bankReceiptDetails = value;
        break;
    }
    this.setState({ userOrders: this.state.userOrders });
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        <>
          <input
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg"
            ref={this.inputFile}
            onChange={this.handleFileUpload}
            type="file"
            multiple="multiple"
          />
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}

          <Helmet>
            <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
            <link href="dist/css/style.css" rel="stylesheet" />
            <link href="dist/css/responsive.css" rel="stylesheet" />
            <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
            <script src="dist/js/custom.js"></script>
          </Helmet>

          <div
            className="Container-fluid mx-0 px-0 "
            style={{ paddingTop: "65px" }}
          >
            <div className="row mt-0 mx-0 px-0">
              <div className="col-md-12  ">
                <>
                  <br></br>
                  <h1 className="producttitlefontandsizeDesktop">
                    <b>My Orders</b>
                  </h1>
                  <br></br>
                  <small>
                    <Table
                      border
                      className="px-5 cartDsn table-striped "
                      responsive="md"
                    >
                      <thead>
                        <tr className="bg-light text-start text-secondary">
                          {window.location.hostname == "shop.green.com.pg" ? (
                            <>
                              <th>Order Details</th>
                              <th>Bank Receipt Details</th>
                            </>
                          ) : (
                            <>
                              <th>order Details</th>
                              <th width="20%">Invoice</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.userOrders
                          .sort((a, b) => (a.orderId < b.orderId ? 1 : -1))
                          .map((p, i) => (
                            <>
                              <tr className="text-start text-secondary ">
                                {window.location.hostname ==
                                "shop.green.com.pg" ? (
                                  <>
                                    <td>
                                      <b>Order placed:</b>
                                      {this.context.GetInDateFormat(
                                        new Date(p.orderCreated)
                                      )}
                                      <br />
                                      <br />
                                      <b>OrderID:</b> {p.orderId}
                                      <br />
                                      <br />
                                      <b>Total Amount: </b>
                                      {Math.round(
                                        Number(p.totalAmt),
                                        0
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                      <br />
                                      <br />
                                      <b>Order Status:</b>
                                      <p class="status received">
                                        {this.GetOrderStatus(p.orderStatus)}
                                      </p>
                                      <br />
                                      {p.deliveryBy == 2 ? (
                                        <>
                                          <b>Delivery via:</b>
                                          <br />
                                          {p.deliveryCarrierName}
                                          <br />
                                          <b>TrackingId:</b>
                                          <br />
                                          {p.deliveryTrackingId}
                                          <br />
                                          <b>Details:</b>
                                          <br />
                                          <span
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {p.deliveryDetails}
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <br />
                                      Invoice:{" "}
                                      <a
                                        href={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/invoice/" +
                                          p.orderId +
                                          ".html" +
                                          this.context.store.storageSasToken +
                                          "&xyz=" +
                                          new Date().toLocaleString()
                                        }
                                        // target="_blank"
                                        target=""
                                        className="download"
                                      >
                                        <img
                                          src="dist/images/html.png"
                                          height="30px"
                                        />
                                        download
                                      </a>
                                    </td>
                                    <td className="text-start">
                                      Reference Number(*) :
                                      <Form.Control
                                        type="text"
                                        name="bankReceiptRefNumber"
                                        value={p.bankReceiptRefNumber}
                                        required
                                        placeholder=""
                                        className=" text-center"
                                        autoComplete="off"
                                        onChange={(e) =>
                                          this.handleInputChange(e, i)
                                        }
                                      />
                                      Bank & Other Details(if any) :
                                      <Form.Control
                                        as="textarea"
                                        type="text"
                                        rows={3}
                                        name="bankReceiptDetails"
                                        value={p.bankReceiptDetails}
                                        placeholder=""
                                        className=" text-center"
                                        autoComplete="off"
                                        onChange={(e) =>
                                          this.handleInputChange(e, i)
                                        }
                                      />
                                      {p.bankReceiptFileName == "" ||
                                      p.bankReceiptFileName == undefined ? (
                                        <>
                                          Attachment (if any) :&nbsp;
                                          <Link
                                            className="text-green text-decoration-none"
                                            onClick={(e) =>
                                              this.onUploadButtonClick(p)
                                            }
                                          >
                                            click to attach{" "}
                                          </Link>
                                        </>
                                      ) : (
                                        <>
                                          <b>{p.bankReceiptFileName}</b>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <a
                                            href={
                                              this.context.store
                                                .storageBlobUrl +
                                              this.context.store
                                                .storageBlobContainerName +
                                              "/bankreceipt/" +
                                              p.bankReceiptFileName +
                                              this.context.store.storageSasToken
                                            }
                                            target="_blank"
                                            className="text-green text-decoration-none"
                                          >
                                            View
                                          </a>
                                          &nbsp;&nbsp;&nbsp;
                                          <Button
                                            onClick={(e) => {
                                              Swal.fire({
                                                title: "Warning!",
                                                confirmButtonColor: "#23B14D",
                                                cancelButtonColor: "#dbdbdb",
                                                text: "Do you want to delete?",
                                                showCancelButton: "true",
                                                confirmButtonText: "Yes",
                                                cancelButtonText: "No",
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  this.DeleteBankReceipt(p);
                                                }
                                              });
                                              // if (
                                              //   window.confirm(
                                              //     "Are you sure you want to Delete ?"
                                              //   )
                                              // ) {
                                              //   this.DeleteBankReceipt(p);
                                              // }
                                            }}
                                            className="bg-green"
                                          >
                                            X
                                          </Button>
                                        </>
                                      )}
                                      <br></br>
                                      <div className="my-2 text-center">
                                        <Button
                                          onClick={(e) => this.UpdateOrder(p)}
                                          className="btn-green d-inline-block"
                                        >
                                          <h5>
                                            &nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;
                                          </h5>
                                        </Button>
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <b className="py-2">OrderId: </b>
                                      {p.orderId}
                                      <br />
                                      <b className="py-2">Order Received: </b>
                                      {new Date(p.orderCreated).getDate() +
                                        "/" +
                                        new Date(p.orderCreated).toLocaleString(
                                          "en-US",
                                          {
                                            month: "short",
                                          }
                                        ) +
                                        "/" +
                                        new Date(p.orderCreated).getFullYear()}
                                      <br />
                                      <b className="py-2"> Total Amount: </b>
                                      {Math.round(Number(p.totalAmt), 0)}
                                      <br />
                                      <b className="py-2">Payment Status: </b>[
                                      {p.paymentStatusId == 1
                                        ? "Paid"
                                        : "Unpaid/Pending"}
                                      ]
                                      <br />
                                      <b className="py-2">Order Status: </b>
                                      <p class="status received">
                                        {this.GetOrderStatus(p.orderStatus)}
                                      </p>
                                      {p.deliveryBy == 2 ? (
                                        <>
                                          <br />
                                          <b className="py-2">Delivery via: </b>
                                          {p.deliveryCarrierName}
                                          <br />
                                          <b className="py-2">ReferenceId: </b>
                                          {p.deliveryTrackingId}
                                          <br />
                                          <b className="py-2">Details: </b>
                                          <br />
                                          <span
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {p.deliveryDetails}
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td>
                                      <a
                                        href={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/invoice/" +
                                          p.orderId +
                                          ".html" +
                                          this.context.store.storageSasToken +
                                          "&xyz=" +
                                          new Date().toLocaleString()
                                        }
                                        // target="_blank"
                                        target=""
                                        className="download1"
                                      >
                                        <img
                                          src="dist/images/html.png"
                                          height="30px"
                                        />
                                        download
                                      </a>
                                    </td>
                                  </>
                                )}
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </small>
                </>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </>
      </>
    );
  }
}
