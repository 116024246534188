import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import ProductCard from "./ProductCard.js";
import ReactDOM from "react-dom";
import Select from "react-select";
import Fuse from "fuse.js";
import { LoadSpinner } from "./LoadSpinner";
import { BsFillHeartFill, BsFillHouseDoorFill, BsSearch } from "react-icons/bs";

import { Link } from "react-router-dom";
import {
  Stack,
  Offcanvas,
  Badge,
  Nav,
  Navbar,
  Button,
  FormControl,
  Form,
} from "react-bootstrap";
import { render } from "@testing-library/react";

const WAIT_INTERVAL = 1000;
export default class SearchView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      weightdefault: "",
      show: false,
      searchData: [],
      searchquery: "",
      searchbuttoncolor: "white",
      loadSpinnerOpen: false,
      noProductFound: "",
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleMenuOffCanvasClose = () => {
    this.setState({ show: false });
    this.setState({ searchData: [] });
  };
  handleMenuOffCanvasShow = (e) => {
    this.setState({ show: true });
  };

  searchItem = (query) => {
    this.setState({ noProductFound: "" });
    this.setState({ searchquery: query }, (r) => {
      if (this.state.searchquery.length > 2) {
        clearTimeout(this.timer);
        this.ShowLoadSpinner();
        console.log(this.state.searchquery);
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
      }
    });
    if (!query) {
      this.setState({ searchData: [] });
      return;
    }
  };

  triggerChange = () => {
    console.log("trigger=" + this.state.searchquery);
    if (false == this.ShowSearchResults()) {
      console.log("result=" + this.state.searchquery);

      // const myArray = this.state.searchquery.split(" ");
      // if (myArray.length > 0) {
      //   this.state.searchquery = this.state.searchquery.substr(
      //     this.state.searchquery.indexOf(" ") + 1
      //   );
      //   this.ShowSearchResults();
      // }
    }
  };
  ShowSearchResults = () => {
    const fuse = new Fuse(this.context.prodsAll, {
      keys: ["name", "sku", "subCategoryName"],

      //option-1
      // threshold: 0.2,
      // ignoreLocation: true,
      // findAllMatches: true,
      // distance: 120,
      // threshold: 0.1,
      threshold: 0.6,
      ignoreLocation: true,
      findAllMatches: false,
      distance: 1,

      //option-2
      // findAllMatches: true,
      // threshold: 0.3, //number of words
      // location: 0,
      // distance: 120,
    });
    const result = fuse.search(this.state.searchquery);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ searchData: finalResult.slice(0, 25) }, () => {
        this.HideLoadSpinner();
      });
      return true;
    } else {
      this.setState({ noProductFound: "No Product Found!" });
      this.setState({ searchData: [] }, () => {
        this.HideLoadSpinner();
      });
      return false;
    }
  };

  hasSearchResults = () => {
    if (this.state.searchData.length > 0) return true;

    return false;
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <span
          className={"align-content-end "}
          style={{
            cursor: "pointer",
            color: this.context.theme.mainNavBarTextColor,
          }}
          onClick={(e) => {
            this.handleMenuOffCanvasShow(e);
          }}
        >
          <BsSearch
            style={{
              color: this.context.theme.mainNavBarTextColor,
            }}
            size={"18px"}
          ></BsSearch>
        </span>

        <Offcanvas
          show={this.state.show}
          onHide={this.handleMenuOffCanvasClose}
          placement="top"
          className="h-auto"
        >
          <Offcanvas.Header
            closeButton
            className=""
            style={{
              backgroundColor: this.context.theme.mainNavBarBackgroundColor,
              color: this.context.theme.mainNavBarTextColor,
            }}
          >
            <Offcanvas.Title>
              <h5>Search Products</h5>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            className="search-wrap"
            style={{
              backgroundColor: this.context.theme.homepageBackgroundColor,
              color: this.context.theme.homepageTitlesTextColor,
            }}
          >
            <Stack direction="horizontal" gap={3} className="">
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </b>
              <FormControl
                type="search"
                placeholder="Search your products here"
                className="me-0 text-dark fw-bold searchboxconoffcanvas bg-light text-black"
                size="lg"
                aria-label="Search"
                onChange={(e) => this.searchItem(e.target.value)}
              />
            </Stack>
            <br></br>
            {this.state.loadSpinnerOpen === true ? (
              <h1 className="text-center w-100">Loading...pls wait</h1>
            ) : (
              ""
            )}

            {this.hasSearchResults() ? (
              <h2>Search results of "{this.state.searchquery}" </h2>
            ) : (
              <h2>{this.state.noProductFound} </h2>
            )}
            <br></br>
            <div className="col-md-12 ">
              <div className="row justify-content-around py-2">
                {this.state.searchData.map((prod) => (
                  <div
                    className="card mb-5 m-2 "
                    style={{
                      width: this.context.theme.productCardWidth + "cm",
                      backgroundColor:
                        this.context.theme.productCardBackgroundColor,
                      borderColor: this.context.theme.productCardBorderColor,
                    }}
                  >
                    <div className="">
                      <ProductCard
                        prod={prod}
                        hideSearchModal={this.handleMenuOffCanvasClose}
                        parent="SearchView"
                      ></ProductCard>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
