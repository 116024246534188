import React, { Component, useState } from "react";
import Slider from "react-slick";

import {
  Accordion,
  Row,
  Col,
  Table,
  NavLink,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ProductsList } from "./ProductsList";
import ProductCard from "./ProductCard";
import CartContext from "./CartContext";
import { LoadSpinner } from "./LoadSpinner";

import { SampleNextArrow } from "./HomeAccessories";
import { SamplePrevArrow } from "./HomeAccessories";
import { HomeCountersDisplay } from "./HomeAccessories";
import { Testimonial } from "./HomeAccessories";
import { CertificatesSlider } from "./HomeAccessories";
import { ClientsSlider } from "./HomeAccessories";
import { Vlogs } from "./HomeAccessories";

export class Blogs extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      blogs: [],
    };
  }
  GetBlogs = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetBlogs/" + this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            blogs: data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)),
          });
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };
  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetBlogs();
    }
  };
  OnClickBlog = (e, htmlfilename) => {
    this.context.SetBlogLink(htmlfilename);
  };
  render() {
    var settings = "";
    if (window.innerWidth > 1200) {
      settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,

        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent1",
      };
    } else {
      settings = {
        infinite: false,
        slidesToShow: 1,
        speed: 100,

        arrows: true,
        autoplay: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent",
      };
    }

    return (
      <>
        {window.innerWidth > 1200 ? (
          <div
            className="text-center text-dark py-1 "
            style={
              {
                // backgroundColor: this.context.theme.menuNavBarbackgroundColor,
                // color: this.context.theme.mainNavBarTextColor,
              }
            }
          >
            <br />
            <br />
            <div
              className=" title-dsn"
              style={{
                fontSize: this.context.theme.homepageTitlesFontSize + "px",
                color: this.context.theme.homepageTitlesTextColor,
                textTransform: this.context.theme.homepageTitlesTextCase,
              }}
            >
              <h1 class="bold_font">Blogs</h1>

              <Slider {...settings}>
                {this.state.blogs
                  .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                  .map((c) => (
                    <>
                      <div
                        className="p-3 m-3  "
                        style={{
                          backgroundColor:
                            this.context.theme.homepageBackgroundColor,
                          color: this.context.theme.categoryTextColor,
                          height: "6cm",
                          border: "1px solid ",
                          borderColor:
                            this.context.theme.mainNavBarBackgroundColor,
                        }}
                      >
                        <table className="  w-100   justify-content-around ">
                          <tbody>
                            <tr>
                              <td width="40%" className="text-start ">
                                <div className="">
                                  {c.imgFileName ? (
                                    <>
                                      <img
                                        src={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/images/" +
                                          c.imgFileName +
                                          this.context.store.storageSasToken
                                        }
                                        className=" border-0 border-dark CornersRounded w-100"
                                        alt="..."
                                        style={{
                                          objectFit: "contain",
                                          height: "5cm",
                                        }}
                                      ></img>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                              <td
                                width="60%"
                                className="text-start "
                                style={{ fontSize: "14px" }}
                              >
                                <div className="mx-3">{c.shortDescription}</div>
                                <br />
                                <br />
                                <div className="text-end">
                                  <Nav.Link
                                    as={Link}
                                    // style={{ color: "green" }}
                                    to="/Blog"
                                    onClick={(e) =>
                                      this.OnClickBlog(e, c.htmlFileName)
                                    }
                                    // to={{
                                    //   pathname: "/Blog",
                                    //   aboutProps: {
                                    //     title: "hi",
                                    //   },
                                    // }}
                                    // exact
                                  >
                                    Read more...
                                  </Nav.Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ))}
              </Slider>
            </div>
          </div>
        ) : (
          <>
            <div
              className="text-center p-3 my-5 text-dark "
              style={{
                // fontSize: this.context.theme.homepageTitlesFontSize + "px",
                // color: this.context.theme.homepageTitlesTextColor,
                // textTransform: this.context.theme.homepageTitlesTextCase,
                color: "black",
                backgroundColor: this.context.theme.homepageBackgroundColor,
              }}
            >
              <div className="p-1 title-dsn">
                <h1 class="bold_font">Blogs</h1>
              </div>
              <Slider {...settings}>
                {this.state.blogs
                  .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                  .map((c) => (
                    <>
                      <div
                        className="p-3 m-3  "
                        style={{
                          backgroundColor:
                            this.context.theme.homepageBackgroundColor,
                          color: this.context.theme.categoryTextColor,
                          border: "1px solid ",
                          borderColor:
                            this.context.theme.mainNavBarBackgroundColor,
                        }}
                      >
                        <table className="  w-100  justify-content-around">
                          <tbody>
                            <tr>
                              <td className="text-start ">
                                <div className="">
                                  {c.imgFileName ? (
                                    <>
                                      <img
                                        src={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/images/" +
                                          c.imgFileName +
                                          this.context.store.storageSasToken
                                        }
                                        className=" border-0 "
                                        alt="..."
                                        style={{
                                          objectFit: "contain",
                                          height: "2cm",
                                        }}
                                      ></img>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="mx-3">{c.shortDescription}</div>
                                <br />
                                <div className="text-end">
                                  <Nav.Link
                                    as={Link}
                                    to="/Blog"
                                    onClick={(e) =>
                                      this.OnClickBlog(e, c.htmlFileName)
                                    }
                                  >
                                    Read More...
                                  </Nav.Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ))}
              </Slider>
            </div>
          </>
        )}
      </>
    );
  }
}
