import React, { useState, useContext, Component, createRef } from "react";
import pincode from "pincode-distance/lib/pincode";
import { Country, State, City } from "country-state-city";
import CartContext from "./CartContext";
import "../CSS/3d.css";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { sha256 } from "js-sha256";
import { OfflineStore } from "./OfflineStore";
import IncDecCounter from "./IncDecCounterControl";

import {
  Modal,
  Nav,
  Form,
  FloatingLabel,
  Table,
  Container,
  Row,
  Col,
  Button,
  Stack,
} from "react-bootstrap";
import { render } from "@testing-library/react";
import { getCurrentDateTime } from "../../helper";
import { useEffect } from "react";
import { CheckoutUpiManual } from "./CheckoutUpiManual";
import CheckoutAndPayForm from "./CheckoutAndPayForm";

export default class CheckoutView extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      storePickup: false,
      homeDelivery: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate = () => {
    if (this.context.refreshIncDecControl == true) {
      this.context.SetRefreshIncDecControl(false);
      this.setState({ reload: true }, () => {
        this.setState({ reload: false });
      });
    }
  };
  refresh = () => {};
  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (
      this.context.userData.loginStatus == "inactive" &&
      this.context.portalMode != "Kiosk"
    ) {
      this.context.ShowUserLoginNew(true);
    }
    return (
      <>
        <div
          className="Container-fluid mx-0 px-0 checkout-container"
          style={
            {
              // backgroundColor: this.context.theme.homepageBackgroundColor,
            }
          }
        >
          <Helmet>
            <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
            <link href="dist/css/grnstyle.css" rel="stylesheet" />
            {/* <link href="dist/css/grnresponsive.css" rel="stylesheet" /> */}
            <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
            {/* <script src="dist/js/grncustom.js"></script> */}
          </Helmet>

          <div className="row mt-1 mx-0 px-0 inner-row">
            {this.context.portalMode == "Kiosk" ? (
              <>
                <div className="text-center">
                  <h2 className=" ectittle-black-outline-kiosk">Checkout</h2>
                </div>

                {/* <div class="text-center">
                  <h1 style={{ fontSize: "64px" }}> Checkout</h1>
                </div> */}
                <div className="col-md-6 bg-white my-0">
                  <br></br>
                  <OrderSummary
                    myCart={this.context.myCart}
                    StoreCouponDetails={this.StoreCouponDetails}
                    homeDelivery={this.state.homeDelivery}
                  ></OrderSummary>
                  <CheckoutAndPayForm></CheckoutAndPayForm>
                </div>
                <div className="col-md-6 border-light border ">
                  <h4 className="text-dark text-center ">
                    <b>Items in Cart</b>
                  </h4>
                  <OrderedItems myCart={this.context.myCart}></OrderedItems>
                  <br></br>
                  <br></br>
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={12} md={7} className="checkout-lef-col">
                    <div class="">
                      <h2 className=" ectittle-black-outline-5">Checkout</h2>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="px-1">
                      <CheckoutAndPayForm></CheckoutAndPayForm>
                    </div>{" "}
                  </Col>
                  {this.state.reload == false ? (
                    <>
                      <Col className="px-0" xs={12} md={5}>
                        <div className="  checkout-right">
                          {window.location.hostname != "shop.green.com.pg" ? (
                            <>
                              <OrderSummary
                                myCart={this.context.myCart}
                                StoreCouponDetails={this.StoreCouponDetails}
                                homeDelivery={this.state.homeDelivery}
                              ></OrderSummary>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="">
                            <h4 className="text-dark text-left ">
                              <b>
                                Items in Cart{" "}
                                <span className="item-count">
                                  {this.context.getTotalQtyInCart(
                                    this.context.myCart
                                  )}
                                </span>
                              </b>
                            </h4>
                            <OrderedItems
                              myCart={this.context.myCart}
                            ></OrderedItems>

                            {window.location.hostname == "shop.green.com.pg" ? (
                              <>
                                <OrderSummary
                                  myCart={this.context.myCart}
                                  StoreCouponDetails={this.StoreCouponDetails}
                                  homeDelivery={this.state.homeDelivery}
                                ></OrderSummary>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export class OrderedItems extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.props.myCart.filter(
      (item) => item.prod.mainCategoryId === maincategory.id
    ).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };
  render() {
    return (
      <>
        <div className="mx-0 pt-3 border-0 ">
          {this.context.mainCategories.map((maincategory) => (
            <>
              {this.isMainCategoryHasItems(maincategory) == true ? (
                <div className="mx-0 px-0">
                  <div className="row px-0 ">
                    <h5 className="text-dark ">
                      <b>
                        <strong>{maincategory.name}</strong>
                      </b>
                    </h5>
                    <div className="px-3 ">
                      <Table
                        border
                        className="cartviewtableOnCheckout px-5"
                        responsive="md"
                      >
                        <thead>
                          <tr className="text-center">
                            <th width="10%"></th>
                            <th width="30%"></th>
                            <th width="10%">Price</th>
                            <th width="10%">Disc</th>
                            <th width="15%" className="text-center">
                              Qty
                            </th>
                            <th width="35%" className="text-center">
                              Sub Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.myCart
                            .filter(
                              (item) =>
                                item.prod.mainCategoryId === maincategory.id
                            )
                            .map((cartitem) => (
                              <>
                                <tr className="text-left align-top">
                                  <td className="col-md-1">
                                    {this.context.IsVideo(
                                      cartitem.prod.imgFileName
                                    ) == true ? (
                                      <>
                                        <img
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            cartitem.prod.posterName +
                                            this.context.store.storageSasToken
                                          }
                                          className=""
                                          alt="..."
                                          style={{ width: "40px" }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            cartitem.prod.imgFileName +
                                            this.context.store.storageSasToken
                                          }
                                          className=""
                                          alt="..."
                                          style={{ width: "40px" }}
                                        />
                                      </>
                                    )}
                                  </td>
                                  <td className="col-md-2 ">
                                    {cartitem.prod.name}
                                  </td>
                                  <td>
                                    {cartitem.prod.mrp.toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </td>
                                  <td>{cartitem.prod.discount}%</td>
                                  <td className="col-md-3 ">
                                    <div className="cartTable">
                                      <IncDecCounter
                                        intvalue={cartitem.qty}
                                        valueChangeCallback={(e) => {
                                          if (e === "") {
                                          } else if (e === 0) {
                                            this.context.deleteItemOnMyCart(
                                              cartitem.prod
                                            );
                                            this.context.forceUpdateCartView();
                                            // this.context.SetRefreshIncDecControl(
                                            //   true
                                            // );
                                          } else {
                                            cartitem.qty = e;
                                            this.context.SetStateOfMyCart(
                                              this.context.myCart
                                            );
                                            this.context.forceUpdateCartView();
                                            // this.context.SetRefreshIncDecControl(
                                            //   true
                                            // );
                                          }
                                        }}
                                      ></IncDecCounter>
                                    </div>
                                  </td>

                                  <td className="col-md-2 text-center">
                                    <b>
                                      {Math.round(
                                        Number(
                                          cartitem.qty *
                                            (cartitem.prod.mrp -
                                              (cartitem.prod.mrp *
                                                cartitem.prod.discount) /
                                                100)
                                        ),
                                        0
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </b>
                                    <br></br>
                                    <span style={{ fontSize: "12px" }}>
                                      Savings: &nbsp;
                                      {Math.round(
                                        Number(
                                          cartitem.qty *
                                            ((cartitem.prod.mrp *
                                              cartitem.prod.discount) /
                                              100)
                                        ),
                                        0
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  }
}
export class OrderSummary extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      couponValue: 0,
      couponData: "",
    };
  }

  componentDidMount() {}

  ValidateCoupon = (data) => {
    if (data.mainCategoryId) {
      var result = this.context.myCart.filter(
        (f) => f.prod.mainCategoryId == data.mainCategoryId
      );
      if (result.length == this.context.myCart.length) return true;
      else return false;
    } else return true;
  };

  StoreCouponDetails = (data) => {
    if (this.ValidateCoupon(data) == true) {
      this.setState({ couponData: data }, () => {
        if (this.state.couponData.couponValueType == 1) {
          if (
            this.state.couponData.amount >
            this.context.getSubTotalPriceInCart(this.context.myCart)
          ) {
            Swal.fire({
              title: "Information!",
              confirmButtonColor: "#23B14D",
              text:
                "Coupon Value is " +
                this.state.couponData.amount +
                " which is greater than items in Cart. Add more items to Cart to use this Coupon!",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
            this.setState({ couponCode: "" });
            return;
          }
          this.setState(
            { couponValue: Math.round(this.state.couponData.amount, 0) },
            () => {
              this.context.StoreCouponDetails(
                this.state.couponCode,
                this.state.couponValue
              );
            }
          );
        } else if (this.state.couponData.couponValueType == 2) {
          if (
            this.state.couponData.discount >
            this.context.getSubTotalPriceInCart(this.context.myCart)
          ) {
            Swal.fire({
              title: "Information!",
              confirmButtonColor: "#23B14D",
              text:
                "Coupon Value is " +
                this.state.couponData.discount +
                " which is greater than items in Cart. Add more items to Cart to use this Coupon!",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
            this.setState({ couponCode: "" });
            return;
          }
          this.setState(
            {
              couponValue: Math.round(
                (this.context.getSubTotalPriceInCart(this.context.myCart) *
                  this.state.couponData.discount) /
                  100,
                0
              ),
            },
            () => {
              this.context.StoreCouponDetails(
                this.state.couponCode,
                this.state.couponValue
              );
            }
          );
        }
      });
    } else {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "We are Sorry! This coupon can't be applied",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      this.setState({ couponCode: "" });
      return;
    }
  };
  OnClickCouponApply = () => {
    if (this.state.couponCode == "" || this.state.couponCode == undefined)
      return;
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetCoupon/" +
        this.context.storeId +
        "/" +
        this.state.couponCode
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          Swal.fire({
            title: "Information!",
            confirmButtonColor: "#23B14D",
            text: "This is an Invalid Coupon Code!",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
          this.setState({ couponCode: "" });
          return;
        }
        this.StoreCouponDetails(data);
      })
      .catch((error) => {
        console.error("OnClickCouponApply error!", error);
      });
  };

  onCouponCodeChange = (e) => {
    this.setState({ couponCode: e.target.value }, () => {
      this.setState({ couponValue: 0 });
      // this.props.StoreCouponDetails(this.state.couponCode, 0, "");
      this.context.StoreCouponDetails(e.target.value, 0);
    });
  };

  render() {
    return (
      <>
        {" "}
        <Helmet>
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>
        <Row className="my-2 mx-0 order-summary">
          <h4 className=" text-left text-dark px-0">
            <b>Order Summary</b>
          </h4>
        </Row>
        <div className="text-dark order-details">
          <Container className="p-2">
            <Row className="py-1 ">
              <Col className=" text-start">
                Sub Total [{this.context.getTotalQtyInCart(this.props.myCart)}{" "}
                Items] :
              </Col>
              <Col xs={3} className="text-end">
                {this.context
                  .getSubTotalPriceInCart(this.context.myCart)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
              </Col>
            </Row>
            <Row className="py-1">
              <Col className=" text-start">Delivery/Shipping charges :</Col>
              <Col className="text-end">
                +{" "}
                {Number(this.context.getDeliveryAmount()).toLocaleString(
                  this.context.storeSettings.defaultLocale,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  }
                )}
              </Col>
            </Row>

            {this.context.taxDetails.inclusiveOfTax == true ? (
              ""
            ) : (
              <>
                <Row className="py-1">
                  <Col className=" text-start">
                    Tax&nbsp;({this.context.taxDetails.taxPercentage}
                    %) :
                  </Col>
                  <Col className="text-end">
                    +{" "}
                    {this.context
                      .getTaxAmount()
                      .toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                  </Col>
                </Row>
              </>
            )}
            <hr></hr>
            <Row className="py-1 my-2">
              <Col className=" text-start total-text">
                <h4 className=""> Total :</h4>
              </Col>
              <Col className="text-end">
                <h4>
                  <b>
                    {this.context
                      .getTotalAmountWithTax()
                      .toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                  </b>
                  <br />
                  <small style={{ fontSize: "11px" }}>
                    {this.context.taxDetails.inclusiveOfTax == true ? (
                      <>(Inclusive of Tax)</>
                    ) : (
                      <></>
                    )}
                  </small>
                </h4>
              </Col>
            </Row>

            <Row className="py-0 ">
              <Col className=" text-start">Total Savings :</Col>
              <Col className="text-end">
                {this.context
                  .getTotalSavingsFromCart(this.context.myCart)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
              </Col>
            </Row>

            {this.context.storeSettings.hideCouponCodeField == true ? (
              ""
            ) : (
              <>
                <Row className="my-1 ">
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Col className="coupon-code-text">
                        Coupon Code (if any) :
                        <Stack direction="horizontal">
                          <Form.Control
                            type="text"
                            placeholder=""
                            className="bg-white  mx-3"
                            value={this.state.couponCode}
                            size="lg"
                            onChange={(e) => this.onCouponCodeChange(e)}
                          />
                          <Button
                            className="btn-green-3 text-white "
                            size=""
                            onClick={(e) => this.OnClickCouponApply(e)}
                          >
                            Apply
                          </Button>
                        </Stack>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className="">
                        Coupon Code (if any):
                        <Stack direction="horizontal">
                          <Form.Control
                            type="text"
                            placeholder=""
                            className="bg-white  mx-3"
                            value={this.state.couponCode}
                            size="lg"
                            onChange={(e) => this.onCouponCodeChange(e)}
                          />
                          <Button
                            size="lg"
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                            onClick={(e) => this.OnClickCouponApply(e)}
                          >
                            Apply
                          </Button>
                        </Stack>
                      </Col>
                    </>
                  )}
                </Row>
                {this.state.couponValue > 0 ? (
                  <>
                    <Row className="py-1">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          <b>Coupon Code value :</b>
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Form.Label size="" className="">
                          <h5>
                            {Math.round(
                              this.state.couponValue,
                              0
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </h5>
                        </Form.Label>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            <Row>
              <div className="text-center note-text">
                {this.context.shippingDetails.freeDelivery == true &&
                this.context.deliveryMethod == "HomeDelivery" ? (
                  <>Free Delivery</>
                ) : this.context.shippingDetails.deliveryCharges == true &&
                  this.context.deliveryMethod == "HomeDelivery" ? (
                  // &&  this.context.getSubTotalPriceInCart(this.context.myCart) >
                  //   this.context.shippingDetails.freeDeliveryAbove
                  <>
                    Free Shipping/Delivery above{" "}
                    {this.context.shippingDetails.freeDeliveryAbove.toLocaleString(
                      this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Row>
          </Container>
        </div>
        <br />
        <br />
      </>
    );
  }
}
