import React, { useState, useContext, Component, createRef } from "react";
import { Country, State, City } from "country-state-city";
import CartContext from "./CartContext";
import "../CSS/3d.css";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { LoadSpinner } from "./LoadSpinner";
import { Link } from "react-router-dom";
import { MessageBox } from "./MessageBox";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { sha256 } from "js-sha256";
import { CheckoutUpiManual } from "./CheckoutUpiManual";
import { OfflineStore } from "./OfflineStore";
import IncDecCounter from "./IncDecCounterControl";
import CheckoutAndPayFormMobile from "./CheckoutAndPayFormMobile";

import {
  Modal,
  FloatingLabel,
  Stack,
  Nav,
  Form,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { render } from "@testing-library/react";
import { getCurrentDateTime } from "../../helper";

export default class CheckoutViewMobile extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // if (this.context.userData.loginStatus === "inactive") {
    //   this.context.storePreviousPage("/Checkout");
    //   this.context.ShowUserLoginNew(true);
    // }
  }

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.context.userData.loginStatus == "inactive") {
      this.context.ShowUserLoginNew(true);
    }
    return (
      <>
        <Helmet>
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>
        <div
          className="Container-fluid mx-0 px-0 checkout-container checkout-container-mob"
          style={{
            paddingTop: "65px",
          }}
        >
          <div className="text-center bg-light py-2 text-dark">
            <h2 className="ectittle-black-outline-mobile ">Checkout</h2>
          </div>
          <br />
          <br />
          <br />
          <div className="row inner-row-mob">
            <div className="col-md-12 checkoutform bg-white checkout-lef-col mb-5 pb-5">
              <CheckoutAndPayFormMobile></CheckoutAndPayFormMobile>
            </div>
            <div className="col-md-12 py-1 pt-4 pb-4 px-0">
              <OrderSummaryMobile
                myCart={this.context.myCart}
                StoreCouponDetails={this.StoreCouponDetails}
              ></OrderSummaryMobile>
            </div>
          </div>
          <div className=" px-4 py-1">
            <h3 className="checkout-mob-title mb-5">
              <b>Items in Cart</b>
            </h3>
            <OrderedItemsMobile
              myCart={this.context.myCart}
            ></OrderedItemsMobile>
          </div>
        </div>
      </>
    );
  }
}
export class OrderedItemsMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.props.myCart.filter(
      (item) => item.prod.mainCategoryId === maincategory.id
    ).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };
  render() {
    return (
      <>
        <div className="mx-0 p-0 mt-3 checkout-right">
          {this.context.mainCategories.map((maincategory) => (
            <>
              {this.isMainCategoryHasItems(maincategory) == true ? (
                <div className="mx-0 px-0">
                  <div className="row mx-0 px-0 ">
                    <h5 className="text-dark px-0">
                      <b>
                        <strong>{maincategory.name}</strong>
                      </b>
                    </h5>
                    <div className="px-0 ">
                      <Table
                        border
                        className="cartviewtableOnCheckout px-5"
                        responsive="md"
                      >
                        <thead>
                          <tr className="text-center">
                            <th width="40%"></th>
                            <th width="10%">Price</th>
                            <th width="10%">Disc</th>
                            <th width="40%" className="text-center">
                              Qty & Sub Tot
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.myCart
                            .filter(
                              (item) =>
                                item.prod.mainCategoryId === maincategory.id
                            )
                            .map((cartitem) => (
                              <>
                                <tr className="text-center align-top">
                                  <td className="text-start">
                                    <strong>{cartitem.prod.name}</strong>
                                  </td>
                                  <td>
                                    {cartitem.prod.mrp.toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </td>
                                  <td>{cartitem.prod.discount}%</td>
                                  <td>
                                    <IncDecCounter
                                      intvalue={cartitem.qty}
                                      valueChangeCallback={(e) => {
                                        if (e === "") {
                                        } else if (e === 0) {
                                          this.context.deleteItemOnMyCart(
                                            cartitem.prod
                                          );
                                          this.context.forceUpdateCartView();
                                        } else {
                                          cartitem.qty = e;
                                          this.context.SetStateOfMyCart(
                                            this.context.myCart
                                          );
                                          this.context.forceUpdateCartView();
                                        }
                                      }}
                                    ></IncDecCounter>
                                    <b>
                                      {
                                        this.context.storeSettings
                                          .defaultCurrency
                                      }
                                      &nbsp;
                                      {Math.round(
                                        Number(
                                          cartitem.qty *
                                            (cartitem.prod.mrp -
                                              (cartitem.prod.mrp *
                                                cartitem.prod.discount) /
                                                100)
                                        ),
                                        0
                                      )}
                                    </b>
                                    <br></br>
                                    Savings:&nbsp;
                                    {Math.round(
                                      Number(
                                        cartitem.qty *
                                          ((cartitem.prod.mrp *
                                            cartitem.prod.discount) /
                                            100)
                                      ),
                                      0
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  }
}
export class OrderSummaryMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      couponValue: 0,
      couponData: "",
    };
  }

  componentDidMount() {}

  ValidateCoupon = (data) => {
    if (data.mainCategoryId) {
      var result = this.context.myCart.filter(
        (f) => f.prod.mainCategoryId == data.mainCategoryId
      );
      if (result.length == this.context.myCart.length) return true;
      else return false;
    } else return true;
  };

  StoreCouponDetails = (data) => {
    if (this.ValidateCoupon(data) == true) {
      this.setState({ couponData: data }, () => {
        if (this.state.couponData.couponValueType == 1) {
          if (
            this.state.couponData.amount >
            this.context.getSubTotalPriceInCart(this.context.myCart)
          ) {
            Swal.fire({
              title: "Information!",
              confirmButtonColor: "#23B14D",
              text:
                "Coupon Value is " +
                this.state.couponData.amount +
                " which is greater than items in Cart. Add more items to Cart to use this Coupon!",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
            this.setState({ couponCode: "" });
            return;
          }
          this.setState(
            { couponValue: Math.round(this.state.couponData.amount, 0) },
            () => {
              this.context.StoreCouponDetails(
                this.state.couponCode,
                this.state.couponValue
              );
              // this.props.StoreCouponDetails(
              //   this.state.couponCode,
              //   this.state.couponValue,
              //   this.state.couponData
              // );
            }
          );
        } else if (this.state.couponData.couponValueType == 2) {
          if (
            this.state.couponData.discount >
            this.context.getSubTotalPriceInCart(this.context.myCart)
          ) {
            Swal.fire({
              title: "Information!",
              confirmButtonColor: "#23B14D",
              text:
                "Coupon Value is " +
                this.state.couponData.discount +
                " which is greater than items in Cart. Add more items to Cart to use this Coupon!",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
            this.setState({ couponCode: "" });
            return;
          }
          this.setState(
            {
              couponValue: Math.round(
                (this.context.getSubTotalPriceInCart(this.context.myCart) *
                  this.state.couponData.discount) /
                  100,
                0
              ),
            },
            () => {
              this.context.StoreCouponDetails(
                this.state.couponCode,
                this.state.couponValue
              );
              // this.props.StoreCouponDetails(
              //   this.state.couponCode,
              //   this.state.couponValue,
              //   this.state.couponData
              // );
            }
          );
        }
      });
    } else {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "We are Sorry! This coupon can be applied.",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      this.setState({ couponCode: "" });
      return;
    }
  };

  OnClickCouponApply = () => {
    if (this.state.couponCode == "" || this.state.couponCode == undefined)
      return;

    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetCoupon/" +
        this.context.storeId +
        "/" +
        this.state.couponCode
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          Swal.fire({
            title: "Information!",
            confirmButtonColor: "#23B14D",
            text: "This is an Invalid Coupon Code!",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
          this.setState({ couponCode: "" });
          return;
        }
        this.StoreCouponDetails(data);
      })
      .catch((error) => {
        console.error("OnClickCouponApply error!", error);
      });
  };

  onCouponCodeChange = (e) => {
    this.setState({ couponCode: e.target.value }, () => {
      this.setState({ couponValue: 0 });
      // this.props.StoreCouponDetails(this.state.couponCode, 0, "");
      this.context.StoreCouponDetails(e.target.value, 0);
    });
  };

  render() {
    return (
      <>
        {/* Order Summary */}

        <Row className="py-3 mx-0 px-0 order-summary">
          <h4 className=" checkout-mob-title px-0">
            <strong>Order Summary</strong>
          </h4>
        </Row>
        <div className="mx-1 px-0 my-1 ">
          <div className="p-2 px-0 text-dark order-details">
            <Row className="py-1 ">
              <Col className=" text-start">
                Subtotal [{this.context.getTotalQtyInCart(this.props.myCart)}{" "}
                Items]:
              </Col>
              <Col xs={3} className="text-end">
                {this.context
                  .getSubTotalPriceInCart(this.context.myCart)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
              </Col>
            </Row>
            <Row className="py-1">
              <Col className=" text-start">Delivery/Shipping charges :</Col>
              <Col className="text-end">
                +{" "}
                {this.context
                  .getDeliveryAmount()
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
              </Col>
            </Row>

            {this.context.taxDetails.inclusiveOfTax == true ? (
              ""
            ) : (
              <>
                <Row className="py-1">
                  <Col className=" text-start">
                    Tax&nbsp; ({this.context.taxDetails.taxPercentage}
                    %) :
                  </Col>
                  <Col className="text-end">
                    +{" "}
                    {this.context
                      .getTaxAmount()
                      .toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                  </Col>
                </Row>
              </>
            )}
            <hr></hr>
            <Row className="py-1 my-2">
              <Col className=" text-start total-text">
                <h3 className=""> Total :</h3>
              </Col>
              <Col className="text-end">
                <h3>
                  <b>
                    {this.context
                      .getTotalAmountWithTax()
                      .toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                  </b>
                  <br />
                  <small style={{ fontSize: "11px" }}>
                    {this.context.taxDetails.inclusiveOfTax == true ? (
                      <>(Inclusive of Tax)</>
                    ) : (
                      <></>
                    )}
                  </small>
                </h3>
              </Col>
            </Row>

            <Row className="py-0 ">
              <Col className=" text-start">Total Savings:</Col>
              <Col className="text-end">
                {this.context
                  .getTotalSavingsFromCart(this.context.myCart)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
              </Col>
            </Row>

            {this.context.storeSettings.hideCouponCodeField == true ? (
              ""
            ) : (
              <>
                <Row className="my-1 ">
                  {/* <Col className="coupon-code-text">
                    Coupon Code (if any):
                    <Stack direction="horizontal">
                      <Form.Control
                        type="text"
                        placeholder=""
                        className="bg-white text-center  mx-3"
                        value={this.state.couponCode}
                        size="lg"
                        onChange={(e) => this.onCouponCodeChange(e)}
                      />
                      <Button
                        className="btn-green-3 text-white "
                        size=""
                        onClick={(e) => this.OnClickCouponApply(e)}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </Col> */}
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Col className="coupon-code-text">
                        Coupon Code (if any):
                        <Stack direction="horizontal">
                          <Form.Control
                            type="text"
                            placeholder=""
                            className="bg-white  mx-3"
                            value={this.state.couponCode}
                            size="lg"
                            onChange={(e) => this.onCouponCodeChange(e)}
                          />
                          <Button
                            className="btn-green-3 text-white "
                            size=""
                            onClick={(e) => this.OnClickCouponApply(e)}
                          >
                            Apply
                          </Button>
                        </Stack>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className="">
                        Coupon Code (if any):
                        <Stack direction="horizontal">
                          <Form.Control
                            type="text"
                            placeholder=""
                            className="bg-white  mx-3"
                            value={this.state.couponCode}
                            size="lg"
                            onChange={(e) => this.onCouponCodeChange(e)}
                          />
                          <Button
                            size="lg"
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                            onClick={(e) => this.OnClickCouponApply(e)}
                          >
                            Apply
                          </Button>
                        </Stack>
                      </Col>
                    </>
                  )}
                </Row>
                {this.state.couponValue > 0 ? (
                  <>
                    <Row className="py-1">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          <b>Coupon Code value :</b>
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Form.Label size="" className="">
                          <h5>
                            {Math.round(
                              this.state.couponValue,
                              0
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </h5>
                        </Form.Label>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </>
            )}

            {this.context.portalMode == "Qrcode" ? (
              <></>
            ) : (
              <>
                <Row>
                  <div className="text-center muted note-text">
                    {this.context.shippingDetails.freeDelivery == true &&
                    this.context.deliveryMethod == "HomeDelivery" ? (
                      <>Free Delivery</>
                    ) : this.context.shippingDetails.deliveryCharges == true &&
                      this.context.deliveryMethod == "HomeDelivery" ? (
                      // &&
                      // this.context.getSubTotalPriceInCart(this.context.myCart) >
                      //   this.context.shippingDetails.freeDeliveryAbove
                      <>
                        Free Shipping/Delivery above{" "}
                        {this.context.shippingDetails.freeDeliveryAbove.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Row>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
