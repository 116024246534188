import { Component, useContext, useState } from "react";
import { Button, Table, Tooltip, OverlayTrigger, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import CartContext from "./CartContext";
import IncDecCounterMobile from "./IncDecCounterControlMobile";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { OfflineStore } from "./OfflineStore";

export default class CartViewMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { forUpdateValue: 1, reload: false };
  }

  //static forceUpdate = useForceUpdate();
  //create your forceUpdate hook
  forceUpdate = () => {
    this.setState({ forUpdateValue: 0 }, () => {
      this.setState({ forUpdateValue: 1 });
    });
  };

  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.context.myCart.filter(
      (item) => item.prod.mainCategoryId === maincategory.id
    ).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };

  ValidateProductInstockAvailablity = () => {
    var cart = this.context.myCart;
    cart.map((p, i) => {
      //if multivariant
      if (p.prod.name.includes("- [")) {
        //TODO.. check instock on the backend. delete if out of stock
      } else {
        var prd = this.context.prodsAll.filter((f) => f.id == p.prod.id);
        if (prd.length == 1 && prd[0].inStockQty >= Number(p.qty)) {
          //ok
          p.prod.discount = prd[0].discount;
          p.prod.mrp = prd[0].mrp;
          p.prod.name = prd[0].name;

          console.log("prod in-stock" + prd[0].name);
        } else {
          //delete prod from cart
          this.context.deleteItemOnMyCart(p.prod);
        }
      }
    });
    this.forceUpdate();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.ValidateProductInstockAvailablity();
    this.context.UpdateOrderStatus("");
  }
  componentDidUpdate() {
    if (
      this.context.reloadProductListView === "true" &&
      this.state.prod != "" &&
      this.context.prodsAll.length > 0
    ) {
      this.ValidateProductInstockAvailablity();
      this.context.UpdateReloadProductListView("false");
    }
  }

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.forUpdateValue == 0) return "";
    return (
      <>
        <Helmet>
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>
        {this.state.reload == false ? (
          <>
            <div
              style={{
                paddingTop: "65px",
                fontFamily: "sans-serif",
              }}
              className="bg-white"
            >
              <br></br>
              <CartViewHeadingMobile></CartViewHeadingMobile>
              {/* <div className="w-100 bg-black border"></div> */}
              <br></br>
              <CartTotalMobile myCart={this.context.myCart} />
              {this.context.mainCategories.map((maincategory) => (
                <>
                  {this.isMainCategoryHasItems(maincategory) == true ? (
                    <div className="mx-0 px-0">
                      <div className="row mx-0 px-0 ">
                        <h5>&nbsp;</h5>
                        <h4>{maincategory.name}</h4>
                        <div className="px-1 cartTable">
                          <Table
                            border
                            className="cartviewtable px-5"
                            responsive="md"
                          >
                            <thead>
                              <tr className="bg-light text-center text-secondary">
                                <th className="" width="60%"></th>
                                <th className="text-start" width="35%"></th>
                                <th width="5%"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.context.myCart
                                .filter(
                                  (item) =>
                                    item.prod.mainCategoryId === maincategory.id
                                )
                                .map((cartitem, i) => (
                                  <>
                                    <tr className="text-center align-middle text-black">
                                      <td>
                                        <Link
                                          to="/ProductSingleProductViewWrapper"
                                          className="text-dark"
                                          style={{ textDecoration: "none" }}
                                          onClick={(e) => {
                                            this.context.StoreShopnowProduct(
                                              cartitem.prod
                                            );
                                          }}
                                        >
                                          {this.context.IsVideo(
                                            cartitem.prod.imgFileName
                                          ) == true ? (
                                            <>
                                              <img
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  cartitem.prod.posterName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                className="CornersRoundedSmall"
                                                alt="..."
                                                style={{ width: "60px" }}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  cartitem.prod.imgFileName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                className="CornersRoundedSmall"
                                                alt="..."
                                                // style={{ width: "1.5cm" }}
                                              />
                                            </>
                                          )}

                                          <small>
                                            <br></br>
                                            {cartitem.prod.name}
                                            <br />
                                            [Price:
                                            {cartitem.prod.mrp.toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                            ]
                                          </small>
                                          <br></br>
                                        </Link>
                                      </td>
                                      <td className="text-center text-black ">
                                        <h6>
                                          <div className="text-start p-0">
                                            <IncDecCounterMobile
                                              intvalue={cartitem.qty}
                                              valueChangeCallback={(e) => {
                                                if (e === "") {
                                                } else if (e === 0) {
                                                  this.context.deleteItemOnMyCart(
                                                    cartitem.prod
                                                  );
                                                  this.context.forceUpdateCartView();
                                                  // this.forceUpdate(); //to rerender this function component
                                                } else {
                                                  cartitem.qty = e;
                                                  this.context.SetStateOfMyCart(
                                                    this.context.myCart
                                                  );
                                                  this.context.forceUpdateCartView();
                                                  // this.forceUpdate(); //to rerender this function component
                                                }
                                              }}
                                            >
                                              {" "}
                                            </IncDecCounterMobile>
                                          </div>
                                          SubTotal: &nbsp;&nbsp;
                                          {Math.round(
                                            Number(
                                              cartitem.qty *
                                                (cartitem.prod.mrp -
                                                  (cartitem.prod.mrp *
                                                    cartitem.prod.discount) /
                                                    100)
                                            ),
                                            0
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                          <br></br>
                                          &nbsp;
                                          <small>
                                            [Savings: &nbsp;
                                            {Math.round(
                                              Number(
                                                cartitem.qty *
                                                  ((cartitem.prod.mrp *
                                                    cartitem.prod.discount) /
                                                    100)
                                              ),
                                              0
                                            ).toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                            ]
                                          </small>
                                        </h6>
                                      </td>
                                      <td className="">
                                        <OverlayTrigger
                                          overlay={<Tooltip>Delete!</Tooltip>}
                                        >
                                          <Button
                                            variant="outline-danger"
                                            onClick={(e) => {
                                              Swal.fire({
                                                title: "Warning!",
                                                confirmButtonColor: "#23B14D",
                                                cancelButtonColor: "#dbdbdb",
                                                text: "Do you want to delete?",
                                                showCancelButton: "true",
                                                confirmButtonText: "Yes",
                                                cancelButtonText: "No",
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  this.context.deleteItemOnMyCart(
                                                    cartitem.prod
                                                  );
                                                  this.forceUpdate();
                                                  this.context.forceUpdateCartView();
                                                }
                                              });
                                            }}

                                            // if (
                                            //   window.confirm(
                                            //     "Do you want to Delete?"
                                            //   )
                                            // ) {
                                            //   this.context.deleteItemOnMyCart(
                                            //     cartitem.prod
                                            //   );
                                            //   this.forceUpdate();
                                            //   this.context.forceUpdateCartView();
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-trash"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                              />
                                            </svg>
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ))}
              <br></br>
              <br></br>
              <br></br>
              {this.context.myCart.length > 5 ? (
                <CartTotalMobile myCart={this.context.myCart} />
              ) : (
                ""
              )}

              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export class CartViewHeadingMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Row className="mx-4">
          {/* <Col xs={1} className="verticalaligncentertd">
            <Nav>
              {this.context.portalMode == "Qrcode" ? (
                <>
                  <Nav.Link
                    as={Link}
                    className="p-0 m-0 text-secondary"
                    to="/Qrcode"
                    style={{}}
                  >
                    <h5>&#60;&#60;&nbsp;back</h5>
                  </Nav.Link>
                </>
              ) : (
                <>
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <></>
                  ) : (
                    <>
                      <Nav.Link
                        as={Link}
                        className="p-0 m-0 text-secondary"
                        to="/Home"
                        style={{}}
                      >
                        <h5> &#60;&#60;&nbsp;back</h5>
                      </Nav.Link>
                    </>
                  )}
                </>
              )}
            </Nav>
          </Col> */}
          <Col xs={12} className="text-center">
            <div
              class="title-dsn"
              style={{
                color: this.context.theme.homepageTitlesTextColor,
              }}
            >
              <h4 class="bold_font text-green">My Cart</h4>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export class CartTotalMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      proceedToCheckOut: false,
      showStoreOffline: false,
    };
  }

  ProceedToCheckOutIfStoreIsOnline = () => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/IsStoerOnline/" +
        this.context.storeId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        if (response.status === 200) {
          this.ProceedCheckOut();
        } else {
          this.setState({ showStoreOffline: true });
        }
      })
      .catch((error) => {
        console.error("SendInvoiceEmail:There was an error!", error);
      });
  };

  ProceedCheckOut = () => {
    if (
      window.location.hostname == "shop.green.com.pg" &&
      this.context.selectedCountryCode != "PG"
    ) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "We are sorry as we don't have delivery to your Country at this moment.",
        confirmButtonText: "Ok",
      }).then((result) => {});
      return;
    }

    if (
      this.context.userData.loginStatus === "inactive" ||
      (this.context.userData.loginStatus === "active" &&
        this.context.userData.id === undefined)
    ) {
      this.context.storePreviousPage("/Checkout");
      this.context.ShowUserLoginNew(true);
    } else {
      this.setState({ proceedToCheckOut: true });
      this.context.SetDeliveryMethod("StorePickup");
    }
  };
  CheckOutClick = () => {
    this.ProceedToCheckOutIfStoreIsOnline();
  };
  HideStoreOfflineModal = () => {
    this.setState({ showStoreOffline: false });
  };

  render() {
    if (
      this.context.userData.loginStatus === "active" &&
      this.state.proceedToCheckOut == true
    ) {
      return <Navigate to="/Checkout" />;
    }
    return (
      <>
        {window.location.hostname == "shop.green.com.pg" ? (
          <>
            <Helmet>
              <link rel="icon" type="image/x-icon" href="dist/images/fav.ico" />
              <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
              <link href="dist/css/style.css" rel="stylesheet" />
              <link href="dist/css/responsive.css" rel="stylesheet" />
              <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
              <script src="dist/js/custom.js"></script>
            </Helmet>
          </>
        ) : (
          <></>
        )}
        {this.state.showStoreOffline == true ? (
          <>
            <OfflineStore onHide={this.HideStoreOfflineModal}></OfflineStore>
          </>
        ) : (
          <></>
        )}
        <div className="Container-fluid mx-1 px-1 tablenopadding  ">
          <div className="row mx-1 px-1 tablenopadding">
            <table className="cartviewbarcolor CartDsnMob border text-black tablenopadding">
              <tr className="tablenopadding">
                <td className="text-start py-1">
                  <h4 className="">
                    Total ({this.context.getTotalQtyInCart(this.context.myCart)}{" "}
                    Items) :
                    <b>
                      {this.context
                        .getSubTotalPriceInCart(this.context.myCart)
                        .toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                    </b>
                  </h4>
                  <span className="text-dark">
                    <small>
                      Total Savings:
                      {this.context
                        .getTotalSavingsFromCart(this.context.myCart)
                        .toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                    </small>
                  </span>
                </td>
                <td align="right" className="tablenopadding">
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Button
                        className="btn-green d-inline-block text-white "
                        disabled={this.context.myCart.length > 0 ? "" : true}
                        onClick={this.CheckOutClick}
                      >
                        &nbsp;CheckOut&nbsp;
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="CornersRounded"
                        disabled={this.context.myCart.length > 0 ? "" : true}
                        onClick={this.CheckOutClick}
                        style={{
                          fontFamily: "sans-serif",
                          backgroundColor:
                            this.context.theme.mainNavBarBackgroundColor,
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                      >
                        <h2>
                          <b>&nbsp;&nbsp;CheckOut&nbsp;&nbsp;</b>
                        </h2>
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <br />
      </>
    );
  }
}
