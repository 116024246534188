import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  Modal,
  Row,
  Col,
  Nav,
  Form,
  Button,
  Alert,
  Container,
} from "react-bootstrap";

export default class TermsAndConditions extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTermsAndConditions = () => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetTermsAndConditionsHtml/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          var d1 = document.getElementById("one");
          if (d1) {
            d1.innerHTML = data;
          }
        }
      })
      .catch((error) => {
        console.error("getTermsAndConditions!", error);
      });
  };

  GetMyStoreDetails = () => {
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.context.setStoreId(data.id);
          this.getTermsAndConditions();
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.context.SetHomePageLoadedStatus();
    if (this.context.storeId == "") {
      this.GetMyStoreDetails();
    } else {
      this.getTermsAndConditions();
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
  };

  render() {
    return (
      <>
        <>
          <div className="Container-fluid mx-0 px-1 ">
            <div className="row mt-2 mx-1 px-0">
              <div className="col-md-1 "></div>
              <div className="col-md-10  ">
                <>
                  <br></br>
                  <h3 className="">
                    <b></b>
                  </h3>
                  <br></br>
                  <h5>
                    <div id="one"></div>
                  </h5>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export class PrivacyPolicy extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPrivacyPolicy = () => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetPrivacyPolicyHtml/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          var d1 = document.getElementById("one");
          if (d1) {
            d1.innerHTML = data;
          }
        }
      })
      .catch((error) => {
        console.error("getPrivacyPolicy!", error);
      });
  };
  GetMyStoreDetails = () => {
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.context.setStoreId(data.id);
          this.getPrivacyPolicy();
        }
      })
      .catch((error) => {});
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.context.SetHomePageLoadedStatus();

    if (this.context.storeId == "") {
      this.GetMyStoreDetails();
    } else {
      this.getPrivacyPolicy();
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
  };

  render() {
    return (
      <>
        <>
          <div className="Container-fluid mx-0 px-1 ">
            <div className="row mt-2 mx-1 px-0">
              <div className="col-md-1 "></div>
              <div className="col-md-10  ">
                <>
                  <br></br>
                  <h3 className="">
                    <b></b>
                  </h3>
                  <br></br>
                  <h5>
                    <div id="one"></div>
                  </h5>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export class Faqs extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  getFaqs = () => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetFaqsHtml/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          var d1 = document.getElementById("one");
          if (d1) {
            d1.innerHTML = data;
          }
        }
      })
      .catch((error) => {
        console.error("getFaqs!", error);
      });
  };

  GetMyStoreDetails = () => {
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.context.setStoreId(data.id);
          this.getFaqs();
        }
      })
      .catch((error) => {});
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.context.SetHomePageLoadedStatus();
    if (this.context.storeId == "") {
      this.GetMyStoreDetails();
    } else {
      this.getFaqs();
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
  };

  render() {
    return (
      <>
        <>
          <div className="Container-fluid mx-0 px-1 ">
            <div className="row mt-2 mx-1 px-0">
              <div className="col-md-1 "></div>
              <div className="col-md-10  ">
                <>
                  <br></br>
                  <h3 className="">
                    <b></b>
                  </h3>
                  <br></br>
                  <h5>
                    <div id="one"></div>
                  </h5>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export class ContactUs extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  getContactUs = () => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetContactUsHtml/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          var d1 = document.getElementById("one");
          if (d1) {
            d1.innerHTML = data;
          }
        }
      })
      .catch((error) => {
        console.error("getContactUs!", error);
      });
  };

  GetMyStoreDetails = () => {
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.context.setStoreId(data.id);
          this.getContactUs();
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.context.SetHomePageLoadedStatus();
    if (this.context.storeId == "") {
      this.GetMyStoreDetails();
    } else {
      this.getContactUs();
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
  };

  render() {
    return (
      <>
        <>
          <div className="Container-fluid mx-0 px-1 ">
            <div className="row mt-2 mx-1 px-0">
              <div className="col-md-1 "></div>
              <div className="col-md-10  ">
                <>
                  <br></br>
                  <h3 className="">
                    <b></b>
                  </h3>
                  <br></br>
                  <h5>
                    <div id="one"></div>
                  </h5>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export class Blog extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  GetBlogHtml = () => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetBlogHtml/" +
        this.context.storeId +
        "/" +
        this.context.blogHtml
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          var d1 = document.getElementById("one");
          if (d1) {
            d1.innerHTML = data;
          }
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.GetBlogHtml();
  }

  render() {
    return (
      <>
        <>
          <div className="Container-fluid mx-0 my-5 px-1 ">
            <div className="row mt-2 mx-1 px-0">
              <div className="col-md-1 "></div>
              <div className="col-md-10  ">
                <>
                  <br></br>
                  <h3 className="">
                    <b></b>
                  </h3>
                  <br></br>
                  <h5>
                    <div id="one"></div>
                  </h5>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
