import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Table,
  Modal,
  Row,
  Col,
  Nav,
  Form,
  Button,
  Alert,
  Container,
} from "react-bootstrap";

export default class LifeStyleAquaAuthenticator extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      chipCode: "",
      result: [],
    };
  }

  GetLifeStyleAquaAuthenticatorByChipCode = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetLifeStyleAquaAuthenticatorByChipCode/" +
        this.context.storeId +
        "/" +
        this.state.chipCode
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          if (data.length == 0) {
            Swal.fire({
              title: "Information!",
              confirmButtonColor: "#23B14D",
              text: "Chip Code is NOT FOUND! Please enter valid Chip-Code and try again!",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
          } else {
            this.setState({ result: data }, () => {});
            this.setState({ chipCode: "" });
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "chipCode":
        this.setState({ chipCode: value });
        break;
    }
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
  };

  render() {
    return (
      <>
        <>
          <div className="Container-fluid mx-0 px-1 mb-5">
            <div className="row mt-5 mx-1 px-0  text-center"></div>
            <div className="row mt-2 mx-1 px-0  text-center">
              <br />
              <br />
              <h1 className="my-5">
                {" "}
                <b>Life Style Aqua - Authenticator</b>
              </h1>
              <div className="col-md-2 "></div>
              <div className="col-md-8 my-1 text-center">
                <Form.Group as={Row} className="mb-3 " controlId="">
                  <Form.Label column sm="2" size="lg">
                    <h5>Enter Tag ID:</h5>
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="chipCode"
                      size="lg"
                      maxlength="49"
                      value={this.state.chipCode}
                      required
                      placeholder=""
                      autoComplete="off"
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                  <Col sm="2">
                    <Button
                      size="lg"
                      onClick={(e) =>
                        this.GetLifeStyleAquaAuthenticatorByChipCode()
                      }
                    >
                      Search
                    </Button>
                  </Col>
                </Form.Group>
              </div>
              <div className="col-md-2  "></div>
            </div>
            {this.state.result.length > 0 ? (
              <>
                <div className="row mt-2 mx-1 px-0">
                  <div className="col-md-2 "></div>
                  <div className="col-md-8 my-5">
                    <Table striped bordered hover>
                      <tbody style={{ fontWeight: "bold" }}>
                        <tr>
                          <td width="30%" className="text-start">
                            Tag ID{" "}
                          </td>
                          <td width="70%" className="text-start">
                            {this.state.result[0].chipCode}
                          </td>
                        </tr>
                        <tr>
                          <td>Fish Category </td>
                          <td>{this.state.result[0].fishVariety}</td>
                        </tr>
                        <tr>
                          <td>Farm Name </td>
                          <td>{this.state.result[0].farmName}</td>
                        </tr>
                        <tr>
                          <td>City </td>
                          <td>{this.state.result[0].customerAddress}</td>
                        </tr>
                        <tr>
                          <td>Quality Rating </td>
                          <td>
                            {/* {this.state.result[0].customerMobileNo} */}
                            <div
                              className=" text-start "
                              style={{ fontSize: "24px" }}
                            >
                              {this.state.result[0].customerMobileNo == 1 ? (
                                <>&#9733;</>
                              ) : this.state.result[0].customerMobileNo == 2 ? (
                                <>&#9733;&#9733;</>
                              ) : this.state.result[0].customerMobileNo == 3 ? (
                                <>&#9733;&#9733;&#9733;</>
                              ) : this.state.result[0].customerMobileNo == 4 ? (
                                <>&#9733;&#9733;&#9733;&#9733;</>
                              ) : this.state.result[0].customerMobileNo == 5 ? (
                                <>&#9733;&#9733;&#9733;&#9733;&#9733;</>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <br />
          <br />
          <br />
        </>
      </>
    );
  }
}
