import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  Modal,
  Row,
  Col,
  Nav,
  FloatingLabel,
  Form,
  Button,
  Alert,
  Container,
} from "react-bootstrap";

export default class UserProfile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      ResultStatusText: "",
      validated: false,
      isAccountCreated: false,
      showModel: false,
      firstLoad: false,
    };
  }

  handleInputChange = (e, index) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstName":
        this.state.userData.firstName = value;
        break;
      case "lastName":
        this.state.userData.lastName = value;
        break;
      case "emailId":
        this.state.userData.emailId = value;
        break;
      case "mobileNo":
        if (value.toString().length < 11) this.state.userData.mobileNo = value;
        break;
      case "address":
        this.state.userData.address = value;
        break;
      case "landmark":
        this.state.userData.landmark = value;
        break;
      case "city":
        this.state.userData.city = value;
        break;
      case "state":
        this.state.userData.state = value;
        break;
      case "country":
        this.state.userData.country = value;
        break;
      case "pinCode":
        this.state.userData.pinCode = value;
        break;
    }
    this.setState({ userData: this.state.userData });
    this.setState({ ResultStatusText: "" });
  };

  GetUserData = () => {
    console.log(`Bearer ${this.context.userData.bearerToken}`);

    var requestData = "";
    if (this.context.storeSettings.loginMethod == "SMS") {
      requestData =
        "Consumers/GetUserAccountByMobileNo/" +
        this.context.storeId +
        "," +
        this.context.userData.mobileNo;
    } else if (this.context.storeSettings.loginMethod == "EMAIL") {
      requestData =
        "Consumers/GetUserAccount/" +
        this.context.storeId +
        "," +
        this.context.userData.emailId;
    } else return;

    fetch(
      process.env.REACT_APP_API + requestData,

      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.context.userData.bearerToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.country == null)
          data.country = this.context.storeSettings.defaultCountry;
        if (data.state == null)
          data.state = this.context.storeSettings.defaultState;
        data.bearerToken = this.context.userData.bearerToken;
        this.setState({ userData: data });

        this.context.storeUserData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  componentDidMount() {
    if (this.context.userData.loginStatus === "active") {
      if (this.state.firstLoad == false) {
        this.state.firstLoad = true;
        this.GetUserData();
      }
    }
  }

  SaveUserProfile() {
    this.state.userData.customerUserTypeId = 1;
    this.state.userData.modifiedDateTime = new Date().toJSON();
    this.state.userData.country = this.context.storeSettings.defaultCountry;

    fetch(
      process.env.REACT_APP_API +
        "Consumers/UpdateUserAccount/" +
        this.context.storeId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.userData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404 || data.status == 400) {
          this.setState({
            ResultStatusText: "Unable to Save. try later!",
          });
          return;
        }
        console.log("User profile updated success");
        this.setState({
          ResultStatusText: "Saved Successfully!",
        });
        this.context.storeUserData(data);
        return;
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("Error while Save:" + error);
      });
  }

  ValidateExtra = () => {
    var phoneValid = true;
    var pinCodeValid = true;

    if (
      this.state.userData.mobileNo.toString().length !==
      this.context.storeSettings.phoneNumberLength
    ) {
      this.state.userData.mobileNo = "";
      this.setState({ userData: this.state.userData });
      phoneValid = false;
    }

    if (
      this.state.userData.pinCode.toString().length !==
      this.context.storeSettings.pincodeLength
    ) {
      this.state.userData.pinCode = "";
      this.setState({ userData: this.state.userData });
      pinCodeValid = false;
    }

    if (phoneValid == true && pinCodeValid == true) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false || this.ValidateExtra() === false) {
      event.stopPropagation();
    } else {
      this.SaveUserProfile();
    }
    this.setState({ validated: true });
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        <>
          <Helmet>
            <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
            <link href="dist/css/style.css" rel="stylesheet" />
            <link href="dist/css/responsive.css" rel="stylesheet" />
            <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
            <script src="dist/js/custom.js"></script>
          </Helmet>

          <div
            className="Container-fluid mx-0 px-1 bg-white"
            style={{ paddingTop: "65px" }}
          >
            <div className="row mt-2 mx-1 px-0">
              <div className="col-md-1 "></div>
              <div className="col-md-10 UserProfileDsn ">
                <>
                  <h3>
                    <b>My Profile</b>
                  </h3>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    onSubmit={this.handleSubmit}
                  >
                    <div className=" ">
                      {/* <h4 className="text-dark">Account information</h4> */}

                      <Row className="py-1">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          {/* <Form.Label size="lg" className="">
                            First Name
                          </Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="First Name *"
                            className="mb-3  "
                          >
                            <Form.Control
                              type="text"
                              name="firstName"
                              placeholder="First Name *"
                              className="bg-white"
                              size="lg"
                              required
                              value={this.state.userData.firstName}
                              onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a name
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom02"
                        >
                          {/* <Form.Label size="lg" className="">
                            Last Name
                          </Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Last Name *"
                            className="mb-3  "
                          >
                            <Form.Control
                              type="text"
                              name="lastName"
                              placeholder="Last Name *"
                              className="bg-white"
                              size="lg"
                              value={this.state.userData.lastName}
                              onChange={this.handleInputChange}
                            />
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom03"
                        >
                          {/* <Form.Label>Phone No. </Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Mobile Number*"
                            className="mb-3  "
                          >
                            <Form.Control
                              type="text"
                              name="mobileNo"
                              placeholder="Mobile No *"
                              className="bg-white"
                              size="lg"
                              required
                              disabled={
                                this.context.storeSettings.loginMethod == "SMS"
                                  ? true
                                  : false
                              }
                              value={this.state.userData.mobileNo}
                              onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid{" "}
                              {this.context.storeSettings.phoneNumberLength}
                              digit Phone Number!
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Row>
                      <Row className="py-1">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom04"
                        >
                          {/* <Form.Label size="lg" className="">
                            Email address
                          </Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email ID *"
                            className="mb-3  "
                          >
                            <Form.Control
                              type="email"
                              name="emailId"
                              placeholder="Email address *"
                              required
                              disabled={
                                this.context.storeSettings.loginMethod ==
                                "EMAIL"
                                  ? true
                                  : false
                              }
                              className="bg-white"
                              size="lg"
                              value={this.state.userData.emailId}
                              onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid Email ID.
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom08"
                        >
                          {/* <Form.Label>Landmark (optional)</Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Landmark"
                            className="mb-3  px-0"
                          >
                            <Form.Control
                              type="text"
                              name="landmark"
                              size="lg"
                              placeholder="Landmark"
                              className="bg-white"
                              value={this.state.userData.landmark}
                              onChange={this.handleInputChange}
                            />
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom09"
                        >
                          {/* <Form.Label>Country</Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Country *"
                            className="mb-3  "
                          >
                            <Form.Select
                              value={this.state.userData.country}
                              name="country"
                              size="lg"
                              required
                              disabled
                              onChange={this.handleInputChange}
                            >
                              {Country.getAllCountries().map((country) => (
                                <>
                                  <option value={country.isoCode}>
                                    {country.name}
                                  </option>
                                </>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please select country
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                        {/* <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom05"
                        >
                          <Form.Label> Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="enter password"
                            className="bg-white"
                            size="lg"
                            required
                            value={this.state.userData.password}
                            onChange={this.handleChangePassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid password.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom06"
                        >
                          <Form.Label> Re-ener Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="re-enter password"
                            className="bg-white"
                            size="lg"
                            required
                            value={this.state.userData.passwordReenter}
                            onChange={this.handleChangePasswordReenter}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please re-enter password
                          </Form.Control.Feedback>
                        </Form.Group> */}
                      </Row>
                      <Row className="py-1">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom07"
                        >
                          {/* <Form.Label>Address</Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Full Address *"
                            className="mb-3 moveLabelUp "
                          >
                            <Form.Control
                              as="textarea"
                              name="address"
                              rows={2}
                              size="lg"
                              required
                              placeholder="Full Address *"
                              value={this.state.userData.address}
                              onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter address
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Row>
                      <Row className="pd-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom10"
                        >
                          <FloatingLabel
                            controlId="floatingInput"
                            label={
                              window.location.hostname ==
                              "shop.green.com.pg" ? (
                                <>Province *</>
                              ) : (
                                <>State *</>
                              )
                            }
                            className="mb-3  "
                          >
                            <Form.Select
                              value={this.state.userData.state}
                              size="lg"
                              name="state"
                              required
                              onChange={this.handleInputChange}
                            >
                              {State.getStatesOfCountry(
                                this.state.userData.country
                              ).map((s1) => (
                                <>
                                  <option>{s1.name}</option>
                                </>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please select State
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom11"
                        >
                          {/* <Form.Label>City</Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="City *"
                            className="mb-3  "
                          >
                            <Form.Control
                              type="text"
                              name="city"
                              placeholder="City *"
                              size="lg"
                              required
                              className="bg-white"
                              value={this.state.userData.city}
                              onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter City
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom12"
                        >
                          {/* <Form.Label>Postal Code</Form.Label> */}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Postal Code *"
                            className="mb-3  "
                          >
                            <Form.Control
                              type="number"
                              name="pinCode"
                              placeholder="Postal Code *"
                              className="bg-white"
                              size="lg"
                              required
                              value={this.state.userData.pinCode}
                              onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter valid{" "}
                              {this.context.storeSettings.pincodeLength} digit
                              Postal Code!
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Row>
                      <br></br>
                      <Row className="text-center">
                        <h5 className="blink_me text-black">
                          {this.state.ResultStatusText}
                        </h5>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          {window.location.hostname == "shop.green.com.pg" ? (
                            <>
                              <Button
                                variant="success"
                                type="submit"
                                className="btn-green"
                                size="lg"
                              >
                                Save
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="success"
                                type="submit"
                                style={{
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                                size="lg"
                              >
                                Save
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                      <br></br>
                    </div>
                  </Form>
                  <Modal
                    show={this.state.showModel}
                    onHide={this.handleModelClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Signup Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Signup Success!</Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={this.handleModelClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
