import React, { useState, useContext, Component, createRef } from "react";
import pincode from "pincode-distance/lib/pincode";
import { Country, State, City } from "country-state-city";
import CartContext from "./CartContext";
import "../CSS/3d.css";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { sha256 } from "js-sha256";

import {
  Modal,
  Nav,
  Form,
  Table,
  Container,
  Stack,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { render } from "@testing-library/react";
import { getCurrentDateTime } from "../../helper";
import { useEffect } from "react";

export class CheckoutUpiManual extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      paidCheck: false,
    };
  }
  handleSubmit = (event) => {
    this.props.onPaidSuccess();
  };
  onPaidCheck = (event) => {
    this.setState({ paidCheck: event.target.checked });
  };
  componentDidMount = () => {
    // var d1 = document.getElementById("one");
    // d1.innerHTML =
    //   "<html>And I can share the payment details via whatsapp number <a href='https://wa.me/" +
    //   this.context.storeSettings.storeWhatsappNo +
    //   "' target='_blank'>" +
    //   this.context.storeSettings.storeWhatsappNo +
    //   "</a></html>";
  };
  render() {
    return (
      <>
        <div className="container " style={{ fontFamily: "sans-serif" }}>
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            show={true}
            size="lg"
            centered
            // dialogClassName="xxlmodal"
            style={{ fontFamily: "sans-serif" }}
          >
            <Form onSubmit={this.handleSubmit} className="">
              <Modal.Header
                // closeButton
                style={{
                  backgroundColor: this.context.theme.mainNavBarBackgroundColor,
                  color: this.context.theme.mainNavBarTextColor,
                  borderColor: "white",
                }}
              >
                <Modal.Title id="contained-modal-title-vcenter">
                  UPI Payment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className=""
                style={{
                  backgroundColor:
                    this.context.theme.productCardBackgroundColor,
                  color: this.context.theme.productCardTextColor,
                }}
              >
                <h2>
                  <span className="blink_me">Total Amount to pay is: </span>
                  <b>
                    {this.context
                      .getTotalAmountWithTax()
                      .toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                  </b>
                </h2>

                {window.innerWidth > 1200 ? (
                  <>
                    <Table className="border bg-light">
                      <tbody>
                        <tr>
                          <td xs={2}>
                            <h5>
                              <b>
                                <u>Option1:</u>
                              </b>
                            </h5>
                          </td>
                          <td xs={10}>
                            <h5>
                              Scan below UPI and pay via Patym/PhonePe/GPay App!{" "}
                            </h5>

                            <img
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/qrcode.png" +
                                this.context.store.storageSasToken
                              }
                              className="text-center"
                              alt="..."
                              width="150px"
                            ></img>
                          </td>
                        </tr>
                        <tr>
                          <td xs={2}>
                            <h5>
                              <b>
                                <u>(or) Option2:</u>
                              </b>
                            </h5>
                          </td>
                          <td xs={10}>
                            <h5>
                              use below UPI-ID to pay via Patym/PhonePe/GPay App
                            </h5>
                            <h3 className="">
                              <b>{this.context.storeSettings.upiId}</b>
                            </h3>
                          </td>
                        </tr>
                        <tr>
                          <td xs={2}>
                            <h5>
                              <b>
                                <u>(or) Option3:</u>
                              </b>
                            </h5>
                          </td>
                          <td xs={10}>
                            <h5>
                              use below Phone Number to pay via
                              Patym/PhonePe/GPay App
                            </h5>
                            <h3 className="">
                              <b>{this.context.storeSettings.upiPhoneNo}</b>
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <Table className="border bg-light">
                      <tbody>
                        <tr>
                          <td xs={2}>
                            <h5>
                              <b>
                                <u>Option1:</u>
                              </b>
                            </h5>
                          </td>
                          <td xs={10}>
                            <h5>
                              use below UPI-ID to pay via Patym/PhonePe/GPay App
                            </h5>
                            <h3 className="">
                              <b>{this.context.storeSettings.upiId}</b>
                            </h3>
                          </td>
                        </tr>
                        <tr>
                          <td xs={2}>
                            <h5>
                              <b>
                                <u>(or) Option2:</u>
                              </b>
                            </h5>
                          </td>
                          <td xs={10}>
                            <h5>
                              use below Phone Number to pay via
                              Patym/PhonePe/GPay App
                            </h5>
                            <h3 className="">
                              <b>{this.context.storeSettings.upiPhoneNo}</b>
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                )}

                <br />
                <h4 className="text-dark">
                  <Form.Check
                    className=""
                    size=""
                    checked={this.state.paidCheck}
                    name="deliverygroup"
                    label={
                      "Yes, I confirm that i have Paid amount = " +
                      this.context
                        .getTotalAmountWithTax()
                        .toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )
                    }
                    id="paymentcheck"
                    onChange={this.onPaidCheck}
                  ></Form.Check>
                  {/* <div id="one"></div> */}
                </h4>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  size="lg"
                  disabled={this.state.paidCheck == true ? "" : "true"}
                >
                  Place Order!
                </Button>
                <Button size="lg" variant="danger" onClick={this.props.onHide}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}
