import { Component, useContext, useState } from "react";
import { Button, Table, Tooltip, OverlayTrigger, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import CartContext from "./CartContext";
import IncDecCounter from "./IncDecCounterControl";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { OfflineStore } from "./OfflineStore";

export default class CartView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { forUpdateValue: 1 };
  }

  //static forceUpdate = useForceUpdate();
  //create your forceUpdate hook
  forceUpdate = () => {
    this.setState({ forUpdateValue: 0 }, () => {
      this.setState({ forUpdateValue: 1 });
    });
  };

  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.context.myCart.filter(
      (item) => item.prod.mainCategoryId === maincategory.id
    ).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };

  ValidateProductInstockAvailablity = () => {
    var cart = this.context.myCart;
    cart.map((p, i) => {
      //if multivariant
      if (p.prod.name.includes("- [")) {
        //TODO.. check instock on the backend. delete if out of stock
      } else {
        var prd = this.context.prodsAll.filter((f) => f.id == p.prod.id);
        if (prd.length == 1 && prd[0].inStockQty >= Number(p.qty)) {
          //ok
          p.prod.discount = prd[0].discount;
          p.prod.mrp = prd[0].mrp;
          p.prod.name = prd[0].name;

          console.log("prod in-stock" + prd[0].name);
        } else {
          //delete prod from cart
          this.context.deleteItemOnMyCart(p.prod);
        }
      }
    });
    this.forceUpdate();
  };

  CloseCartOffCanvas = () => {
    this.props.CloseCartOffCanvas();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.ValidateProductInstockAvailablity();
    this.context.UpdateOrderStatus("");
  }
  componentDidUpdate() {}

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.forUpdateValue == 0) return "";
    return (
      <>
        <CartTotal
          myCart={this.context.myCart}
          CloseCartOffCanvas={this.CloseCartOffCanvas}
        />
        {this.context.mainCategories.map((maincategory) => (
          <>
            {this.isMainCategoryHasItems(maincategory) == true ? (
              <div className="mx-1 px-1 cartTable">
                <div className="row mx-1 px-1 ">
                  <h5>&nbsp;</h5>
                  <h4 className=" ">
                    <b>
                      <strong>{maincategory.name}</strong>
                    </b>
                  </h4>

                  <div className="px-1">
                    <Table
                      border
                      className="cartviewtable px-2"
                      responsive="md"
                    >
                      <thead>
                        <tr className="bg-light text-center text-secondary">
                          <th className="" width="5%"></th>
                          <th className="text-start" width="30%"></th>
                          <th width="10%">Price</th>
                          <th width="8%">Disc</th>
                          <th width="24%">Qty</th>
                          <th width="18%">Sub.Total</th>
                          <th width="5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.context.myCart
                          .filter(
                            (item) =>
                              item.prod.mainCategoryId === maincategory.id
                          )
                          .map((cartitem, i) => (
                            <>
                              <tr className="text-center align-middle text-black">
                                <td>
                                  <Link
                                    to="/ProductSingleProductViewWrapper"
                                    className="text-dark"
                                    style={{ textDecoration: "none" }}
                                  >
                                    {this.context.IsVideo(
                                      cartitem.prod.imgFileName
                                    ) == true ? (
                                      <>
                                        <img
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            cartitem.prod.posterName +
                                            this.context.store.storageSasToken
                                          }
                                          className="CornersRoundedSmall"
                                          onClick={(e) => {
                                            this.context.StoreShopnowProduct(
                                              cartitem.prod
                                            );
                                            this.CloseCartOffCanvas();
                                          }}
                                          alt="..."
                                          style={{ width: "60px" }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            cartitem.prod.imgFileName +
                                            this.context.store.storageSasToken
                                          }
                                          className="CornersRoundedSmall"
                                          onClick={(e) => {
                                            this.context.StoreShopnowProduct(
                                              cartitem.prod
                                            );
                                            this.CloseCartOffCanvas();
                                          }}
                                          alt="..."
                                          // style={{ width: "1.5cm" }}
                                        />
                                      </>
                                    )}
                                  </Link>
                                </td>
                                <td className="text-black text-start">
                                  <Link
                                    to="/ProductSingleProductViewWrapper"
                                    className="text-dark"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <span
                                      onClick={(e) => {
                                        this.context.StoreShopnowProduct(
                                          cartitem.prod
                                        );
                                        this.CloseCartOffCanvas();
                                      }}
                                    >
                                      <strong>{cartitem.prod.name}</strong>
                                    </span>
                                  </Link>
                                  <br></br>
                                </td>
                                <td>
                                  {cartitem.prod.mrp.toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </td>
                                <td>{cartitem.prod.discount}%</td>
                                <td className="border-0 border-dark">
                                  <IncDecCounter
                                    intvalue={cartitem.qty}
                                    valueChangeCallback={(e) => {
                                      if (e === "") {
                                      } else if (e === 0) {
                                        this.context.deleteItemOnMyCart(
                                          cartitem.prod
                                        );
                                        this.context.forceUpdateCartView();
                                        this.context.SetRefreshIncDecControl(
                                          true
                                        );
                                        this.forceUpdate(); //to rerender this function component
                                      } else {
                                        cartitem.qty = e;
                                        this.context.SetStateOfMyCart(
                                          this.context.myCart
                                        );
                                        this.context.forceUpdateCartView();
                                        this.context.SetRefreshIncDecControl(
                                          true
                                        );
                                        this.forceUpdate(); //to rerender this function component
                                      }
                                    }}
                                  ></IncDecCounter>
                                </td>
                                <td className=" text-black ">
                                  <strong>
                                    {Math.round(
                                      Number(
                                        cartitem.qty *
                                          (cartitem.prod.mrp -
                                            (cartitem.prod.mrp *
                                              cartitem.prod.discount) /
                                              100)
                                      ),
                                      0
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </strong>
                                  <br></br>
                                  <span style={{ fontSize: "12px" }}>
                                    Savings:&nbsp;
                                    {Math.round(
                                      Number(
                                        cartitem.qty *
                                          ((cartitem.prod.mrp *
                                            cartitem.prod.discount) /
                                            100)
                                      ),
                                      0
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </span>
                                </td>
                                <td className="">
                                  <OverlayTrigger
                                    overlay={<Tooltip>Delete!</Tooltip>}
                                  >
                                    <Button
                                      variant="outline-danger"
                                      onClick={(e) => {
                                        Swal.fire({
                                          title: "Warning!",
                                          confirmButtonColor: "#23B14D",
                                          cancelButtonColor: "#dbdbdb",
                                          text: "Do you want to delete?",
                                          showCancelButton: "true",
                                          confirmButtonText: "Yes",
                                          cancelButtonText: "No",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            this.context.deleteItemOnMyCart(
                                              cartitem.prod
                                            );
                                            this.forceUpdate();
                                            this.context.forceUpdateCartView();
                                          }
                                        });

                                        // if (
                                        //   window.confirm(
                                        //     "Do you want to Delete?"
                                        //   )
                                        // ) {
                                        //   this.context.deleteItemOnMyCart(
                                        //     cartitem.prod
                                        //   );
                                        //   this.forceUpdate();
                                        //   this.context.forceUpdateCartView();
                                        // }
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                          fill-rule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                      </svg>
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ))}
        <br></br>
        {this.context.myCart.length > 5 ? (
          <>
            <CartTotal
              myCart={this.context.myCart}
              CloseCartOffCanvas={this.CloseCartOffCanvas}
            />
          </>
        ) : (
          <></>
        )}

        <br></br>
      </>
    );
  }
}

export function CartViewHeading() {
  return (
    <>
      <div className="checkout-right">
        <h4 className=" text-primary mx-1 checkout-right">
          <b className="">My Cart</b>
        </h4>
        <span className="item-count">
          {this.context.getTotalQtyInCart(this.context.myCart)}
        </span>
      </div>
    </>
  );
}

export class CartTotal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      proceedToCheckOut: false,
      showStoreOffline: false,
    };
  }

  ProceedToCheckOutIfStoreIsOnline = () => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/IsStoerOnline/" +
        this.context.storeId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        if (response.status === 200) {
          this.ProceedCheckOut();
        } else {
          this.setState({ showStoreOffline: true });
        }
      })
      .catch((error) => {
        console.error("SendInvoiceEmail:There was an error!", error);
      });
  };

  ProceedCheckOut = () => {
    if (
      window.location.hostname == "shop.green.com.pg" &&
      this.context.selectedCountryCode != "PG"
    ) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "We are sorry as we don't have delivery to your Country at this moment.",
        confirmButtonText: "Ok",
      }).then((result) => {});
      return;
    }
    if (
      this.context.userData.loginStatus === "inactive" ||
      (this.context.userData.loginStatus === "active" &&
        this.context.userData.id === undefined)
    ) {
      this.context.storePreviousPage("/Checkout");
      this.context.ShowUserLoginNew(true);
    } else {
      this.setState({ proceedToCheckOut: true });
      this.context.SetDeliveryMethod("StorePickup");
    }
  };

  CheckOutClick = () => {
    this.ProceedToCheckOutIfStoreIsOnline();
  };
  HideStoreOfflineModal = () => {
    this.setState({ showStoreOffline: false });
  };

  render() {
    if (
      this.context.userData.loginStatus === "active" &&
      this.state.proceedToCheckOut == true
    ) {
      this.props.CloseCartOffCanvas();
      return <Navigate to="/Checkout" />;
    }

    return (
      <>
        <div
          className="Container-fluid mx-2 px-1 tablenopadding"
          style={{ fontFamily: "sans-serif" }}
        >
          {this.state.showStoreOffline == true ? (
            <>
              <OfflineStore onHide={this.HideStoreOfflineModal}></OfflineStore>
            </>
          ) : (
            <></>
          )}

          <div className="row mx-2 px-0 tablenopadding">
            <table className="cartviewbarcolor border  tablenopadding">
              <tr className="">
                <td className="text-start py-4 " width="25%">
                  <h3 className="cartPriceData">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Total</b>
                    <small>
                      &nbsp;(
                      {this.context.getTotalQtyInCart(this.context.myCart)}{" "}
                      Items)
                    </small>
                    :&nbsp;
                    <b className="text-black">
                      {this.context
                        .getSubTotalPriceInCart(this.context.myCart)
                        .toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                    </b>
                    &nbsp;&nbsp;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <small>
                      Savings:&nbsp;
                      {this.context
                        .getTotalSavingsFromCart(this.context.myCart)
                        .toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}{" "}
                    </small>
                  </h3>
                </td>
                <td align="right" className="p-3" width="20%">
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Button
                        className="btn-green-3 d-inline-block text-white "
                        size="lg"
                        disabled={this.context.myCart.length > 0 ? "" : true}
                        onClick={this.CheckOutClick}
                      >
                        <strong>&nbsp;&nbsp;CheckOut&nbsp;&nbsp;</strong>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="CornersRounded"
                        size="lg"
                        disabled={this.context.myCart.length > 0 ? "" : true}
                        style={{
                          backgroundColor:
                            this.context.theme.mainNavBarBackgroundColor ==
                            undefined
                              ? "white"
                              : this.context.theme.mainNavBarBackgroundColor,
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                        onClick={this.CheckOutClick}
                      >
                        <h3>
                          <b>&nbsp;&nbsp;CheckOut&nbsp;&nbsp;</b>
                        </h3>
                      </Button>
                    </>
                  )}

                  {/* <Nav.Link
                    as={Link}
                    className=" text-white w-50 text-center mx-4 primarythemecolor"
                    to={
                      this.context.getTotalQtyInCart(this.props.myCart) > 0
                        ? "/Checkout"
                        : ""
                    }
                  >
                    <h5>                      
                      <strong className="text-white ">CheckOut...</strong>
                    </h5>
                  </Nav.Link> */}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </>
    );
  }
}
