import { render } from "@testing-library/react";
import React, { Component, useContext, useState } from "react";
import { BsFillHeartFill, BsHeart, BsCart2, BsBag } from "react-icons/bs";
import {
  ProductSingleProductView,
  ProductSingleProductViewWrapper,
} from "./ProductSingleProductView";
import axios from "axios";
import IncDecCounter from "./IncDecCounterControl";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";

import {
  Form,
  Button,
  Modal,
  ListGroup,
  Badge,
  FloatingLabel,
  Row,
  Col,
  Table,
  Nav,
  InputGroup,
  Container as div,
  Stack,
} from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import CartContext from "./CartContext";

export default class ProductCard extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      qty: "1",
      weightAndPrice: "",
      showProductSingleViewModal: false,
      showProductSingleViewPage: false,
      reload: false,
      firstload: false,
    };
  }
  static weightString = "";
  static priceString = "";

  componentDidMount() {
    if (this.state.firstload == false) {
      this.state.firstload = true;
    }
  }

  addToCartSubmit = () => {
    this.context.addToMyCart({
      prod: this.props.prod,
      qty: this.state.qty,
    });
    //this.context.forceUpdateCartView();
  };

  handleQtyChange = (value) => {
    this.setState({ qty: value });
  };

  getFullWidthProductName = (name) => {
    var len = name.length;
    var whitespacerequired = 60 - len;
    for (let i = 0; i < whitespacerequired; i++) {
      name += "\u00A0";
    }

    return name;
  };

  printwhitespace = () => {
    return "";
  };

  OpenSingleViewModal = () => {
    this.setState({ showProductSingleViewModal: true });
  };

  OnClickShopNow = () => {
    if (this.props.hideSearchModal != undefined) this.props.hideSearchModal();
    this.context.StoreShopnowProduct(this.props.prod);
  };

  editModalClose = () => {
    this.setState({ showProductSingleViewModal: false }, () => {
      this.context.forceUpdateCartView();
      this.context.SetRefreshIncDecControl(true);
    });
  };

  IsAlreadyInWishlist = (prodId) => {
    var res = this.context.wishListProductIds.filter(
      (f) => f.prodId == this.props.prod.id
    );
    if (res.length > 0) return true;
    else return false;
  };
  IsVideo(filename) {
    if (filename) {
      var f = filename.split(".").pop();
      if (f == "mp4") return true;
    }
    return false;
  }
  GetPosterFileName = () => {
    if (
      this.props.prod.productImages &&
      this.props.prod.productImages.length > 0
    ) {
      return this.props.prod.productImages[0].posterName;
    } else return "noimage.jpg";
  };

  componentDidUpdate = () => {};

  render() {
    // if (!this.props.prod) return <></>;
    if (this.state.showProductSingleViewPage == true) {
      if (this.props.parent == "SearchView") {
        this.context.SetRefreshSinglePageView(true);
        this.props.hideSearchModal();
      }
      return <Navigate to="/ProductSingleProductView1"></Navigate>;
    }

    return (
      <>
        {window.location.hostname == "shop.green.com.pg" ? (
          <>
            <div
              style={{
                backgroundColor: this.context.theme.productCardBackgroundColor,
              }}
            >
              <Form
                className=""
                style={{
                  backgroundColor:
                    this.context.theme.productCardBackgroundColor,
                }}
              >
                <Form.Group
                  className="mb-1 border-0"
                  controlId="formBasicEmail"
                >
                  <>
                    <div class="item -dark m-0 py-0 imgcenter border-0 ">
                      <div className=" m-0 p-0 w-100">
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            (this.props.prod.imgFileName == undefined
                              ? this.props.prod.productImages != undefined &&
                                this.props.prod.productImages.length > 0
                                ? this.props.prod.productImages[0].fileName
                                : "noimage.jpg"
                              : this.props.prod.imgFileName) +
                            this.context.store.storageSasToken
                          }
                          className="card-img-top text-center align-top align-content-start "
                          alt="..."
                          // onClick={(e) => this.OpenSingleViewModal(e)}
                          onClick={(e) => {
                            if (this.props.parent != "singleview")
                              this.OnClickShopNow(e);
                          }}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height:
                              this.context.theme.productCardImageHeight + "cm",
                          }}
                        ></img>
                      </div>
                    </div>
                  </>
                  <br />
                  <br />
                  <label
                    className="mx-0 p-0 mb-0  productNamewrap "
                    style={{
                      width: "100%",
                      // height:
                      //   this.context.theme.productNameHeightOnProductCard +
                      //   "cm",
                      //fontFamily: "Helvetica",
                      fontWeight: "bold",
                      textAlign:
                        this.context.theme.productNameAlignOnProductCard,
                      color: this.context.theme.productCardTextColor,
                      fontSize: "12px",
                    }}
                    // onClick={(e) => this.OpenSingleViewModal(e)}
                  >
                    <h2 class="bold_font">
                      {
                        this.context.subCategories.filter(
                          (m) => m.id == this.props.prod.subCategoryId
                        )[0].name
                      }
                    </h2>
                    <h5>{this.props.prod.name}</h5>
                  </label>
                </Form.Group>
                <br />
                {this.props.prod.shortAd ? (
                  <span className="ribbonleftgreen ribboncorners">
                    {this.props.prod.shortAd}
                  </span>
                ) : (
                  <></>
                )}
                {this.props.prod.discount > 0 ? (
                  <>
                    {/* <span class="notify-badge-left"> */}
                    <span
                      // class="notify-badge-left"
                      className="ribbonmodernright "
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Discount"
                      style={{
                        backgroundColor: "#23B14D",
                        color: "white",
                      }}
                    >
                      {this.props.prod.discount}% off
                    </span>
                  </>
                ) : (
                  ""
                )}
                <Form.Label
                  className="text-center "
                  style={{
                    width: "100%",
                    color: this.context.theme.productCardTextColor,
                  }}
                >
                  {this.props.prod.discount > 0 ? (
                    <>
                      <div class="row pt-3 ">
                        <div class="">
                          <h4>
                            <span class="semibold_font">
                              {Math.round(
                                Number(
                                  this.props.prod.mrp -
                                    (this.props.prod.mrp *
                                      this.props.prod.discount) /
                                      100
                                ),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </span>{" "}
                            &nbsp;&nbsp;
                            <span
                              className=""
                              style={{
                                color: this.context.theme.productCardTextColor,
                              }}
                            >
                              <strike>
                                {Number(this.props.prod.mrp).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </strike>
                            </span>
                          </h4>
                          {/* <h5 class="bold cl-gra-green">
                            ({this.props.prod.discount}% OFF)
                          </h5> */}

                          {/* <p class="bold cl-grey">Inclusive of all taxes</p> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="row pt-3 ">
                        <div class="">
                          <h4>
                            <span class="semibold_font">
                              {Math.round(
                                Number(this.props.prod.mrp),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </span>
                          </h4>

                          {/* <p class="bold cl-grey">Inclusive of all taxes</p> */}
                        </div>
                      </div>
                    </>
                  )}
                </Form.Label>

                <div class="text-center ">
                  <div className="single-item">
                    <Nav.Link
                      className="btn-dgn-2"
                      href={
                        window.location.origin +
                        "/SingleProductViewGreenArg?sku=" +
                        this.props.prod.sku
                      }
                    >
                      Shop now{" "}
                    </Nav.Link>
                  </div>
                </div>
                <span
                  class="notify-badge-right_share"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Share me"
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "black",
                  }}
                >
                  <RWebShare
                    data={{
                      text: this.props.prod.name,
                      url:
                        window.location.origin +
                        "/SingleProductViewGreenArg?sku=" +
                        this.props.prod.sku,
                      title: this.props.prod.name,
                    }}
                    sites={[
                      "whatsapp",
                      "telegram",
                      "mail",
                      "twitter",
                      "facebook",
                      "linkedin",
                      "reddit",
                      // "copy",
                    ]}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <FaShareAlt size="16" color="green" />
                  </RWebShare>
                </span>
              </Form>
            </div>
          </>
        ) : (
          <>
            <div
              className="p-0 m-0 border-0 border-danger CornersRounded"
              style={{
                backgroundColor: this.context.theme.productCardBackgroundColor,
                borderColor: this.context.theme.productCardBorderColor,
              }}
            >
              {this.state.showProductSingleViewModal == true ? (
                <ProductSingleProductView
                  show={this.state.showProductSingleViewModal}
                  onHide={this.editModalClose}
                  prod={this.props.prod}
                />
              ) : (
                ""
              )}

              {this.props.displayType == "list" ? (
                <>
                  {this.props.prod.shortAd ? (
                    <span
                      className="ribbonleft ribboncorners"
                      style={{
                        backgroundColor:
                          this.context.theme.mainNavBarBackgroundColor,
                        color: this.context.theme.mainNavBarTextColor,
                      }}
                    >
                      {this.props.prod.shortAd}
                    </span>
                  ) : (
                    <></>
                  )}
                  {this.props.prod.discount > 0 ? (
                    <>
                      <span
                        // class="notify-badge-left"
                        className="ribbonmodernright"
                        style={{
                          cursor: "pointer",
                          // backgroundColor:
                          //   this.context.theme.mainNavBarBackgroundColor,
                          backgroundColor: "red",
                          color: this.context.theme.mainNavBarTextColor,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.prod.discount}% off
                      </span>
                    </>
                  ) : (
                    ""
                  )}

                  <Table className="border-0 p-0 m-0">
                    <tr>
                      <td width={"35%"}>
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            (this.props.prod.imgFileName == undefined
                              ? this.props.prod.productImages != undefined &&
                                this.props.prod.productImages.length > 0
                                ? this.props.prod.productImages[0].fileName
                                : "noimage.jpg"
                              : this.props.prod.imgFileName) +
                            this.context.store.storageSasToken
                          }
                          className="card-img-top CornersRounded"
                          alt="..."
                          onClick={(e) => this.OpenSingleViewModal(e)}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            // height:
                            //   this.context.theme.productCardImageHeightMobile +
                            //   "cm",
                            objectFit: "contain",
                          }}
                        ></img>
                      </td>
                      <td width={"65%"}>
                        <h5
                          style={{
                            width: "100%",
                            color: this.context.theme.productCardTextColor,
                            fontFamily: "Helvetica",
                          }}
                        >
                          <b>{this.props.prod.name}</b>
                        </h5>

                        <div
                          className="text-start p-0 m-0"
                          style={{
                            width: "100%",
                            color: this.context.theme.productCardTextColor,
                            //fontFamily: "Helvetica",
                            fontFamily: "monospace",
                          }}
                        >
                          {this.props.prod.discount > 0 ? (
                            <>
                              <div>
                                <span
                                  style={{
                                    fontSize:
                                      this.context.theme
                                        .priceFontSizeOnProductCardMobile +
                                      "px",
                                    // color: this.context.theme.productCardTextColor,
                                  }}
                                >
                                  <b
                                    className="costfont"
                                    style={{
                                      fontSize:
                                        this.context.theme
                                          .priceFontSizeOnProductCardMobile +
                                        "px",
                                      // color: this.context.theme.productCardTextColor,
                                    }}
                                  >
                                    &nbsp;
                                    {Math.round(
                                      Number(
                                        this.props.prod.mrp -
                                          (this.props.prod.mrp *
                                            this.props.prod.discount) /
                                            100
                                      ),
                                      0
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </b>{" "}
                                  &nbsp;
                                  <span
                                    className=" "
                                    style={{
                                      color: "grey",
                                      //this.context.theme.productCardTextColor,
                                    }}
                                  >
                                    {/* M.R.P  */}
                                    <strike>
                                      {Math.round(
                                        Number(this.props.prod.mrp)
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </strike>
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <span
                                  className="text-center costfont "
                                  style={{
                                    fontSize:
                                      this.context.theme
                                        .priceFontSizeOnProductCardMobile +
                                      "px",
                                  }}
                                >
                                  <small className=""> </small>
                                  {Math.round(
                                    this.props.prod.mrp,
                                    0
                                  ).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <table className="p-0 m-0 ">
                          <tbody>
                            <tr>
                              <td className="text-center">
                                <>
                                  {this.context.myCart.filter(
                                    (f) => f.prod.id == this.props.prod.id
                                  ).length == 1 ? (
                                    <>
                                      <div className=" p-2 cartTable">
                                        <IncDecCounter
                                          intvalue={
                                            this.context.myCart.filter(
                                              (f) =>
                                                f.prod.id == this.props.prod.id
                                            )[0].qty
                                          }
                                          valueChangeCallback={(e) => {
                                            this.context.UpdateQtyToCart(
                                              this.props.prod,
                                              e
                                            );
                                            this.context.forceUpdateCartView();
                                          }}
                                        ></IncDecCounter>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className=" p-2">
                                        <Button
                                          className="align-content-center w-100 border-0 buttonRounded buttonwithshadow"
                                          onClick={this.addToCartSubmit}
                                          style={{
                                            backgroundColor:
                                              this.context.theme
                                                .mainNavBarBackgroundColor,
                                            color:
                                              this.context.theme
                                                .mainNavBarTextColor,
                                            // fontWeight: "bolder",
                                          }}
                                        >
                                          Add to &nbsp;
                                          {this.context.theme.cartIcon ==
                                          "Cart" ? (
                                            <>
                                              <BsCart2
                                                size={"20px"}
                                                style={{
                                                  backgroundColor:
                                                    this.context.theme
                                                      .mainNavBarBackgroundColor,
                                                  color:
                                                    this.context.theme
                                                      .mainNavBarTextColor,
                                                }}
                                              ></BsCart2>
                                            </>
                                          ) : (
                                            <>
                                              <BsBag
                                                size={"20px"}
                                                style={{
                                                  backgroundColor:
                                                    this.context.theme
                                                      .mainNavBarBackgroundColor,
                                                  color:
                                                    this.context.theme
                                                      .mainNavBarTextColor,
                                                }}
                                              ></BsBag>
                                            </>
                                          )}
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </>
                              </td>
                              <td>
                                <label className="mx-0 p-0 mb-0  verticalaligncentertd ">
                                  <div>
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Add to Wishlist"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        this.context.AddToWishList(
                                          this.props.prod
                                        );
                                        this.setState({ msgboxOpen: true });
                                        this.setState({
                                          msgboxTitle: "Information",
                                        });
                                        this.setState({
                                          msgboxMessage: "Added to wishlist",
                                        });
                                      }}
                                    >
                                      {this.IsAlreadyInWishlist(
                                        this.props.prod.id
                                      ) == false ? (
                                        <>
                                          <BsHeart
                                            size="16"
                                            className=""
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor:
                                                "transparent !important",
                                              color:
                                                this.context.theme
                                                  .productCardTextColor,
                                            }}
                                          ></BsHeart>
                                        </>
                                      ) : (
                                        <>
                                          <BsFillHeartFill
                                            size="16"
                                            style={{
                                              backgroundColor:
                                                "transparent !important",
                                              color: "red",
                                            }}
                                          ></BsFillHeartFill>
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </label>
                                {/* share */}
                                {this.props.prod.sku ? (
                                  <>
                                    <span
                                      class="notify-badge-right_share"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Share me"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor:
                                          this.context.theme
                                            .mainNavBarBackgroundColor,
                                        color:
                                          this.context.theme
                                            .mainNavBarTextColor,
                                      }}
                                    >
                                      <RWebShare
                                        data={{
                                          text: this.props.prod.name,
                                          url:
                                            window.location.origin +
                                            "/SingleProductViewGreenArg?sku=" +
                                            this.props.prod.sku,
                                          title: this.props.prod.name,
                                        }}
                                        onClick={() =>
                                          console.log("shared successfully!")
                                        }
                                      >
                                        <FaShareAlt size="13" />
                                      </RWebShare>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </Table>
                </>
              ) : (
                <>
                  <Form className="m-0 pb-2">
                    <Form.Group
                      className="mb-0 border-0"
                      controlId="formBasicEmail"
                    >
                      {/* Discount and Image */}
                      <div
                        class={
                          this.context.theme.showPriceOnCardswhenHover ==
                            undefined ||
                          this.context.theme.showPriceOnCardswhenHover == true
                            ? ""
                            : this.context.myCart.filter(
                                (f) => f.prod.id == this.props.prod.id
                              ).length == 1
                            ? ""
                            : "cartDIV"
                        }
                      >
                        <div class="item-dark m-0 py-0 imgcenter  ">
                          <div className="img-hover-zoom m-0 p-0 w-100 ">
                            {this.IsVideo(this.props.prod.imgFileName) ==
                            true ? (
                              <>
                                <video
                                  preload="none"
                                  className="TopCornerRounded"
                                  width="100%"
                                  poster={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    this.props.prod.posterName +
                                    this.context.store.storageSasToken
                                  }
                                  // controls="false"
                                  onClick={(e) => {
                                    if (
                                      this.props.parent != "singleview" &&
                                      this.props.parent == undefined
                                    )
                                      this.OpenSingleViewModal(e);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                >
                                  <source
                                    src={
                                      this.context.store.storageBlobUrl +
                                      this.context.store
                                        .storageBlobContainerName +
                                      "/images/" +
                                      this.props.prod.imgFileName +
                                      this.context.store.storageSasToken
                                    }
                                    // type="video/mp4"
                                  />
                                </video>
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    (this.props.prod.imgFileName == undefined
                                      ? this.props.prod.productImages !=
                                          undefined &&
                                        this.props.prod.productImages.length > 0
                                        ? this.props.prod.productImages[0]
                                            .fileName
                                        : "noimage.jpg"
                                      : this.props.prod.imgFileName) +
                                    this.context.store.storageSasToken
                                  }
                                  className="card-img-top border-0 text-center align-top align-content-start TopCornerRounded"
                                  alt="..."
                                  onClick={(e) => {
                                    if (
                                      this.props.parent != "singleview" &&
                                      this.props.parent == undefined &&
                                      this.context.theme.allProductToGetQuote !=
                                        true
                                    )
                                      this.OpenSingleViewModal(e);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                ></img>
                              </>
                            )}
                          </div>
                        </div>

                        {/* Product Name */}
                        <label
                          className="mx-0 my-2 p-0 mb-0 productNamewrap "
                          style={{
                            width: "100%",
                            height:
                              this.context.theme
                                .productNameHeightOnProductCard + "cm",
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                            textAlign:
                              this.context.theme.productNameAlignOnProductCard,
                            color: this.context.theme.productCardTextColor,
                          }}
                        >
                          {this.props.prod.name} &nbsp;&nbsp;&nbsp;
                          <span
                            className=""
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Wishlist"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.context.AddToWishList(this.props.prod);
                              this.setState({ msgboxOpen: true });
                              this.setState({
                                msgboxTitle: "Information",
                              });
                              this.setState({
                                msgboxMessage: "Added to wishlist",
                              });
                            }}
                          >
                            {this.IsAlreadyInWishlist(this.props.prod.id) ==
                            false ? (
                              <>
                                <BsHeart
                                  size="16"
                                  className=""
                                  style={{
                                    backgroundColor: "transparent !important",
                                    // color: "black",
                                    color:
                                      this.context.theme.productCardTextColor,
                                  }}
                                ></BsHeart>
                              </>
                            ) : (
                              <>
                                <BsFillHeartFill
                                  size="16"
                                  style={{
                                    backgroundColor: "transparent !important",
                                    color: "red",
                                  }}
                                ></BsFillHeartFill>
                              </>
                            )}
                          </span>
                          {/* {this.context.theme.allProductToGetQuote == true ? (
                            <></>
                          ) : (
                            <>
                              
                            </>
                          )} */}
                        </label>

                        {/* Short Ad     */}
                        {this.props.prod.shortAd ? (
                          <span
                            className="ribbonleft ribboncorners"
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          >
                            {this.props.prod.shortAd}
                          </span>
                        ) : (
                          <></>
                        )}

                        {/* Discount Ribbon */}
                        {this.props.prod.discount > 0 ? (
                          <>
                            <span
                              className="ribbonmodernright"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Discount"
                              style={{
                                backgroundColor:
                                  this.context.theme.mainNavBarBackgroundColor,
                                color: this.context.theme.mainNavBarTextColor,
                              }}
                            >
                              {this.props.prod.discount}% off
                            </span>
                          </>
                        ) : (
                          ""
                        )}

                        {/* WebShare */}
                        {this.context.portalMode == "Kiosk" ? (
                          <></>
                        ) : (
                          <>
                            {this.props.prod.sku ? (
                              <>
                                <span
                                  className="notify-badge-right_share"
                                  // data-toggle="tooltip"
                                  // data-placement="top"
                                  title="Share me"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                  }}
                                >
                                  <RWebShare
                                    data={{
                                      text: this.props.prod.name,
                                      url:
                                        window.location.origin +
                                        "/SingleProductViewGreenArg?sku=" +
                                        this.props.prod.sku,
                                      title: this.props.prod.name,
                                    }}
                                    onClick={() =>
                                      console.log("shared successfully!")
                                    }
                                  >
                                    <FaShareAlt size="16" />
                                  </RWebShare>
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>

                      {/* Add to Cart */}
                      {this.context.theme.allProductToGetQuote == true ? (
                        <>
                          <div className="w-100 text-center">
                            <Button className="m-0 p-0 border-0">
                              <Nav.Link
                                style={{
                                  backgroundColor: "#FF9900",
                                  color: "white",
                                  fontSize: "18px",
                                  fontFamily: "Helvetica",
                                }}
                                onClick={(e) => {
                                  if (
                                    this.context.userData.loginStatus !=
                                    "active"
                                  ) {
                                    this.context.ShowUserLoginNew(true);
                                  } else {
                                    this.context.StoreShopnowProduct(
                                      this.props.prod
                                    );
                                    this.setState({
                                      showProductSingleViewPage: true,
                                    });
                                  }
                                }}
                              >
                                Explore more...{" "}
                              </Nav.Link>
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            class={
                              this.context.theme.showPriceOnCardswhenHover ==
                                undefined ||
                              this.context.theme.showPriceOnCardswhenHover ==
                                true
                                ? ""
                                : this.context.myCart.filter(
                                    (f) => f.prod.id == this.props.prod.id
                                  ).length == 1
                                ? ""
                                : "addcarthide addtocartDIV"
                            }
                          >
                            <table className=" fullwidth ">
                              <tr>
                                <td
                                  className="border-0 text-center align-content-center"
                                  style={{
                                    color:
                                      this.context.theme.productCardTextColor,
                                  }}
                                >
                                  {this.props.prod.discount > 0 ? (
                                    <>
                                      <span className="costfont">
                                        <b
                                          className=""
                                          style={{
                                            fontSize:
                                              this.context.theme
                                                .priceFontSizeOnProductCard +
                                              "px",
                                          }}
                                        >
                                          &nbsp;
                                          {Number(
                                            this.props.prod.mrp -
                                              (this.props.prod.mrp *
                                                this.props.prod.discount) /
                                                100
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </b>
                                        &nbsp; &nbsp;
                                        <span
                                          className=""
                                          style={{
                                            color:
                                              this.context.theme
                                                .productCardTextColor,
                                          }}
                                        >
                                          <strike>
                                            {Number(
                                              this.props.prod.mrp
                                            ).toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                          </strike>
                                        </span>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        className="costfont"
                                        style={{
                                          fontSize:
                                            this.context.theme
                                              .priceFontSizeOnProductCard +
                                            "px",
                                        }}
                                      >
                                        <b>
                                          {Math.round(
                                            this.props.prod.mrp,
                                            0
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </b>
                                      </span>
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="text-center py-0">
                                  {this.props.prod.productVariantType == 2 ? (
                                    <>
                                      <Button
                                        className="align-content-center border-0 buttonwithshadow buttonRounded"
                                        style={{
                                          backgroundColor:
                                            this.context.theme
                                              .mainNavBarBackgroundColor,
                                          color:
                                            this.context.theme
                                              .mainNavBarTextColor,
                                        }}
                                        onClick={(e) =>
                                          this.OpenSingleViewModal(e)
                                        }
                                      >
                                        View
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {this.context.myCart.filter(
                                        (f) => f.prod.id == this.props.prod.id
                                      ).length == 1 ? (
                                        <>
                                          <div className="text-center align-content-center cartTable">
                                            <Row>
                                              <Col md={2}></Col>
                                              <Col md={8}>
                                                <IncDecCounter
                                                  intvalue={
                                                    this.context.myCart.filter(
                                                      (f) =>
                                                        f.prod.id ==
                                                        this.props.prod.id
                                                    )[0].qty
                                                  }
                                                  valueChangeCallback={(e) => {
                                                    this.context.UpdateQtyToCart(
                                                      this.props.prod,
                                                      e
                                                    );
                                                    this.context.forceUpdateCartView();
                                                  }}
                                                ></IncDecCounter>
                                              </Col>
                                              <Col md={2}></Col>
                                            </Row>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="p-0 ">
                                            <>
                                              <Button
                                                className="align-content-end border-0 buttonwithshadow buttonRounded"
                                                onClick={this.addToCartSubmit}
                                                style={{
                                                  backgroundColor:
                                                    this.context.theme
                                                      .mainNavBarBackgroundColor,
                                                  color:
                                                    this.context.theme
                                                      .mainNavBarTextColor,
                                                }}
                                              >
                                                Add to &nbsp;
                                                {this.context.theme.cartIcon ==
                                                "Cart" ? (
                                                  <>
                                                    <BsCart2
                                                      size={"20px"}
                                                      style={{
                                                        backgroundColor:
                                                          this.context.theme
                                                            .mainNavBarBackgroundColor,
                                                        color:
                                                          this.context.theme
                                                            .mainNavBarTextColor,
                                                      }}
                                                    ></BsCart2>
                                                  </>
                                                ) : (
                                                  <>
                                                    <BsBag
                                                      size={"20px"}
                                                      style={{
                                                        backgroundColor:
                                                          this.context.theme
                                                            .mainNavBarBackgroundColor,
                                                        color:
                                                          this.context.theme
                                                            .mainNavBarTextColor,
                                                      }}
                                                    ></BsBag>
                                                  </>
                                                )}
                                              </Button>
                                            </>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Form>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
