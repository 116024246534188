import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";

import { Modal, Button, Form, Row, Col } from "react-bootstrap";

import CartContext from "./CartContext";

export class LanguageSelection extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }
  onCountryChange = (e) => {
    this.setState({ code: e.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.context.SetSelectedCountryCode(this.state.code);
    this.context.SetIslanguageSelected(true);
  };

  componentDidMount = () => {
    this.setState({ code: this.context.selectedCountryCode });
  };

  render() {
    return (
      <>
        {/* <Helmet>
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet> */}
        <div className="Container-fluid mx-0 px-1 ">
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            // className=" usedLoginDsn"
          >
            <Modal.Header
              className="border-0"
              // closeButton
            >
              <Modal.Title>
                <h4>Select your Country</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <Form onSubmit={this.handleSubmit}>
                <div className="text-center">
                  <Row>
                    <Col xs={6}> Country Code:</Col>
                    <Col xs={6}>
                      <Form.Select
                        type="text"
                        // size="sm"
                        className="text-center"
                        name="countryName"
                        value={this.state.code}
                        required
                        className="text-start"
                        autoComplete="off"
                        onChange={(e) => this.onCountryChange(e)}
                      >
                        {this.context.multiCurrenciesSetting &&
                          this.context.multiCurrenciesSetting
                            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                            .map((m) => (
                              <>
                                <option value={m.countryName}>
                                  {m.countryName}
                                </option>
                              </>
                            ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col className="text-center">
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-50 btn-green btn-mt d-inline-block text-white "
                        size="lg"
                        // onClick={this.ValidateOtp}
                      >
                        <b>Proceed </b>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
          </Modal>
        </div>
      </>
    );
  }
}
