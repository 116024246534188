import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { BlobServiceClient } from "@azure/storage-blob";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import axios from "axios";

import { Table, Form, Button } from "react-bootstrap";

export default class UserOrders extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      userOrders: [],
      loadSpinnerOpen: false,
      currentOrderRow: "",
    };
    this.inputFile = React.createRef();
  }

  GetUserOrders = () => {
    console.log(`Bearer ${this.context.userData.bearerToken}`);
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetUserOrders/" +
        this.context.storeId +
        "," +
        this.context.userData.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.context.userData.bearerToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) return;
        this.setState({ userOrders: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  componentDidMount() {
    this.GetUserOrders();
  }

  GetOrderStatus = (orderStatusId) => {
    var list = this.context.orderStatusTypes.filter(
      (e) => e.id == orderStatusId
    );
    if (list.length > 0) return list[0].statusName;
    return "";
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  handleFileUpload = async (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    for (let i = 0; i < files.length; i++) {
      await this.uploadFileToBlob(files[i]);
    }
    console.log("All uploaded ");
    this.HideLoadSpinner();
  };

  uploadFileToBlob = async (file) => {
    if (!file) return [];
    const blobService = new BlobServiceClient(
      this.context.store.storageBlobUrl + this.context.store.storageSasToken
    );
    const containerClient = blobService.getContainerClient(
      this.context.store.storageBlobContainerName
    );

    console.log("uploading:" + file.name);
    //upload file.
    await this.createBlobInContainer(containerClient, file);

    // get list of blobs in container
    //return getBlobsInContainer(containerClient);
  };

  createBlobInContainer = async (containerClient, file) => {
    // create blobClient for container
    var targetFileName =
      this.state.currentOrderRow.orderId + "." + file.name.split(".").pop();
    const blobClient = containerClient.getBlockBlobClient(
      "bankreceipt/" + targetFileName
    );

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    await blobClient.uploadData(file, options);

    this.setState({ bankReceiptFileName: targetFileName });
    this.state.currentOrderRow.bankReceiptFileName = targetFileName;
    this.setState({ currentOrderRow: this.state.currentOrderRow }, () => {});

    console.log("uploaded:" + file.name);
  };

  onUploadButtonClick = (currentOrderRow) => {
    this.setState({ currentOrderRow: currentOrderRow }, () => {
      this.inputFile.current.click();
    });
  };

  DeleteBankReceipt = (currentOrderRow) => {
    currentOrderRow.bankReceiptFileName = "";
    this.setState({ currentOrderRow: currentOrderRow });
  };

  UpdateOrder = (order) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    };
    fetch(process.env.REACT_APP_API + "Consumers/UpdateOrder", requestOptions)
      .then((res) => {
        if (res.status != 200) {
          alert("Not updated");
          return;
        }

        Swal.fire({
          title: " Status",
          text: "Updated successfully!",
          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("Failed" + error);
      });
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "bankReceiptRefNumber":
        this.state.userOrders[i].bankReceiptRefNumber = value;
        break;
      case "bankReceiptDetails":
        this.state.userOrders[i].bankReceiptDetails = value;
        break;
    }
    this.setState({ userOrders: this.state.userOrders });
  };

  OnDownloadPDF = (order) => {
    var res = axios
      .post(process.env.REACT_APP_API + "Consumers/GetPosInvoiceHtml/", order)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          if (response.data.length > 0) {
            var val = window.htmlToPdfmake(response.data);
            var dd = { content: val };
            window.pdfMake.createPdf(dd).download();
          }
        } else {
          alert("Unable to download! pls try later!");
        }
      })
      .catch((error) => {
        console.error("GetOrderStatusTypes - There was an error! ", error);
      });
  };
  GenPDF = () => {
    var s =
      '<html>      <head>        <title></title>      </head>      <body><div style="width: 100%; padding: 2px"> </div>              </body>    </html>    ';

    var val = window.htmlToPdfmake(s);
    var dd = { content: val };
    window.pdfMake.createPdf(dd).download();
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        <>
          <input
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg"
            ref={this.inputFile}
            onChange={this.handleFileUpload}
            type="file"
            multiple="multiple"
          />
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          {window.location.hostname == "shop.green.com.pg" ? (
            <>
              <Helmet>
                {/* <link
                  rel="icon"
                  type="image/x-icon"
                  href="dist/images/fav.ico"
                /> */}
                <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
                <link href="dist/css/style.css" rel="stylesheet" />
                <link href="dist/css/responsive.css" rel="stylesheet" />
                <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
                <script src="dist/js/custom.js"></script>
              </Helmet>
            </>
          ) : (
            <></>
          )}
          <Helmet>
            <script src="https://cdn.jsdelivr.net/npm/html-to-pdfmake/browser.js"></script>
          </Helmet>
          <div className="Container-fluid mx-0 px-1 bg-white userOrderDsn ">
            <div className="row mt-2 mx-5 px-5">
              <div className="col-md-12  ">
                <>
                  <br></br>
                  <h3 className="producttitlefontandsizeDesktop">
                    <b>My Orders</b>
                  </h3>
                  <br></br>
                  <Table
                    border
                    className="cartviewtable cartDsn px-5 table-striped"
                    responsive="md"
                  >
                    <thead>
                      <tr className="bg-light text-center text-secondary">
                        <th width="10%">Order Placed</th>
                        <th width="10%">Order No</th>
                        <th width="5%">Total Items</th>
                        <th width="5%">Total Amount</th>
                        <th width="10%">Payment Status</th>
                        <th width="10%">Order Status</th>
                        <th width="15%">Delivery Details</th>
                        <th width="15%">Delivery Address</th>
                        <th width="10%">Invoice</th>
                        {window.location.hostname == "shop.green.com.pg" ? (
                          <>
                            <th width="20%">Bank Receipt Details</th>
                          </>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.userOrders
                        .sort((a, b) => (a.orderId < b.orderId ? 1 : -1))
                        .map((p, i) => (
                          <>
                            <tr className="text-center text-secondary ">
                              <td>
                                {this.context.GetInDateFormat(
                                  new Date(p.orderCreated)
                                )}
                              </td>
                              <td>{p.orderId}</td>
                              <td>{p.customerOrderedItems.length}</td>
                              <td>
                                {Math.round(
                                  Number(p.totalAmt),
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </td>
                              <td>
                                {p.paymentStatusId == 1
                                  ? "Paid"
                                  : "Unpaid/Pending"}
                              </td>
                              <td>
                                <p class="status received">
                                  {this.GetOrderStatus(p.orderStatus)}
                                </p>{" "}
                              </td>
                              <td className="text-start">
                                {p.deliveryBy == 2 ? (
                                  <>
                                    <b>Sent via: </b>
                                    {p.deliveryCarrierName}
                                    <br />
                                    <b>TrackingId: </b>
                                    {p.deliveryTrackingId}
                                    <br />
                                    <b>Details:</b>
                                    <br />
                                    <span style={{ whiteSpace: "pre-line" }}>
                                      {p.deliveryDetails}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="text-start">
                                {p.customerOrderDeliveryAddresses[0].firstName}
                                &nbsp;{" "}
                                {
                                  p.customerOrderDeliveryAddresses[0].lastName
                                }, <br />
                                {
                                  p.customerOrderDeliveryAddresses[0].address
                                }, {p.customerOrderDeliveryAddresses[0].city},{" "}
                                {p.customerOrderDeliveryAddresses[0].state},{" "}
                                {p.customerOrderDeliveryAddresses[0].country}
                                {" - "}
                                {
                                  p.customerOrderDeliveryAddresses[0].pinCode
                                } . <br />
                                Phone No :{" "}
                                {p.customerOrderDeliveryAddresses[0].mobileNo}
                              </td>
                              <td>
                                {/* <Button
                                  onClick={(e) => {
                                    this.OnDownloadPDF(p);
                                    //this.GenPDF();
                                  }}
                                  className="download "
                                  variant="white"
                                >
                                  <img src="dist/images/pdf.png" /> download
                                </Button> */}
                                <a
                                  href={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/invoice/" +
                                    p.orderId +
                                    ".html" +
                                    this.context.store.storageSasToken +
                                    "&xyz=" +
                                    new Date().toLocaleString()
                                  }
                                  target="_blank"
                                  className="download"
                                >
                                  <img
                                    src="dist/images/html.png"
                                    height="30px"
                                  />
                                  download
                                </a>
                              </td>
                              {window.location.hostname ==
                              "shop.green.com.pg" ? (
                                <>
                                  <td className="text-start">
                                    Reference Number(*) :
                                    <Form.Control
                                      type="text"
                                      name="bankReceiptRefNumber"
                                      value={p.bankReceiptRefNumber}
                                      required
                                      placeholder=""
                                      className=" text-center"
                                      autoComplete="off"
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                    />
                                    Bank & Other Details(if any) :
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      rows={3}
                                      name="bankReceiptDetails"
                                      value={p.bankReceiptDetails}
                                      placeholder=""
                                      className=" text-center   "
                                      autoComplete="off"
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                    />
                                    {p.bankReceiptFileName == "" ||
                                    p.bankReceiptFileName == undefined ? (
                                      <>
                                        Attachment (if any) :&nbsp;
                                        <Link
                                          className="text-green text-decoration-none"
                                          onClick={(e) =>
                                            this.onUploadButtonClick(p)
                                          }
                                        >
                                          click to attach{" "}
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <b>{p.bankReceiptFileName}</b>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <a
                                          href={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/bankreceipt/" +
                                            p.bankReceiptFileName +
                                            this.context.store.storageSasToken
                                          }
                                          target="_blank"
                                          className="text-green text-decoration-none"
                                        >
                                          View
                                        </a>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                          className="bg-green"
                                          onClick={(e) => {
                                            Swal.fire({
                                              title: "Warning!",
                                              confirmButtonColor: "#23B14D",
                                              cancelButtonColor: "#dbdbdb",
                                              text: "Do you want to delete?",
                                              showCancelButton: "true",
                                              confirmButtonText: "Yes",
                                              cancelButtonText: "No",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                this.DeleteBankReceipt(p);
                                              }
                                            });

                                            // if (
                                            //   window.confirm(
                                            //     "Are you sure you want to Delete ?"
                                            //   )
                                            // ) {
                                            //   this.DeleteBankReceipt(p);
                                            // }
                                          }}
                                        >
                                          X
                                        </Button>
                                      </>
                                    )}
                                    <br></br>
                                    <div className="my-2 text-center ">
                                      <Button
                                        className="btn-green d-inline-block"
                                        onClick={(e) => this.UpdateOrder(p)}
                                      >
                                        &nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;
                                      </Button>
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
