import React, { Component, useContext, useState } from "react";
import { Stack, Row, Col, Button, Form } from "react-bootstrap";
import CartContext from "./CartContext";

//https://codingstatus.com/increment-decrement-counter-in-react-hooks/
export class IncDecCounter extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      num: this.props.intvalue,
    };
  }

  incNum = () => {
    if (this.state.num < this.context.storeSettings.productQtyLimitToCart) {
      var newnum = Number(this.state.num) + 1;
      //setNum(newnum);
      this.setState({ num: newnum });

      this.props.valueChangeCallback(newnum);
    }
  };
  decNum = () => {
    if (Number(this.state.num) > 0) {
      var newnum = this.state.num - 1;
      //setNum(newnum);
      this.setState({ num: newnum });

      this.props.valueChangeCallback(newnum);
    }
  };
  handleChange = (e) => {
    if (e.target.value === "") {
      this.setState({ num: 1 });
    } else if (Number(e.target.value) === 0) {
      this.setState({ num: Number(e.target.value) });
      this.props.valueChangeCallback(Number(e.target.value));
    } else {
      if (Number(e.target.value) > 0) {
        this.setState({ num: Number(e.target.value) });
        this.props.valueChangeCallback(Number(e.target.value));
      }
    }
  };

  render() {
    return (
      <>
        <div className="mx-0">
          <Stack direction="horizontal" className={"mx-0"}>
            <Button
              className="btn mx-0 rounded-circle minus"
              type="button"
              onClick={this.decNum}
              style={{
                backgroundColor: "#4ad168",
                color: "white",
                fontSize: "16px",
              }}
            >
              <b>&nbsp;-&nbsp;</b>
            </Button>
            &nbsp;
            <Form.Control
              type="text"
              className="text-center fw-bold px-0 "
              value={this.state.num}
              // disabled="true"
              onChange={this.handleChange}
              style={{
                // backgroundColor: "white",
                // color: "black",
                fontSize: "16px",
              }}
            ></Form.Control>
            &nbsp;
            <Button
              className="btn rounded-circle plus"
              type="button"
              onClick={this.incNum}
              style={{
                backgroundColor: "#4ad168",
                color: "white",
                fontSize: "16px",
              }}
            >
              <b>&nbsp;+&nbsp;</b>
            </Button>
          </Stack>
        </div>
      </>
    );
  }
}

export default IncDecCounter;
