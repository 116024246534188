import React, { Component, useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import Slider from "react-slick";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";

import axios from "axios";

import {
  Accordion,
  Row,
  Col,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ProductsList } from "./ProductsList";
import ProductCard from "./ProductCard";
import CartContext from "./CartContext";
import { LoadSpinner } from "./LoadSpinner";
export class QrHome extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.context.SetPortalMode("QrPortal");
  };
  render() {
    return <>Hi</>;
  }
}
