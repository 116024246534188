import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export class MessageBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      open: this.props.open,
      message: this.props.message,
      callback: this.props.callback,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.callback();
  };
  render() {
    return (
      <>
        <Modal
          show={this.state.open}
          backdrop="static"
          keyboard={false}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
