import React, { useState, useContext, Component, createRef } from "react";
import pincode from "pincode-distance/lib/pincode";
import { Country, State, City } from "country-state-city";
import CartContext from "./CartContext";
import "../CSS/3d.css";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { sha256 } from "js-sha256";

import {
  Modal,
  Nav,
  Form,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { render } from "@testing-library/react";
import { getCurrentDateTime } from "../../helper";
import { useEffect } from "react";
import { CheckoutUpiManual } from "./CheckoutUpiManual";

export class OfflineStore extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {};
  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // size="xl"
          backdrop="static"
          show={true}
          onHide={this.props.onHide}
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-primary text-light">
            <Modal.Title id="contained-modal-title-vcenter">
              Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            <h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.context.storeSettings.offlineNote,
                }}
              />
            </h5>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
