import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

import {
  Modal,
  Row,
  Col,
  Nav,
  Form,
  Button,
  Alert,
  Container,
} from "react-bootstrap";

export default class UserCustomerSupport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      xmlString:
        "<html>Please WhatsApp <a href='https://wa.me/9663617620'>https://wa.me/9663617620</a> or Call us for Queries, Feedbacks and Complaints</html>",
      printed: false,
    };
  }

  componentDidMount() {
    if (this.state.printed == false) {
      var d1 = document.getElementById("one");
      if (d1 && this.context.storeSettings)
        d1.innerHTML = this.context.storeSettings.customerSupportDescription;
      this.setState({ printed: true });
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        <>
          <div className="mx-0 px-1 " style={{ paddingTop: "65px" }}>
            <div className="row mt-2 mx-1 px-0">
              <div className="col-md-1 "></div>
              <div className="col-md-10  ">
                <>
                  <br></br>
                  <h3 className="">
                    <b>Customer Support</b>
                  </h3>
                  <br></br>
                  <h5>
                    <div id="one"></div>
                  </h5>
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
