// import React from "react";

// export async function getMainCategories() {
//   var var1 = process.env.REACT_APP_API + "Consumers/GetMainCategory";
//   var maincat = null;
//   await fetch(process.env.REACT_APP_API + "Consumers/GetMainCategory")
//     .then((response) => response.json())
//     .then((data) => {
//       maincat = data;
//     })
//     .catch((error) => {
//       console.error("There was an error!", error);
//       alert("err");
//     });

//   return maincat;
// }

// export async function isValidateLogin(emailId, password) {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify({
//       id: 0,
//       storeId: this.context.storeId,
//       firstName: "string",
//       lastName: "string",
//       mobileNo: "string",
//       emailId: emailId,
//       password: password,
//       photoUrl: "string",
//       address: "string",
//       landmark: "string",
//       city: "string",
//       state: "string",
//       country: "string",
//       pinCode: "string",
//       createdDateTime: new Date().toJSON(),
//       modifiedDateTime: new Date().toJSON(),
//     }),
//   };
//   await fetch(process.env.REACT_APP_API + "Consumers/Login", requestOptions)
//     .then((response) => {
//       const isJson = response.headers
//         .get("content-type")
//         ?.includes("application/json");
//       const data = isJson && response.json();

//       // check for error response
//       if (!response.ok) {
//         // get error message from body or default to response status
//         const error = (data && data.message) || response.status;
//         console.error("error!", error);
//         //return Promise.reject(error);
//         return false;
//       }

//       // this.setState({ postId: data.id });
//       return true;
//     })
//     .catch((error) => {
//       //this.setState({ errorMessage: error.toString() });
//       console.error("There was an error!", error);
//       return false;
//     });

//   return false;
// }
