import { render } from "@testing-library/react";
import React, { Component, useContext, useState } from "react";
import { BsFillHeartFill, BsHeart, BsCart2, BsBag } from "react-icons/bs";
// import { ProductSingleProductView } from "./ProductSingleProductView";
import axios from "axios";
import ProductCard from "./ProductCard";
import { Helmet } from "react-helmet";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import Zoom from "react-medium-image-zoom";
import IncDecCounter from "./IncDecCounterControl";

import {
  Form,
  Button,
  Modal,
  ListGroup,
  Stack,
  FloatingLabel,
  Row,
  Col,
  Table,
  InputGroup,
  Container as div,
} from "react-bootstrap";

import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import CartContext from "./CartContext";

export class ProductSingleProductView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      currentImageFileName: "",
      currentPosterName: "",
      qty: 1,
      prod: "",
      selectedCustomField1Value: "",
      selectedCustomField2Value: "",
      selectedCustomField3Value: "",
      selectedProductVariant: "",
      customValue1List: [],
      customValue2List: [],
      customValue3List: [],
      reload: true,
      activeButton: "",
    };
  }

  OnImageChange = (e, i) => {
    if (
      this.state.currentImageFileName ==
        this.state.prod.productImages[i].fileName &&
      this.state.currentPosterName ==
        this.state.prod.productImages[i].posterName
    )
      return;

    this.setState(
      {
        currentImageFileName: this.state.prod.productImages[i].fileName,
      },
      () => {
        this.setState(
          {
            currentPosterName: this.state.prod.productImages[i].posterName,
          },
          () =>
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            })
        );
      }
    );
    this.setState({
      activeButton: this.state.prod.productImages[i].fileName,
    });
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    var tempprod = JSON.parse(JSON.stringify(this.props.prod));
    if (this.state.prod.productVariantType == 2) {
      tempprod.name +=
        " - [" +
        this.state.prod.customField1 +
        "=" +
        this.state.selectedCustomField1Value +
        "; " +
        (this.state.prod.customField2
          ? this.state.prod.customField2 +
            "=" +
            this.state.selectedCustomField2Value +
            "]"
          : "]");
      tempprod.mrp = this.state.selectedProductVariant.mrp;
      tempprod.discount = this.state.selectedProductVariant.discount;
      tempprod.inStockQty = this.state.selectedProductVariant.inStockQty;
      tempprod.productVariantType = 1;
      tempprod.id = this.state.selectedProductVariant.id;
    }

    this.context.addToMyCart({
      prod: tempprod,
      qty: this.state.qty,
    });
    // this.context.forceUpdateCartView();
    this.props.onHide();
  };

  handleQtyChange = (value) => {
    this.setState({ qty: value });
  };

  GetProductById = (productId) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetProductById/" +
          this.props.prod.id
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ prod: response.data }, () => {
            if (response.data.productVariantType == 2) {
              if (response.data.productCustomFields) {
                this.LoadCustomValueList(
                  response.data.productCustomFields.sort((a, b) =>
                    a.seqNo > b.seqNo ? 1 : -1
                  )
                );
              }
              this.setState(
                {
                  selectedCustomField1Value:
                    response.data.productCustomFields[0].customValue1,
                },
                () => this.SetSelectedProductVariant()
              );
              this.setState(
                {
                  selectedCustomField2Value:
                    response.data.productCustomFields[0].customValue2,
                },
                () => this.SetSelectedProductVariant()
              );
              this.setState(
                {
                  selectedCustomField3Value:
                    response.data.productCustomFields[0].customValue3,
                },
                () => this.SetSelectedProductVariant()
              );
            }
          });
          this.setState({
            currentPosterName:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .posterName
                : "",
          });
          this.setState({
            currentImageFileName:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .fileName
                : "",
          });

          this.setState({
            activeButton:
              response.data.productImages.length > 0
                ? response.data.productImages.sort((a, b) =>
                    a.seqNo > b.seqNo ? 1 : -1
                  )[0].fileName
                : "",
          });
          // if (response.data.productImages.length > 0) {
          //   if (this.IsVideo(response.data.productImages[0].fileName)) {
          //     this.setState({
          //       activeButton:
          //         response.data.productImages.length > 0
          //           ? response.data.productImages[0].posterName
          //           : "",
          //     });
          //   } else {
          //     this.setState({
          //       activeButton:
          //         response.data.productImages.length > 0
          //           ? response.data.productImages[0].fileName
          //           : "",
          //     });
          //   }
          // }
        }
      })
      .catch((error) => {
        console.error("GetProductById - There was an error! ", error);
      });
  };

  LoadCustomValueList = (productCustomFields) => {
    var list1 = [];
    productCustomFields.map((m) => {
      list1 = [...list1, { value: m.customValue1 }];
    });
    var uniquelist1 = [...new Set(list1.map((item) => item.value))];

    this.setState({ customValue1List: uniquelist1 }, () => {
      if (uniquelist1.length > 0) {
        var list2 = [];
        var fil = productCustomFields.filter(
          (f) => f.customValue1 == uniquelist1[0]
        );

        if (fil.length > 0) {
          fil.map((m) => {
            list2 = [...list2, { value: m.customValue2 }];
          });
          var uniquelist2 = [...new Set(list2.map((item) => item.value))];
          this.setState({ customValue2List: uniquelist2 });
        }
      }
    });

    console.log("hi");
  };

  SetSelectedProductVariant = () => {
    this.state.prod.productCustomFields.map((m) => {
      if (
        m.customValue1 == this.state.selectedCustomField1Value &&
        m.customValue2 == this.state.selectedCustomField2Value &&
        m.customValue3 == this.state.selectedCustomField3Value
      ) {
        this.setState({ selectedProductVariant: m }, () => {
          this.state.prod.mrp = this.state.selectedProductVariant.mrp;
          this.state.prod.discount = this.state.selectedProductVariant.discount;
          this.setState({ prod: this.state.prod });
        });
      }
    });
  };

  handleInputChange = (e) => {
    var { name, value } = e.target;

    switch (name.trim()) {
      case "customValue1":
        if (
          this.GetDisplayTypeOfCustomField(this.state.prod.customField1) ==
          "ListItems"
        ) {
          value = e.target.id;
        }
        this.setState({ selectedCustomField1Value: value }, () => {
          var list2 = [];
          var fil = this.state.prod.productCustomFields.filter(
            (f) => f.customValue1 == this.state.selectedCustomField1Value
          );

          if (fil.length > 0) {
            fil.map((m) => {
              list2 = [...list2, { value: m.customValue2 }];
            });
            var uniquelist2 = [...new Set(list2.map((item) => item.value))];
            this.setState({ customValue2List: uniquelist2 }, () => {
              this.setState(
                { selectedCustomField2Value: uniquelist2[0] },
                () => {
                  this.SetSelectedProductVariant();
                }
              );
            });
          }
        });
        break;
      case "customValue2":
        if (
          this.GetDisplayTypeOfCustomField(this.state.prod.customField2) ==
          "ListItems"
        )
          value = e.target.id;
        this.setState({ selectedCustomField2Value: value }, () =>
          this.SetSelectedProductVariant()
        );
        break;
    }
  };
  GetDisplayTypeOfCustomField = (customField) => {
    var res = this.context.ProductDataType.filter((f) => f.name == customField);
    if (res.length > 0) {
      return res[0].displayAs;
    }
    return "";
  };
  IsAlreadyInWishlist = (prodId) => {
    var res = this.context.wishListProductIds.filter(
      (f) => f.prodId == this.props.prod.id
    );
    if (res.length > 0) return true;
    else return false;
  };
  componentDidMount() {
    // window.scrollTo(0, 0);
    this.GetProductById(this.props.prod.id);
  }
  IsVideo(filename) {
    if (filename) {
      var f = filename.split(".").pop();
      if (f == "mp4") return true;
    }
    return false;
  }
  render() {
    return (
      <div className="container">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.props.onHide}
          dialogClassName="xxlmodal"
        >
          <Form onSubmit={this.handleSubmit} className="">
            <Modal.Header
              closeButton
              style={
                {
                  //backgroundColor: this.context.theme.mainNavBarBackgroundColor,
                  //color: this.context.theme.mainNavBarTextColor,
                }
              }
            ></Modal.Header>
            <Modal.Body
              className="show-grid productmodal-body"
              style={{
                // backgroundColor: this.context.theme.productCardBackgroundColor,
                backgroundColor: this.context.theme.homepageBackgroundColor,
                color: this.context.theme.productCardTextColor,
              }}
            >
              <Row className="m-0 p-0">
                <Col xs={12} md={1} className="">
                  <ListGroup
                    className=" p-1 w-100 border"
                    style={{ backgroundColor: "transparent" }}
                  >
                    {this.state.prod != ""
                      ? this.state.prod.productImages
                          .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                          .map((image, i) => (
                            <>
                              <div className="p-2">
                                <ListGroup.Item
                                  id={image.fileName}
                                  className={
                                    this.state.activeButton == image.fileName
                                      ? "active-color"
                                      : "base-class"
                                  }
                                >
                                  <div className="img-hover-zoom m-0 p-0 w-100">
                                    {this.IsVideo(image.fileName) == true ? (
                                      <>
                                        <img
                                          className="card-img-top border-0 text-center align-top align-content-start "
                                          width="100%"
                                          // height="60px"
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            image.posterName +
                                            this.context.store.storageSasToken
                                          }
                                          onClick={(e) =>
                                            this.OnImageChange(e, i)
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          className="card-img-top border-0 text-center align-top align-content-start "
                                          width="100%"
                                          // height="60px"
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            image.fileName +
                                            this.context.store.storageSasToken
                                          }
                                          onClick={(e) =>
                                            this.OnImageChange(e, i)
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </ListGroup.Item>
                              </div>
                            </>
                          ))
                      : ""}
                  </ListGroup>
                </Col>
                <Col xs={12} md={5} className="text-center m-0 p-0 border-0">
                  <div style={{}}>
                    <Form.Group controlId="Image" className="text-center ">
                      <div class="item  m-0 py-0 imgcenter">
                        {/* <div className="img-hover-zoom m-0 p-0 w-100"> */}
                        <div className=" m-0 p-0 w-100 border">
                          {this.state.reload == true ? (
                            <>
                              {this.IsVideo(this.state.currentImageFileName) ==
                              true ? (
                                <>
                                  <video
                                    preload="none"
                                    // width="100%"
                                    // height="320px"
                                    width="100%"
                                    // height={
                                    //   this.context.theme
                                    //     .productSingleViewProductCardImageHeight +
                                    //   "cm"
                                    // }
                                    controls
                                    poster={
                                      this.context.store.storageBlobUrl +
                                      this.context.store
                                        .storageBlobContainerName +
                                      "/images/" +
                                      this.state.currentPosterName +
                                      this.context.store.storageSasToken
                                    }
                                  >
                                    <source
                                      src={
                                        this.context.store.storageBlobUrl +
                                        this.context.store
                                          .storageBlobContainerName +
                                        "/images/" +
                                        this.state.currentImageFileName +
                                        this.context.store.storageSasToken
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                </>
                              ) : (
                                <>
                                  <Zoom>
                                    <img
                                      className="card-img-top border-0 text-center align-middle "
                                      style={{
                                        width: "100%",
                                        height:
                                          this.context.theme
                                            .productSingleViewProductCardImageHeight +
                                          "cm",
                                      }}
                                      src={
                                        this.context.store.storageBlobUrl +
                                        this.context.store
                                          .storageBlobContainerName +
                                        "/images/" +
                                        this.state.currentImageFileName +
                                        this.context.store.storageSasToken
                                      }
                                    />
                                  </Zoom>
                                </>
                              )}

                              {this.props.prod.shortAd ? (
                                <span
                                  className="ribbonleft ribboncorners"
                                  style={{
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                  }}
                                >
                                  {this.state.prod.shortAd}
                                </span>
                              ) : (
                                <></>
                              )}
                              {this.props.prod.discount > 0 ? (
                                <>
                                  {/* <span class="notify-badge-left"> */}
                                  <span
                                    // class="notify-badge-left"
                                    className="ribbonmodernright"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Discount"
                                    style={{
                                      backgroundColor:
                                        this.context.theme
                                          .mainNavBarBackgroundColor,
                                      color:
                                        this.context.theme.mainNavBarTextColor,
                                    }}
                                  >
                                    {this.props.prod.discount}% off
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                              {this.context.portalMode == "Kiosk" ? (
                                <></>
                              ) : (
                                <></>
                              )}

                              {this.props.prod.sku ? (
                                <>
                                  <span
                                    class="notify-badge-right_share"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Share me"
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor:
                                        this.context.theme
                                          .mainNavBarBackgroundColor,
                                      color:
                                        this.context.theme.mainNavBarTextColor,
                                    }}
                                  >
                                    <RWebShare
                                      data={{
                                        text: this.props.prod.name,
                                        url:
                                          window.location.origin +
                                          "/SingleProductViewGreenArg?sku=" +
                                          this.props.prod.sku,
                                        title: this.props.prod.name,
                                      }}
                                      onClick={() =>
                                        console.log("shared successfully!")
                                      }
                                    >
                                      <FaShareAlt size="16" />
                                    </RWebShare>
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} md={6} className="p-3">
                  <div>
                    <h2 class="semibold_font pt-2">
                      <b>{this.props.prod.name}</b>
                      &nbsp;&nbsp;&nbsp;
                      <span
                        // class="notify-badge-right"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add to Wishlist"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.context.AddToWishList(this.props.prod);
                          this.setState({ msgboxOpen: true });
                          this.setState({
                            msgboxTitle: "Information",
                          });
                          this.setState({
                            msgboxMessage: "Added to wishlist",
                          });
                        }}
                      >
                        {this.IsAlreadyInWishlist(this.props.prod.id) ==
                        false ? (
                          <>
                            <BsHeart
                              size="16"
                              className=""
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent !important",
                                color: this.context.theme.productCardTextColor,
                              }}
                            ></BsHeart>
                          </>
                        ) : (
                          <>
                            <BsFillHeartFill
                              size="16"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent !important",
                                color: "red",
                              }}
                            ></BsFillHeartFill>
                          </>
                        )}
                      </span>
                    </h2>

                    <Form.Label
                      className="text-start  "
                      style={{ width: "100%", fontFamily: "sans-serif" }}
                    >
                      {this.state.prod.discount > 0 ? (
                        <>
                          <div
                            style={{
                              fontSize:
                                this.context.theme.priceFontSizeOnProductCard +
                                4 +
                                "px",
                            }}
                          >
                            <span class="semibold_font">
                              <b>
                                {Math.round(
                                  Number(
                                    this.state.prod.mrp -
                                      (this.state.prod.mrp *
                                        this.state.prod.discount) /
                                        100
                                  ),
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </b>
                              &nbsp;&nbsp;
                              <strike className="text-secondary">
                                {Math.round(
                                  this.state.prod.mrp,
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </strike>
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              fontSize:
                                this.context.theme.priceFontSizeOnProductCard +
                                4 +
                                "px",
                            }}
                          >
                            <span className=" ">
                              <b>
                                {Math.round(
                                  this.state.prod.mrp,
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </b>
                            </span>
                          </div>
                        </>
                      )}
                    </Form.Label>
                  </div>
                  <br />
                  <div className="text-dark">
                    {this.state.prod.productVariantType == 2 ? (
                      <>
                        {this.state.prod.customField1 != null &&
                        this.state.prod.customField1 != undefined ? (
                          <>
                            <h5 className="">
                              <b>{this.state.prod.customField1}</b>
                            </h5>
                            {this.GetDisplayTypeOfCustomField(
                              this.state.prod.customField1
                            ) == "DropDown" ? (
                              <>
                                <Form.Select
                                  type="text"
                                  name="customValue1"
                                  className="text-dark"
                                  value={this.state.selectedCustomField1Value}
                                  required
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                >
                                  {this.state.customValue1List.map((m) => (
                                    <>
                                      <option value={m}>{m}</option>
                                    </>
                                  ))}
                                </Form.Select>
                              </>
                            ) : (
                              <>
                                {this.GetDisplayTypeOfCustomField(
                                  this.state.prod.customField1
                                ) == "ListItems" ? (
                                  <>
                                    <ListGroup horizontal className="my-2">
                                      {this.state.customValue1List.map(
                                        (value, i) => (
                                          <>
                                            <ListGroup.Item>
                                              <Form.Check
                                                inline
                                                label={value}
                                                name="customValue1"
                                                type="radio"
                                                onChange={(e) =>
                                                  this.handleInputChange(e)
                                                }
                                                id={value}
                                                checked={
                                                  this.state
                                                    .selectedCustomField1Value ==
                                                  value
                                                    ? "true"
                                                    : ""
                                                }
                                              />
                                            </ListGroup.Item>
                                          </>
                                        )
                                      )}
                                    </ListGroup>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {this.state.prod.customField2 ? (
                          <>
                            <h5 className="font-bold text-dark">
                              <b>{this.state.prod.customField2}</b>
                            </h5>
                            <Form.Select
                              type="text"
                              name="customValue2"
                              value={this.state.selectedCustomField2Value}
                              required
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              {this.state.customValue2List.map((m) => (
                                <>
                                  <option value={m}>{m}</option>
                                </>
                              ))}
                            </Form.Select>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        {this.props.prod.productVariantType == 2 ? (
                          <>
                            <Button
                              className="buttonRounded buttonwithshadow"
                              variant="primary "
                              type="submit"
                              style={{
                                backgroundColor:
                                  this.context.theme.mainNavBarBackgroundColor,
                                color: this.context.theme.mainNavBarTextColor,
                              }}
                            >
                              Add to &nbsp;
                              {this.context.theme.cartIcon == "Cart" ? (
                                <>
                                  <BsCart2
                                    size={"20px"}
                                    style={{
                                      backgroundColor:
                                        this.context.theme
                                          .mainNavBarBackgroundColor,
                                      color:
                                        this.context.theme.mainNavBarTextColor,
                                    }}
                                  ></BsCart2>
                                </>
                              ) : (
                                <>
                                  <BsBag
                                    size={"20px"}
                                    style={{
                                      backgroundColor:
                                        this.context.theme
                                          .mainNavBarBackgroundColor,
                                      color:
                                        this.context.theme.mainNavBarTextColor,
                                    }}
                                  ></BsBag>
                                </>
                              )}
                            </Button>
                          </>
                        ) : (
                          <>
                            {this.context.myCart.filter(
                              (f) => f.prod.id == this.props.prod.id
                            ).length == 1 ? (
                              <>
                                <Stack direction="horizontal">
                                  <div>
                                    <h6 for="" class="col-form-label bold">
                                      Quantity : &nbsp;&nbsp;
                                    </h6>
                                  </div>
                                  <div className="w-50 cartTable">
                                    <IncDecCounter
                                      intvalue={
                                        this.context.myCart.filter(
                                          (f) => f.prod.id == this.props.prod.id
                                        )[0].qty
                                      }
                                      valueChangeCallback={(e) => {
                                        this.context.UpdateQtyToCart(
                                          this.props.prod,
                                          e
                                        );
                                        this.context.forceUpdateCartView();
                                      }}
                                    ></IncDecCounter>
                                  </div>
                                </Stack>
                              </>
                            ) : (
                              <>
                                <Button
                                  className="buttonRounded buttonwithshadow"
                                  variant="primary"
                                  type="submit"
                                  style={{
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                  }}
                                >
                                  Add to &nbsp;
                                  {this.context.theme.cartIcon == "Cart" ? (
                                    <>
                                      <BsCart2
                                        size={"20px"}
                                        style={{
                                          backgroundColor:
                                            this.context.theme
                                              .mainNavBarBackgroundColor,
                                          color:
                                            this.context.theme
                                              .mainNavBarTextColor,
                                        }}
                                      ></BsCart2>
                                    </>
                                  ) : (
                                    <>
                                      <BsBag
                                        size={"20px"}
                                        style={{
                                          backgroundColor:
                                            this.context.theme
                                              .mainNavBarBackgroundColor,
                                          color:
                                            this.context.theme
                                              .mainNavBarTextColor,
                                        }}
                                      ></BsBag>
                                    </>
                                  )}
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      {/* <Button variant="danger" onClick={this.props.onHide}>
                        Close
                      </Button> */}
                    </Col>
                  </Row>
                  <br />
                  <div
                    className="py-3"
                    dangerouslySetInnerHTML={{
                      __html: this.state.prod.description,
                    }}
                  />
                </Col>
              </Row>
              {this.state.prod.relatedProductsSkuListByCsv != undefined &&
              this.state.prod.relatedProductsSkuListByCsv != "" ? (
                <>
                  <section class="bg-white">
                    <div class="container">
                      <div class="row py-5">
                        <div class="col-md-12 p-5 ">
                          <h2 class="semibold_font pt-2">Related Products</h2>
                          <div className="row justify-content-around py-2">
                            {this.state.prod.relatedProductsSkuListByCsv
                              .split(",")
                              .map((sku) => (
                                <>
                                  <div
                                    className="card p-5 mb-5 m-0 border"
                                    style={{
                                      width:
                                        this.context.theme.productCardWidth +
                                        "cm",
                                      backgroundColor:
                                        this.context.theme
                                          .productCardBackgroundColor,
                                      borderColor:
                                        this.context.theme
                                          .productCardBorderColor,
                                    }}
                                  >
                                    <div className="p-0 m-0 ">
                                      {this.context.prods.filter(
                                        (f) => f.sku == sku
                                      ).length == 1 ? (
                                        <>
                                          <ProductCard
                                            parent="singleview"
                                            prod={
                                              this.context.prods.filter(
                                                (f) => f.sku == sku
                                              )[0]
                                            }
                                          ></ProductCard>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <></>
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    );
  }
}
