import React, { useState, useEffect, useContext } from "react";
import CartContext from "./CartContext";

//refresh bearer token every 5 minute if user is logged on. RefreshBearerToken embedded on Navigation
const StartAddedToCartTimer = () => {
  const { ClearAddedToCartMsg } = useContext(CartContext);
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        console.log("timer called");

        setCounter((counter) => counter + 1);
        ClearAddedToCartMsg();
      }, 2000); //refresh once in 1mins
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, counter]);

  return <div className="container"></div>;
};

export default StartAddedToCartTimer;
