import { Component, useContext, useState } from "react";
import {
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CartContext from "./CartContext";
import IncDecCounter from "./IncDecCounterControl";
import CartView from "./CartView";

export default class CartOffCanvas extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { value: "", show: true };
  }
  forceUpdate = () => {
    this.setState({ value: 0 });
    return () => this.setState({ value: this.state.value + 1 }); // update the state to force render
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleMenuOffCanvasClose = () => {
    this.setState({ show: false });
    this.props.onCloseCartOffCanvas();
  };
  handleMenuOffCanvasShow = (e) => {
    this.setState({ show: true });
  };

  render() {
    return (
      <>
        {window.location.hostname == "shop.green.com.pg" ? (
          <>
            <Helmet>
              {/* <link rel="icon" type="image/x-icon" href="dist/images/fav.ico" /> */}
              <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
              <link href="dist/css/style.css" rel="stylesheet" />
              <link href="dist/css/responsive.css" rel="stylesheet" />
              <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
              <script src="dist/js/custom.js"></script>
            </Helmet>
          </>
        ) : (
          <></>
        )}
        <Offcanvas
          show={this.state.show}
          onHide={this.handleMenuOffCanvasClose}
          placement="end"
          className="h-auto w-50"
          style={{
            backgroundColor: this.context.theme.homepageBackgroundColor,
          }}
        >
          <Offcanvas.Header
            closeButton
            className="bg-white text-black border"
            style={{
              backgroundColor: this.context.theme.mainNavBarBackgroundColor,
              color: this.context.theme.mainNavBarTextColor,
            }}
          >
            <Offcanvas.Title>
              {/* <h4 className="mx-4 cl-green mb-0"> */}
              <h4
                className={
                  window.location.hostname == "shop.green.com.pg"
                    ? "mx-4 cl-green"
                    : "mx-4"
                }
              >
                <b>My Cart</b>
                {/* <img
                  src="./images/smiley1.png"
                  className="mx-2"
                  alt="..."
                  width="30"
                  height="30"
                />{" "} */}
              </h4>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-white text-dark">
            <CartView
              CloseCartOffCanvas={this.handleMenuOffCanvasClose}
            ></CartView>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
