import React, { Component, useState } from "react";
import { useContext } from "react";
import CartContext from "./CartContext";
import { Helmet } from "react-helmet";

export class MySEO extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {this.context.storeSettings != "" ? (
          <>
            <Helmet>
              <title>{this.context.storeSettings.seoTitle}</title>
              <meta
                content="width=device-width, initial-scale=1.0"
                name="viewport"
              />
              <meta
                content={this.context.storeSettings.seoKeywords}
                name="keywords"
              />
              <meta
                content={this.context.storeSettings.seoDescription}
                name="description"
              />
              <meta
                name="google-site-verification"
                content={this.context.storeSettings.seoGoogleSiteVerification}
              />
            </Helmet>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
