import React, { Component } from "react";
import CartContext from "./CartContext";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

export default class UserForgotPassword extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { value: "", status: "" };
  }

  SendTemporaryPassword = () => {
    var res = axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/ForgotPassword/" +
          this.context.storeId +
          "," +
          this.state.value
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            status: "We've emailed you a Temporary password! Plese check.",
          });
        } else {
          if (response.status == 404) {
            this.setState({ status: "This Email ID does not exist" });
            return;
          }
        }
      })
      .catch((error) => {
        this.setState({ status: "This Email ID does not exist" });
      });
  };

  validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  handleChange = (event) => {
    this.setState({ value: event.currentTarget.value });
    this.setState({
      status: "",
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    // validate emailid
    if (this.validateEmail(this.state.value)) {
      this.SendTemporaryPassword();
    } else {
      this.setState({
        status:
          "Invalid Email ID. Please enter correct Email ID and try again!",
      });
    }
  };
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-1 ">
          <div className="row mt-2 mx-1 px-0">
            <div className="col-md-4 "></div>
            <div className="col-md-4 ">
              <br></br>
              <br></br>
              <h1 className="text-center">
                <b>Reset your Password</b>
              </h1>
              <div className="mx-0 px-3 p-3 bg-secondary border">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-white">
                      Email address
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      placeholder="Enter your Email ID"
                      value={this.state.value}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Form.Group>
                  <div className="text-center text-white">
                    <h5 className="blink_me">{this.state.status}</h5>
                  </div>
                  <br></br>
                  <div className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-25"
                      size="lg"
                    >
                      <b>Submit</b>
                    </Button>
                  </div>
                  <br></br>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
